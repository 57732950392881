h2 {
  margin-bottom: 16px;
}

#period-selector > button, #period-selector > select, input[type='date'] {
  margin-left: 8px;
}

#period-selector > button {
  display: inline-flex;
}

#table-nasabah {
  margin-top: 8px;
}

#excel-btn {
  margin: 0 auto;
  margin-top: 8px;
}