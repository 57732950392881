.photo-disp > div > img {
  width: 200px;
  margin: 8px 0;
  object-fit: contain;
}

.photo-disp {
  display: flex;
}

.no-disp {
  display: none;
}

