.hidden {
  display: none;
}

.edit-nasabah-label {
  width: 300px;
}

#edit-nasabah-container input, #edit-nasabah-container textarea {
  display: inline-block;
  width: 400px;
  margin-left: 0px;
}

#edit-nasabah-container .textarea-div {
  display: inline-flex;
}

#edit-nasabah-container img {
  width: 200px;
  object-fit: contain;
  display: inline-block;
}

.empty-div-label {
  display: inline-block;
}

.file-container {
  display: inline-flex;
  flex-direction: column;
}

#edit-nasabah-container .pagination {
  border: 1px solid gray;
  border-radius: 4px;
  display: inline-flex;
  align-self: flex-end;
  cursor: pointer;
}

#edit-nasabah-container .pagination li {
  padding: 8px;
  display: flex;
  box-sizing: border-box;
  border: 1px solid gray;
}

#edit-nasabah-container .pagination .nasabah-selected {
  background-color: #006575;
  font-weight: 600;
}

#edit-nasabah-container .pagination li.nasabah-selected a {
  color: white;
}

#edit-nasabah-container .pagination li a {
  color: #006575;
}


#edit-nasabah-container .pagination .previous {
  border-right: 1px solid gray;
}

#edit-nasabah-container .pagination .next {
  border-left: 1px solid gray;
}