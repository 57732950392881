#login-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 600px;
  border-radius: 8px;
  padding: 24px;
  border: 2px solid #e55300;
}

#login-container {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
}

#sign-in-title {
  font-size: 2em;
  padding-bottom: 24px;
  text-align: center;
}

#login-form label {
  font-weight: bold;
  font-size: 1.2em;
}

#login-form label[for=password] {
  margin-top: 16px;
}

#login-form button[type=submit] {
  width: 100%;
  background-color: #e55300;
  color: white;
  padding: 14px 20px;
  border: none;
  margin: 8px 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer;
}

#login-form input[type=text], #login-form input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}