label, .searchKey {
  display: inline-flex;
  width: 180px;
}

#inquiry-container > h4 {
  margin: 8px 0;
}

.photo-btn-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.searchValue {
  display: inline-flex;
}

.form-element {
  margin: 6px 0;
}

#inquiry-form-msg {
  font-size: 0.8em;
  display: inline-flex;
}

#inquiry-container > button {
  display: inline-flex;
}

#search-by {
  margin-bottom: 8px;
  display: inline-flex;
}

input {
  width: 300px;
}

button {
  display: block;
}