@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

button,
select,
input,
textarea {
  border-radius: 0;
  font-family: inherit; }

input[type='text']::-ms-clear {
  display: none; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

sup {
  vertical-align: super; }

sub {
  vertical-align: sub; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html {
  font-size: 16px; }

body {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

strong {
  font-weight: 600; }

@media screen and (-ms-high-contrast: active) {
  svg {
    fill: ButtonText; } }

h1 {
  font-size: 2.625rem;
  font-weight: 300;
  line-height: 3.125rem;
  letter-spacing: 0; }

h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0; }

h3 {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
  letter-spacing: 0; }

h4 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
  letter-spacing: 0; }

h5 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0; }

h6 {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px; }

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0; }

a {
  color: #0062ff; }

em {
  font-style: italic; }

@-webkit-keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; }
  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1; }
  28% {
    width: 100%;
    left: auto;
    right: 0; }
  51% {
    width: 0%;
    left: auto;
    right: 0; }
  58% {
    width: 0%;
    left: auto;
    right: 0; }
  82% {
    width: 100%;
    left: auto;
    right: 0; }
  83% {
    width: 100%;
    left: 0;
    right: auto; }
  96% {
    width: 0%;
    left: 0;
    right: auto; }
  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3; } }

.bx--text-truncate--end {
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.bx--text-truncate--front {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1gMoW.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa1Xdm.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1gMoW.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlBFhA.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1i8q0Q.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFhA.woff) format("woff"); }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1jcoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1hMoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1j8oQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1jsoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light Italic"), local("IBMPlexMono-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSflV1gMoQPttozw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa2HdgregdFOFh.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa0XdgregdFOFh.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa2ndgregdFOFh.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa23dgregdFOFh.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6pfjptAgt5VM-kVkqdyU8n1ioa1XdgregdFA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1jcoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1hMoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1j8oQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1jsoQPttoz6Pz.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold Italic"), local("IBMPlexMono-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6sfjptAgt5VM-kVkqdyU8n1ioSClN1gMoQPttozw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl1FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlRFgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl9FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwl5FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Mono Light"), local("IBMPlexMono-Light"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3oQIwlBFgsAXHNk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iIq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1isq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iAq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1iEq131nj-otFQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F63fjptAgt5VM-kVkqdyU8n1i8q131nj-o.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl1FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlRFgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl9FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwl5FgsAXHNlYzg.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Mono SemiBold"), local("IBMPlexMono-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexmono/v4/-F6qfjptAgt5VM-kVkqdyU8n3vAOwlBFgsAXHNk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdvfo.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZP.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdvfo.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFscg.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdHeEw.woff) format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFscg.woff) format("woff"); }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRce_fuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRccvfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRceffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcePfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRcdvfuJGl18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGqZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuE6ZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuFKZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGKZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGaZJW9XjDlN8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuF6ZJW9XjDg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJce_fuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJccvfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJceffuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcePfuJGl18QRY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcdvfuJGl18Q.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIJsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdzeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdXeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdLeFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhd7eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhd_eFaxOedfTDw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIxsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIVsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIJsdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI5sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI9sdP3pBmtF8A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdP3pBms.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

.bx--assistive-text,
.bx--visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--body {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #161616;
  background-color: #ffffff;
  line-height: 1; }

body {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #161616;
  background-color: #ffffff;
  line-height: 1; }

.bx--grid {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 42rem) {
    .bx--grid {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (min-width: 99rem) {
    .bx--grid {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

@media (min-width: 99rem) {
  .bx--grid--full-width {
    max-width: 100%; } }

.bx--row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.bx--grid--condensed .bx--row:not(:last-of-type) {
  margin-bottom: 0.125rem; }

.bx--row--condensed + .bx--row--condensed {
  margin-top: 0.125rem; }

.bx--col {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col,
  .bx--grid--condensed .bx--col {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-1,
  .bx--grid--condensed .bx--col-sm-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-2,
  .bx--grid--condensed .bx--col-sm-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-3,
  .bx--grid--condensed .bx--col-sm-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm-4,
  .bx--grid--condensed .bx--col-sm-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-sm,
.bx--col-sm--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-sm,
  .bx--grid--condensed .bx--col-sm, .bx--row--condensed
  .bx--col-sm--auto,
  .bx--grid--condensed
  .bx--col-sm--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col,
.bx--col-sm {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.bx--col--auto,
.bx--col-sm--auto {
  flex: 1 0;
  width: auto;
  max-width: 100%; }

.bx--col-sm-1 {
  flex: 0 0 25%;
  max-width: 25%; }

.bx--col-sm-2 {
  flex: 0 0 50%;
  max-width: 50%; }

.bx--col-sm-3 {
  flex: 0 0 75%;
  max-width: 75%; }

.bx--col-sm-4 {
  flex: 0 0 100%;
  max-width: 100%; }

.bx--offset-sm-0 {
  margin-left: 0; }

.bx--offset-sm-1 {
  margin-left: 25%; }

.bx--offset-sm-2 {
  margin-left: 50%; }

.bx--offset-sm-3 {
  margin-left: 75%; }

.bx--col-md-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-1,
  .bx--grid--condensed .bx--col-md-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-2,
  .bx--grid--condensed .bx--col-md-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-3,
  .bx--grid--condensed .bx--col-md-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-4,
  .bx--grid--condensed .bx--col-md-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-5,
  .bx--grid--condensed .bx--col-md-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-6,
  .bx--grid--condensed .bx--col-md-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-7,
  .bx--grid--condensed .bx--col-md-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md-8,
  .bx--grid--condensed .bx--col-md-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-md,
.bx--col-md--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-md,
  .bx--grid--condensed .bx--col-md, .bx--row--condensed
  .bx--col-md--auto,
  .bx--grid--condensed
  .bx--col-md--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 42rem) {
  .bx--col,
  .bx--col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-md--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-md-1 {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .bx--col-md-2 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-md-3 {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
  .bx--col-md-4 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-md-5 {
    flex: 0 0 62.5%;
    max-width: 62.5%; }
  .bx--col-md-6 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-md-7 {
    flex: 0 0 87.5%;
    max-width: 87.5%; }
  .bx--col-md-8 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-md-0 {
    margin-left: 0; }
  .bx--offset-md-1 {
    margin-left: 12.5%; }
  .bx--offset-md-2 {
    margin-left: 25%; }
  .bx--offset-md-3 {
    margin-left: 37.5%; }
  .bx--offset-md-4 {
    margin-left: 50%; }
  .bx--offset-md-5 {
    margin-left: 62.5%; }
  .bx--offset-md-6 {
    margin-left: 75%; }
  .bx--offset-md-7 {
    margin-left: 87.5%; } }

.bx--col-lg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-1,
  .bx--grid--condensed .bx--col-lg-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-2,
  .bx--grid--condensed .bx--col-lg-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-3,
  .bx--grid--condensed .bx--col-lg-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-4,
  .bx--grid--condensed .bx--col-lg-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-5,
  .bx--grid--condensed .bx--col-lg-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-6,
  .bx--grid--condensed .bx--col-lg-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-7,
  .bx--grid--condensed .bx--col-lg-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-8,
  .bx--grid--condensed .bx--col-lg-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-9,
  .bx--grid--condensed .bx--col-lg-9 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-10,
  .bx--grid--condensed .bx--col-lg-10 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-11,
  .bx--grid--condensed .bx--col-lg-11 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg-12,
  .bx--grid--condensed .bx--col-lg-12 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-lg,
.bx--col-lg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-lg,
  .bx--grid--condensed .bx--col-lg, .bx--row--condensed
  .bx--col-lg--auto,
  .bx--grid--condensed
  .bx--col-lg--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 66rem) {
  .bx--col,
  .bx--col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-lg--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .bx--col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .bx--col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .bx--col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .bx--col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .bx--col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .bx--col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .bx--col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .bx--col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-lg-0 {
    margin-left: 0; }
  .bx--offset-lg-1 {
    margin-left: 8.33333%; }
  .bx--offset-lg-2 {
    margin-left: 16.66667%; }
  .bx--offset-lg-3 {
    margin-left: 25%; }
  .bx--offset-lg-4 {
    margin-left: 33.33333%; }
  .bx--offset-lg-5 {
    margin-left: 41.66667%; }
  .bx--offset-lg-6 {
    margin-left: 50%; }
  .bx--offset-lg-7 {
    margin-left: 58.33333%; }
  .bx--offset-lg-8 {
    margin-left: 66.66667%; }
  .bx--offset-lg-9 {
    margin-left: 75%; }
  .bx--offset-lg-10 {
    margin-left: 83.33333%; }
  .bx--offset-lg-11 {
    margin-left: 91.66667%; } }

.bx--col-xlg-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-1,
  .bx--grid--condensed .bx--col-xlg-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-2,
  .bx--grid--condensed .bx--col-xlg-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-3,
  .bx--grid--condensed .bx--col-xlg-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-4,
  .bx--grid--condensed .bx--col-xlg-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-5,
  .bx--grid--condensed .bx--col-xlg-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-6,
  .bx--grid--condensed .bx--col-xlg-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-7,
  .bx--grid--condensed .bx--col-xlg-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-8,
  .bx--grid--condensed .bx--col-xlg-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-9,
  .bx--grid--condensed .bx--col-xlg-9 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-10,
  .bx--grid--condensed .bx--col-xlg-10 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-11,
  .bx--grid--condensed .bx--col-xlg-11 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg-12,
  .bx--grid--condensed .bx--col-xlg-12 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-xlg,
.bx--col-xlg--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-xlg,
  .bx--grid--condensed .bx--col-xlg, .bx--row--condensed
  .bx--col-xlg--auto,
  .bx--grid--condensed
  .bx--col-xlg--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 82rem) {
  .bx--col,
  .bx--col-xlg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-xlg--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-xlg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .bx--col-xlg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .bx--col-xlg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-xlg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .bx--col-xlg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .bx--col-xlg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-xlg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .bx--col-xlg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .bx--col-xlg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-xlg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .bx--col-xlg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .bx--col-xlg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-xlg-0 {
    margin-left: 0; }
  .bx--offset-xlg-1 {
    margin-left: 8.33333%; }
  .bx--offset-xlg-2 {
    margin-left: 16.66667%; }
  .bx--offset-xlg-3 {
    margin-left: 25%; }
  .bx--offset-xlg-4 {
    margin-left: 33.33333%; }
  .bx--offset-xlg-5 {
    margin-left: 41.66667%; }
  .bx--offset-xlg-6 {
    margin-left: 50%; }
  .bx--offset-xlg-7 {
    margin-left: 58.33333%; }
  .bx--offset-xlg-8 {
    margin-left: 66.66667%; }
  .bx--offset-xlg-9 {
    margin-left: 75%; }
  .bx--offset-xlg-10 {
    margin-left: 83.33333%; }
  .bx--offset-xlg-11 {
    margin-left: 91.66667%; } }

.bx--col-max-1 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-1,
  .bx--grid--condensed .bx--col-max-1 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-2 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-2,
  .bx--grid--condensed .bx--col-max-2 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-3 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-3,
  .bx--grid--condensed .bx--col-max-3 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-4 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-4,
  .bx--grid--condensed .bx--col-max-4 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-5 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-5,
  .bx--grid--condensed .bx--col-max-5 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-6 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-6,
  .bx--grid--condensed .bx--col-max-6 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-7 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-7,
  .bx--grid--condensed .bx--col-max-7 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-8 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-8,
  .bx--grid--condensed .bx--col-max-8 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-9 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-9,
  .bx--grid--condensed .bx--col-max-9 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-10 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-10,
  .bx--grid--condensed .bx--col-max-10 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-11 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-11,
  .bx--grid--condensed .bx--col-max-11 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max-12 {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max-12,
  .bx--grid--condensed .bx--col-max-12 {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

.bx--col-max,
.bx--col-max--auto {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }
  .bx--row--condensed .bx--col-max,
  .bx--grid--condensed .bx--col-max, .bx--row--condensed
  .bx--col-max--auto,
  .bx--grid--condensed
  .bx--col-max--auto {
    padding-right: 0.0625rem;
    padding-left: 0.0625rem; }

@media (min-width: 99rem) {
  .bx--col,
  .bx--col-max {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .bx--col--auto,
  .bx--col-max--auto {
    flex: 1 0;
    width: auto;
    max-width: 100%; }
  .bx--col-max-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .bx--col-max-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .bx--col-max-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .bx--col-max-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .bx--col-max-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .bx--col-max-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .bx--col-max-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .bx--col-max-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .bx--col-max-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .bx--col-max-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .bx--col-max-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .bx--col-max-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .bx--offset-max-0 {
    margin-left: 0; }
  .bx--offset-max-1 {
    margin-left: 8.33333%; }
  .bx--offset-max-2 {
    margin-left: 16.66667%; }
  .bx--offset-max-3 {
    margin-left: 25%; }
  .bx--offset-max-4 {
    margin-left: 33.33333%; }
  .bx--offset-max-5 {
    margin-left: 41.66667%; }
  .bx--offset-max-6 {
    margin-left: 50%; }
  .bx--offset-max-7 {
    margin-left: 58.33333%; }
  .bx--offset-max-8 {
    margin-left: 66.66667%; }
  .bx--offset-max-9 {
    margin-left: 75%; }
  .bx--offset-max-10 {
    margin-left: 83.33333%; }
  .bx--offset-max-11 {
    margin-left: 91.66667%; } }

.bx--no-gutter,
.bx--row.bx--no-gutter [class*='bx--col'] {
  padding-left: 0;
  padding-right: 0; }

.bx--no-gutter--left,
.bx--row.bx--no-gutter--left [class*='bx--col'] {
  padding-left: 0; }

.bx--no-gutter--right,
.bx--row.bx--no-gutter--right [class*='bx--col'] {
  padding-right: 0; }

.bx--hang--left {
  padding-left: 1rem; }

.bx--hang--right {
  padding-right: 1rem; }

.bx--aspect-ratio {
  height: 0;
  position: relative; }

.bx--aspect-ratio--object {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100; }

.bx--aspect-ratio--16x9 {
  padding-bottom: 56.25%; }

.bx--aspect-ratio--2x1 {
  padding-bottom: 50%; }

.bx--aspect-ratio--4x3 {
  padding-bottom: 75%; }

.bx--aspect-ratio--1x1 {
  padding-bottom: 100%; }

.bx--aspect-ratio--1x2 {
  padding-bottom: 200%; }

.bx--btn-set {
  display: flex; }

.bx--btn-set > .bx--btn {
  max-width: 12.25rem;
  width: 100%; }

.bx--btn--secondary.bx--btn--disabled
+ .bx--btn--primary.bx--btn--disabled,
.bx--btn--tertiary.bx--btn--disabled
+ .bx--btn--danger.bx--btn--disabled {
  border-left: 0.0625rem solid #8d8d8d; }

.bx--btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: top;
  flex-shrink: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 3px) 60px calc(0.875rem - 3px) 12px;
  border-radius: 0;
  text-align: left;
  text-decoration: none;
  -webkit-transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0, 0, 0.38, 0.9);
  outline: 1px solid transparent;
  outline-offset: -4px;
  position: relative;
  max-width: 20rem; }
  .bx--btn:disabled, .bx--btn.bx--btn--disabled {
    cursor: not-allowed;
    color: #8d8d8d;
    background: #c6c6c6;
    border-color: #c6c6c6; }
  .bx--btn .bx--btn__icon {
    position: absolute;
    right: 1rem;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem; }
  .bx--btn.bx--btn--disabled > svg.bx--btn__icon,
  .bx--btn:disabled > svg.bx--btn__icon {
    fill: #8d8d8d; }

.bx--btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

.bx--btn--primary {
  background-color: #0f62fe;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  color: #ffffff; }
  .bx--btn--primary:hover {
    background-color: #0353e9; }
  .bx--btn--primary:focus {
    border-color: #0f62fe;
    outline-color: #ffffff; }
  .bx--btn--primary:disabled:hover, .bx--btn--primary:disabled:focus, .bx--btn--primary:hover.bx--btn--disabled, .bx--btn--primary:focus.bx--btn--disabled {
    color: #8d8d8d;
    background-color: #c6c6c6;
    border-color: #c6c6c6;
    text-decoration: none;
    outline-color: #c6c6c6; }
  .bx--btn--primary:active {
    background-color: #002d9c; }
  .bx--btn--primary .bx--btn__icon,
  .bx--btn--primary .bx--btn__icon path {
    fill: currentColor; }
  .bx--btn--primary:hover {
    color: #ffffff; }

.bx--btn--secondary {
  background-color: #393939;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  color: #ffffff; }
  .bx--btn--secondary:hover {
    background-color: #4c4c4c; }
  .bx--btn--secondary:focus {
    border-color: #0f62fe;
    outline-color: #ffffff; }
  .bx--btn--secondary:disabled:hover, .bx--btn--secondary:disabled:focus, .bx--btn--secondary:hover.bx--btn--disabled, .bx--btn--secondary:focus.bx--btn--disabled {
    color: #8d8d8d;
    background-color: #c6c6c6;
    border-color: #c6c6c6;
    text-decoration: none;
    outline-color: #c6c6c6; }
  .bx--btn--secondary:active {
    background-color: #6f6f6f; }
  .bx--btn--secondary .bx--btn__icon,
  .bx--btn--secondary .bx--btn__icon path {
    fill: currentColor; }
  .bx--btn--secondary:hover, .bx--btn--secondary:focus {
    color: #ffffff; }

.bx--btn--tertiary {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #0f62fe;
  color: #0f62fe; }
  .bx--btn--tertiary:hover {
    background-color: #0353e9; }
  .bx--btn--tertiary:focus {
    border-color: #0f62fe;
    outline-color: #ffffff; }
  .bx--btn--tertiary:disabled:hover, .bx--btn--tertiary:disabled:focus, .bx--btn--tertiary:hover.bx--btn--disabled, .bx--btn--tertiary:focus.bx--btn--disabled {
    color: #8d8d8d;
    background-color: #c6c6c6;
    border-color: #c6c6c6;
    text-decoration: none;
    outline-color: #c6c6c6; }
  .bx--btn--tertiary:active {
    background-color: #002d9c; }
  .bx--btn--tertiary .bx--btn__icon,
  .bx--btn--tertiary .bx--btn__icon path {
    fill: currentColor; }
  .bx--btn--tertiary:hover {
    color: #ffffff; }
  .bx--btn--tertiary:focus {
    color: #ffffff;
    background-color: #0f62fe; }
  .bx--btn--tertiary:active {
    color: #ffffff; }
  .bx--btn--tertiary:disabled, .bx--btn--tertiary:hover:disabled, .bx--btn--tertiary:focus:disabled, .bx--btn--tertiary.bx--btn--disabled, .bx--btn--tertiary.bx--btn--disabled:hover, .bx--btn--tertiary.bx--btn--disabled:focus {
    background: transparent;
    color: #c6c6c6;
    outline: none; }
    .bx--btn--tertiary:disabled > .bx--btn__icon path, .bx--btn--tertiary:hover:disabled > .bx--btn__icon path, .bx--btn--tertiary:focus:disabled > .bx--btn__icon path, .bx--btn--tertiary.bx--btn--disabled > .bx--btn__icon path, .bx--btn--tertiary.bx--btn--disabled:hover > .bx--btn__icon path, .bx--btn--tertiary.bx--btn--disabled:focus > .bx--btn__icon path {
      fill: #c6c6c6; }
  .bx--btn--tertiary:hover > .bx--btn__icon path {
    fill: #ffffff; }

.bx--btn--ghost {
  background-color: transparent;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  color: #0f62fe;
  padding: calc(0.875rem - 3px) 12px; }
  .bx--btn--ghost:hover {
    background-color: #e5e5e5; }
  .bx--btn--ghost:focus {
    border-color: #0f62fe;
    outline-color: #ffffff; }
  .bx--btn--ghost:disabled:hover, .bx--btn--ghost:disabled:focus, .bx--btn--ghost:hover.bx--btn--disabled, .bx--btn--ghost:focus.bx--btn--disabled {
    color: #8d8d8d;
    background-color: #c6c6c6;
    border-color: #c6c6c6;
    text-decoration: none;
    outline-color: #c6c6c6; }
  .bx--btn--ghost:active {
    background-color: #c6c6c6; }
  .bx--btn--ghost .bx--btn__icon,
  .bx--btn--ghost .bx--btn__icon path {
    fill: currentColor; }
  .bx--btn--ghost .bx--btn__icon {
    position: static;
    margin-left: 0.5rem; }
  .bx--btn--ghost:hover, .bx--btn--ghost:active {
    color: #0043ce; }
    .bx--btn--ghost:hover .bx--btn__icon path, .bx--btn--ghost:active .bx--btn__icon path {
      fill: #0043ce; }
  .bx--btn--ghost:active {
    background-color: #c6c6c6; }
  .bx--btn--ghost:disabled, .bx--btn--ghost:hover:disabled, .bx--btn--ghost:focus:disabled, .bx--btn--ghost.bx--btn--disabled, .bx--btn--ghost.bx--btn--disabled:hover, .bx--btn--ghost.bx--btn--disabled:focus {
    color: #c6c6c6;
    background: transparent;
    border-color: transparent;
    outline: none; }
    .bx--btn--ghost:disabled .bx--btn__icon path, .bx--btn--ghost:hover:disabled .bx--btn__icon path, .bx--btn--ghost:focus:disabled .bx--btn__icon path, .bx--btn--ghost.bx--btn--disabled .bx--btn__icon path, .bx--btn--ghost.bx--btn--disabled:hover .bx--btn__icon path, .bx--btn--ghost.bx--btn--disabled:focus .bx--btn__icon path {
      fill: #c6c6c6; }
  .bx--btn--ghost.bx--btn--sm {
    padding: calc(0.375rem - 3px) 1rem; }
  .bx--btn--ghost.bx--btn--field {
    padding: calc(0.675rem - 3px) 12px; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible;
  outline: 1px solid transparent;
  outline-offset: -4px; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    outline: 1px solid #0f62fe; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
    outline: 1px solid transparent; }
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus svg {
      outline: 1px solid #0f62fe; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
        display: inline-block; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text,
      .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger::after {
    content: attr(aria-label); }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.bx--tooltip--a11y::after {
    content: none; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover::after, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus::after {
    opacity: 1; }

@-webkit-keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:hover.bx--tooltip--a11y::before, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus .bx--assistive-text,
  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus + .bx--assistive-text, .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus {
  border-color: #0f62fe;
  outline-color: #ffffff; }

.bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus
svg {
  outline-color: transparent; }

.bx--btn--icon-only--top {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--btn--icon-only--top:focus {
    outline: 1px solid #0f62fe; }
  .bx--btn--icon-only--top:focus {
    outline: 1px solid transparent; }
    .bx--btn--icon-only--top:focus svg {
      outline: 1px solid #0f62fe; }
  .bx--btn--icon-only--top::before, .bx--btn--icon-only--top::after,
  .bx--btn--icon-only--top .bx--assistive-text,
  .bx--btn--icon-only--top + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn--icon-only--top::before, .bx--btn--icon-only--top::after,
      .bx--btn--icon-only--top .bx--assistive-text,
      .bx--btn--icon-only--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--btn--icon-only--top::before, .bx--btn--icon-only--top::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn--icon-only--top.bx--tooltip--a11y::before, .bx--btn--icon-only--top.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--btn--icon-only--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--btn--icon-only--top .bx--assistive-text,
  .bx--btn--icon-only--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--btn--icon-only--top::after,
  .bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--btn--icon-only--top:hover + .bx--assistive-text,
  .bx--btn--icon-only--top:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--btn--icon-only--top::after,
      .bx--btn--icon-only--top:hover .bx--assistive-text,
      .bx--btn--icon-only--top:focus .bx--assistive-text,
      .bx--btn--icon-only--top:hover + .bx--assistive-text,
      .bx--btn--icon-only--top:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--btn--icon-only--top::after,
      .bx--btn--icon-only--top:hover .bx--assistive-text,
      .bx--btn--icon-only--top:focus .bx--assistive-text,
      .bx--btn--icon-only--top:hover + .bx--assistive-text,
      .bx--btn--icon-only--top:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--btn--icon-only--top::after,
      .bx--btn--icon-only--top:hover .bx--assistive-text,
      .bx--btn--icon-only--top:focus .bx--assistive-text,
      .bx--btn--icon-only--top:hover + .bx--assistive-text,
      .bx--btn--icon-only--top:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--btn--icon-only--top::after {
    content: attr(aria-label); }
  .bx--btn--icon-only--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--btn--icon-only--top:hover::before, .bx--btn--icon-only--top:hover::after, .bx--btn--icon-only--top:focus::before, .bx--btn--icon-only--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--btn--icon-only--top:hover + .bx--assistive-text, .bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--btn--icon-only--top:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--btn--icon-only--top:hover + .bx--assistive-text, .bx--btn--icon-only--top:hover.bx--tooltip--a11y::before, .bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--btn--icon-only--top:focus + .bx--assistive-text, .bx--btn--icon-only--top:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--btn--icon-only--top::before, .bx--btn--icon-only--top::after,
  .bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--btn--icon-only--top:hover + .bx--assistive-text,
  .bx--btn--icon-only--top:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--btn--icon-only--top::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--btn--icon-only--top::after,
  .bx--btn--icon-only--top:hover .bx--assistive-text,
  .bx--btn--icon-only--top:focus .bx--assistive-text,
  .bx--btn--icon-only--top:hover + .bx--assistive-text,
  .bx--btn--icon-only--top:focus + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }

.bx--btn--icon-only--bottom::before, .bx--btn--icon-only--bottom::after,
.bx--btn--icon-only--bottom:hover .bx--assistive-text,
.bx--btn--icon-only--bottom:focus .bx--assistive-text,
.bx--btn--icon-only--bottom:hover + .bx--assistive-text,
.bx--btn--icon-only--bottom:focus + .bx--assistive-text {
  bottom: 0;
  left: 50%; }

.bx--btn--icon-only--bottom::before {
  bottom: -0.5rem;
  border-width: 0 0.25rem 0.3125rem 0.25rem;
  border-color: transparent transparent #393939 transparent;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%); }

.bx--btn--icon-only--bottom::after,
.bx--btn--icon-only--bottom:hover .bx--assistive-text,
.bx--btn--icon-only--bottom:focus .bx--assistive-text,
.bx--btn--icon-only--bottom:hover + .bx--assistive-text,
.bx--btn--icon-only--bottom:focus + .bx--assistive-text {
  bottom: -0.8125rem;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%); }

.bx--btn--icon-only,
.bx--btn--sm.bx--btn--icon-only,
.bx--btn--field.bx--btn--icon-only {
  padding-right: 0.8125rem; }
  .bx--btn--icon-only .bx--btn__icon,
  .bx--btn--sm.bx--btn--icon-only .bx--btn__icon,
  .bx--btn--field.bx--btn--icon-only .bx--btn__icon {
    position: static; }
  .bx--btn--icon-only.bx--btn--ghost .bx--btn__icon,
  .bx--btn--sm.bx--btn--icon-only.bx--btn--ghost .bx--btn__icon,
  .bx--btn--field.bx--btn--icon-only.bx--btn--ghost .bx--btn__icon {
    margin: 0; }

.bx--btn--danger {
  background-color: #da1e28;
  border-width: 3px;
  border-style: solid;
  border-color: #da1e28;
  color: #ffffff; }
  .bx--btn--danger:hover {
    background-color: #ba1b23; }
  .bx--btn--danger:focus {
    border-color: #0f62fe;
    outline-color: #ffffff; }
  .bx--btn--danger:disabled:hover, .bx--btn--danger:disabled:focus, .bx--btn--danger:hover.bx--btn--disabled, .bx--btn--danger:focus.bx--btn--disabled {
    color: #8d8d8d;
    background-color: #c6c6c6;
    border-color: #c6c6c6;
    text-decoration: none;
    outline-color: #c6c6c6; }
  .bx--btn--danger:active {
    background-color: #750e13; }
  .bx--btn--danger .bx--btn__icon,
  .bx--btn--danger .bx--btn__icon path {
    fill: #ffffff; }
  .bx--btn--danger:hover {
    color: #ffffff;
    border: 3px solid transparent; }

.bx--btn--sm {
  min-height: 2rem;
  padding: calc(0.375rem - 3px) 60px calc(0.375rem - 3px) 12px; }

.bx--btn--field {
  height: 40px;
  min-height: 40px;
  padding: calc(0.675rem - 3px) 60px calc(0.675rem - 3px) 12px; }

.bx--btn.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 9.375rem; }
  .bx--btn.bx--skeleton:hover, .bx--btn.bx--skeleton:focus, .bx--btn.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--btn.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--btn.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

.bx--btn--copy {
  position: relative;
  overflow: visible; }
  .bx--btn--copy .bx--btn__icon {
    margin-left: 0.3125rem; }

.bx--btn--copy__feedback {
  position: absolute;
  display: none;
  top: 1.2rem;
  left: 50%; }
  .bx--btn--copy__feedback:focus {
    border: 2px solid red; }
  .bx--btn--copy__feedback:before {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.32px;
    top: 1.1rem;
    padding: 0.25rem;
    color: #ffffff;
    content: attr(data-feedback);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    white-space: nowrap;
    pointer-events: none;
    border-radius: 4px;
    font-weight: 400;
    z-index: 2; }
  .bx--btn--copy__feedback:after {
    top: 0.85rem;
    width: 0.6rem;
    height: 0.6rem;
    left: -0.3rem;
    border-right: 1px solid #393939;
    border-bottom: 1px solid #393939;
    content: '';
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    z-index: 1; }
  .bx--btn--copy__feedback:before, .bx--btn--copy__feedback:after {
    position: absolute;
    display: block;
    background: #393939; }
  .bx--btn--copy__feedback--displayed {
    display: inline-flex; }

.bx--fieldset {
  margin-bottom: 2rem; }

.bx--form-item {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: flex-start; }

.bx--label {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #393939;
  font-weight: 400;
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: 0.5rem;
  line-height: 1rem; }

.bx--label .bx--tooltip__trigger {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px; }

.bx--label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--label.bx--skeleton:hover, .bx--label.bx--skeleton:focus, .bx--label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--label.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

input[type='number'] {
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace; }

input[data-invalid],
.bx--text-input__field-wrapper[data-invalid],
.bx--text-area__wrapper[data-invalid]
> .bx--text-area--invalid,
.bx--select-input__wrapper[data-invalid],
.bx--list-box[data-invalid],
.bx--combo-box[data-invalid] .bx--text-input {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }

input[data-invalid] ~ .bx--form-requirement,
.bx--text-input__field-wrapper[data-invalid] ~ .bx--form-requirement,
.bx--text-area__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--select-input__wrapper[data-invalid] ~ .bx--form-requirement,
.bx--time-picker[data-invalid] ~ .bx--form-requirement,
.bx--list-box[data-invalid] ~ .bx--form-requirement {
  max-height: 12.5rem;
  display: block;
  color: #da1e28; }

input:not(output):not([data-invalid]):-moz-ui-invalid {
  box-shadow: none; }

.bx--form-requirement {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  margin: 0.25rem 0 0;
  max-height: 0;
  overflow: hidden;
  line-height: 1.5;
  display: none; }

.bx--label + .bx--form__helper-text {
  margin-top: -0.375rem; }

.bx--form__helper-text {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #6f6f6f;
  z-index: 0;
  opacity: 1;
  margin-bottom: 0.5rem;
  max-width: 75%; }

.bx--label--disabled,
.bx--form__helper-text--disabled {
  color: #c6c6c6; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

/* Stroke animations */
@-webkit-keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@-webkit-keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

.bx--loading {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 690ms;
          animation-duration: 690ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  width: 10.5rem;
  height: 10.5rem; }
  .bx--loading svg circle {
    -webkit-animation-name: init-stroke;
            animation-name: init-stroke;
    -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
    -webkit-animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
            animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--loading__svg {
  fill: transparent; }

.bx--loading__svg circle {
  stroke-width: 8;
  stroke-linecap: butt;
  stroke-dasharray: 240; }

.bx--loading__stroke {
  stroke: #0f62fe;
  stroke-dashoffset: 40; }

.bx--loading--small .bx--loading__stroke {
  stroke-dashoffset: 99; }

.bx--loading--stop {
  -webkit-animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards;
          animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards; }
  .bx--loading--stop svg circle {
    -webkit-animation-name: stroke-end;
            animation-name: stroke-end;
    -webkit-animation-duration: 700ms;
            animation-duration: 700ms;
    -webkit-animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
            animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
    -webkit-animation-delay: 700ms;
            animation-delay: 700ms;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

.bx--loading--small {
  width: 2rem;
  height: 2rem; }
  .bx--loading--small circle {
    stroke-width: 12; }

.bx--loading--small .bx--loading__svg {
  stroke: #0f62fe; }

.bx--loading__background {
  stroke: #e0e0e0;
  stroke-dashoffset: 0; }

.bx--loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
  z-index: 8000; }

.bx--loading-overlay--stop {
  display: none; }

.bx--file {
  width: 100%; }

.bx--file--invalid {
  margin-right: 0.5rem;
  fill: #da1e28; }

.bx--file--label {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #161616;
  margin-bottom: 0.5rem; }

.bx--file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--file-btn {
  display: inline-flex;
  margin: 0;
  padding-right: 4rem; }

.bx--file-browse-btn {
  margin-bottom: 0.5rem;
  display: inline-block;
  width: 100%;
  color: #0f62fe;
  outline: none;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px; }
  .bx--file-browse-btn:focus, .bx--file-browse-btn:hover {
    outline: 2px solid #0f62fe; }
  .bx--file-browse-btn:hover, .bx--file-browse-btn:focus, .bx--file-browse-btn:active, .bx--file-browse-btn:active:visited {
    text-decoration: underline; }
  .bx--file-browse-btn:active {
    color: #161616; }

.bx--file-browse-btn--disabled {
  cursor: no-drop;
  text-decoration: none;
  color: #c6c6c6; }
  .bx--file-browse-btn--disabled:hover, .bx--file-browse-btn--disabled:focus {
    outline: none;
    text-decoration: none;
    color: #c6c6c6; }

.bx--file-browse-btn--disabled .bx--file__drop-container {
  border: 1px dashed #f4f4f4; }

.bx--label-description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #393939;
  margin-bottom: 1rem; }

.bx--file-btn ~ .bx--file-container {
  margin-top: 1.5rem; }

.bx--file__selected-file {
  display: grid;
  grid-gap: 0.5rem 1rem;
  grid-template-columns: 1fr auto;
  grid-auto-rows: auto;
  align-items: center;
  min-height: 2rem;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  background-color: #f4f4f4;
  word-break: break-word; }
  .bx--file__selected-file:last-child {
    margin-bottom: 0; }
  .bx--file__selected-file .bx--form-requirement {
    grid-column-start: 1;
    grid-column-end: -1;
    max-height: none;
    margin: 0; }
  .bx--file__selected-file .bx--loading {
    width: 2rem;
    height: 2rem;
    margin-right: -0.5rem; }
  .bx--file__selected-file .bx--inline-loading__animation .bx--loading {
    margin-right: 0; }
  .bx--file__selected-file .bx--file-filename {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    margin-left: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

.bx--file__selected-file--invalid__wrapper {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  outline-width: 1px;
  background-color: #f4f4f4;
  max-width: 20rem;
  margin-bottom: 0.5rem; }

.bx--file__selected-file--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  outline-width: 1px;
  padding: 0.75rem 0; }

.bx--file__selected-file--invalid .bx--form-requirement {
  border-top: 1px solid #e0e0e0;
  padding-top: 0.5rem; }

.bx--file__selected-file--invalid
.bx--form-requirement__title,
.bx--file__selected-file--invalid
.bx--form-requirement__supplement {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  padding-right: 0.5rem;
  padding-left: 1rem; }

.bx--file__selected-file--invalid
.bx--form-requirement__supplement {
  color: #161616; }

.bx--file__selected-file--invalid + .bx--form-requirement {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  display: block;
  max-height: 12.5rem;
  color: #da1e28;
  font-weight: 400;
  padding: 0.5rem 1rem;
  overflow: visible; }

.bx--file__selected-file--invalid
+ .bx--form-requirement
.bx--form-requirement__supplement {
  padding-bottom: 0.5rem;
  color: #161616; }

.bx--file__state-container {
  display: flex;
  justify-content: center;
  min-width: 1.5rem;
  padding-right: 0.5rem; }
  .bx--file__state-container .bx--loading__svg {
    stroke: #161616; }

.bx--file__state-container .bx--file-complete {
  fill: #0f62fe;
  cursor: pointer; }
  .bx--file__state-container .bx--file-complete:focus {
    outline: 1px solid #0f62fe; }
  .bx--file__state-container .bx--file-complete [data-icon-path='inner-path'] {
    opacity: 1;
    fill: #ffffff; }

.bx--file__state-container .bx--file-invalid {
  height: 1rem;
  width: 1rem;
  fill: #da1e28;
  margin-right: 0.5rem; }

.bx--file__state-container .bx--file-close {
  height: 1rem;
  width: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  fill: #525252; }
  .bx--file__state-container .bx--file-close:focus {
    outline: 1px solid #0f62fe; }

.bx--file__state-container .bx--file-close svg path {
  fill: #525252; }

.bx--file__state-container .bx--inline-loading__animation {
  margin-right: -0.5rem; }

.bx--file__drop-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 6rem;
  max-width: 20rem;
  padding: 1rem;
  overflow: hidden;
  border: 1px dashed #8d8d8d; }

.bx--file__drop-container--drag-over {
  background: none;
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--form-item.bx--checkbox-wrapper {
  margin-bottom: 0.25rem; }

.bx--form-item.bx--checkbox-wrapper:first-of-type {
  margin-top: 0.1875rem; }

.bx--label + .bx--form-item.bx--checkbox-wrapper {
  margin-top: -0.125rem; }

.bx--form-item.bx--checkbox-wrapper:last-of-type {
  margin-bottom: 0.1875rem; }

.bx--checkbox {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--checkbox-label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  line-height: 1.5rem;
  position: relative;
  display: flex;
  cursor: pointer;
  padding-left: 1.625rem;
  min-height: 1.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.bx--checkbox-label::before,
.bx--checkbox-label::after {
  box-sizing: border-box; }

.bx--checkbox-label::before {
  content: '';
  width: 1rem;
  height: 1rem;
  margin: 0.125rem;
  position: absolute;
  left: 0;
  top: 0.125rem;
  background-color: transparent;
  border: 1px solid #161616;
  border-radius: 1px; }

.bx--checkbox-label::after {
  content: '';
  position: absolute;
  left: 0.375rem;
  top: 0.5rem;
  width: 0.5625rem;
  height: 0.3125rem;
  background: none;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  -webkit-transform: scale(0) rotate(-45deg);
          transform: scale(0) rotate(-45deg);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  margin-top: -0.1875rem; }

.bx--checkbox:checked + .bx--checkbox-label::before,
.bx--checkbox:indeterminate + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::before {
  background-color: #161616;
  border-color: #161616;
  border-width: 1px; }

.bx--checkbox:checked + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='true']::after {
  -webkit-transform: scale(1) rotate(-45deg);
          transform: scale(1) rotate(-45deg); }

.bx--checkbox:indeterminate + .bx--checkbox-label::after,
.bx--checkbox-label[data-contained-checkbox-state='mixed']::after {
  -webkit-transform: scale(1) rotate(0deg);
          transform: scale(1) rotate(0deg);
  border-left: 0 solid #ffffff;
  border-bottom: 2px solid #ffffff;
  width: 0.5rem;
  top: 0.6875rem; }

.bx--checkbox:focus + .bx--checkbox-label::before,
.bx--checkbox-label__focus::before,
.bx--checkbox:checked:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'].bx--checkbox-label__focus::before,
.bx--checkbox:indeterminate:focus + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'].bx--checkbox-label__focus::before {
  border-color: #0f62fe;
  border-width: 3px;
  width: 1.25rem;
  height: 1.25rem;
  left: -2px;
  top: 0; }

[disabled] ~ _ {
  font-size: inherit; }

.bx--checkbox:disabled + .bx--checkbox-label,
.bx--checkbox-label[data-contained-checkbox-disabled='true'] {
  cursor: not-allowed;
  color: #c6c6c6; }

.bx--checkbox:disabled + .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-disabled='true']::before {
  border-color: #c6c6c6; }

.bx--checkbox:checked:disabled + .bx--checkbox-label::before,
.bx--checkbox:indeterminate:disabled
+ .bx--checkbox-label::before,
.bx--checkbox-label[data-contained-checkbox-state='true'][data-contained-checkbox-disabled='true']::before,
.bx--checkbox-label[data-contained-checkbox-state='mixed'][data-contained-checkbox-disabled='true']::before {
  background-color: #c6c6c6; }

.bx--list-box__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-template: auto auto / auto auto;
  grid-gap: 0.25rem; }
  .bx--list-box__wrapper--inline .bx--label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px; }
  .bx--list-box__wrapper--inline .bx--label,
  .bx--list-box__wrapper--inline .bx--form__helper-text,
  .bx--list-box__wrapper--inline .bx--form-requirement {
    margin: 0; }
  .bx--list-box__wrapper--inline .bx--form__helper-text {
    max-width: none; }
  .bx--list-box__wrapper--inline .bx--form-requirement {
    grid-column: 2; }

.bx--list-box {
  position: relative;
  width: 100%;
  height: 2.5rem;
  max-height: 2.5rem;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  cursor: pointer;
  color: #161616;
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box:hover {
    background-color: #e5e5e5; }

.bx--list-box--expanded {
  border-bottom-color: #e0e0e0; }

.bx--list-box--expanded:hover {
  background-color: #f4f4f4; }

.bx--list-box--expanded:hover.bx--list-box--light:hover {
  background-color: #ffffff; }

.bx--list-box .bx--text-input {
  height: 100%; }

.bx--list-box__invalid-icon {
  position: absolute;
  top: 0.75rem;
  right: 2.5rem;
  fill: #da1e28; }

.bx--list-box--inline .bx--list-box__invalid-icon {
  top: 0.5rem; }

.bx--list-box[data-invalid] .bx--list-box__field {
  border-bottom: 0;
  padding-right: 4rem; }

.bx--list-box[data-invalid].bx--list-box--inline
.bx--list-box__field {
  padding-right: 3.5rem; }

.bx--list-box--light {
  background-color: #ffffff; }

.bx--list-box--light.bx--list-box--expanded {
  border-bottom-width: 0; }

.bx--list-box--disabled:hover {
  background-color: #f4f4f4; }

.bx--list-box--light.bx--list-box--disabled {
  background-color: #ffffff; }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__field:focus {
  border-bottom-width: 0;
  outline: none; }

.bx--list-box--disabled .bx--list-box__label,
.bx--list-box--disabled.bx--list-box--inline
.bx--list-box__label {
  color: #c6c6c6; }

.bx--list-box--disabled .bx--list-box__menu-icon > svg {
  fill: #c6c6c6; }

.bx--list-box--disabled,
.bx--list-box--disabled .bx--list-box__field,
.bx--list-box--disabled .bx--list-box__menu-icon {
  cursor: not-allowed; }

.bx--list-box--disabled .bx--list-box__menu-item,
.bx--list-box--disabled .bx--list-box__menu-item:hover,
.bx--list-box--disabled
.bx--list-box__menu-item--highlighted {
  color: #c6c6c6;
  text-decoration: none; }

.bx--list-box--disabled .bx--list-box__selection:hover {
  cursor: not-allowed; }

.bx--list-box--disabled.bx--list-box[data-invalid]
.bx--list-box__field {
  padding-right: 3rem; }

.bx--list-box--disabled.bx--list-box[data-invalid].bx--list-box--inline
.bx--list-box__field {
  padding-right: 2rem; }

.bx--list-box.bx--list-box--inline {
  background-color: #ffffff;
  border-width: 0; }
  .bx--list-box.bx--list-box--inline:hover {
    background-color: #e5e5e5; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded {
  border-bottom-width: 0; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded
.bx--list-box__field[aria-expanded='true'] {
  border-width: 0; }

.bx--list-box.bx--list-box--inline.bx--list-box--disabled:hover {
  background-color: transparent; }

.bx--list-box.bx--list-box--inline.bx--list-box--expanded:hover {
  background-color: #ffffff; }

.bx--list-box.bx--list-box--inline,
.bx--list-box.bx--list-box--inline
.bx--list-box__field {
  height: 2rem; }

.bx--list-box.bx--list-box--inline
.bx--list-box__field {
  padding: 0 2rem 0 0.5rem; }

.bx--list-box.bx--list-box--inline
.bx--list-box__menu-icon {
  right: 0.5rem; }

.bx--list-box.bx--list-box--inline
.bx--list-box__invalid-icon {
  right: 2rem; }

.bx--list-box--inline .bx--list-box__label {
  color: #161616; }

.bx--list-box__field {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  height: 2.5rem;
  padding: 0 3rem 0 1rem;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  .bx--list-box__field::-moz-focus-inner {
    border: 0; }

.bx--list-box__field:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--list-box__field[disabled] {
  outline: none;
  color: #c6c6c6; }

.bx--list-box__field .bx--text-input {
  padding-right: 4.5rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input {
  padding-right: 6.125rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input
+ .bx--list-box__invalid-icon {
  right: 4.125rem; }

.bx--list-box__field .bx--text-input--empty {
  padding-right: 3rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input--empty {
  padding-right: 4.5rem; }

.bx--list-box[data-invalid]
.bx--list-box__field
.bx--text-input--empty
+ .bx--list-box__invalid-icon {
  right: 2.5rem; }

.bx--list-box__label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #161616;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.bx--list-box__menu-icon {
  position: absolute;
  right: 1rem;
  height: 100%;
  -webkit-transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  cursor: pointer; }

.bx--list-box__menu-icon > svg {
  fill: #161616;
  height: 100%; }

.bx--list-box__menu-icon--open {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--list-box__selection {
  position: absolute;
  right: 2.0625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box__selection:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }

.bx--list-box__selection > svg {
  fill: #525252; }

.bx--list-box--disabled .bx--list-box__selection:focus {
  outline: none; }

.bx--list-box--disabled .bx--list-box__selection > svg {
  fill: #c6c6c6; }

.bx--list-box__selection--multi {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background-color: #393939;
  height: 1.5rem;
  width: auto;
  color: #ffffff;
  line-height: 0;
  padding: 0.5rem;
  padding-right: 0.125rem;
  margin-right: 0.625rem;
  border-radius: 0.75rem; }

.bx--list-box__selection--multi > svg {
  fill: #ffffff;
  margin-left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.125rem; }

.bx--list-box__selection--multi > svg:hover {
  border-radius: 50%;
  background-color: #4c4c4c; }

.bx--list-box__selection--multi:focus,
.bx--list-box__selection--multi:hover {
  outline: none; }

.bx--list-box__menu {
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #f4f4f4;
  max-height: 8.75rem;
  overflow-y: auto;
  z-index: 9100; }

.bx--list-box__menu-item {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  height: 2.5rem;
  color: #393939;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  -webkit-transition: background 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box__menu-item:hover {
    background-color: #e5e5e5; }
  .bx--list-box__menu-item:active {
    background-color: #e0e0e0; }

.bx--list-box--disabled .bx--list-box__menu-item:hover {
  background-color: transparent; }

.bx--list-box--disabled
.bx--list-box__menu-item__option:hover {
  border-top-color: #e0e0e0; }

.bx--list-box__menu-item:first-of-type
.bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box__menu-item:hover
.bx--list-box__menu-item__option {
  color: #161616; }

.bx--list-box__menu-item:hover
+ .bx--list-box__menu-item
.bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box--disabled
.bx--list-box__menu-item:hover
+ .bx--list-box__menu-item
.bx--list-box__menu-item__option {
  border-top-color: #e0e0e0; }

.bx--list-box__menu-item__option {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  height: 2.5rem;
  color: #393939;
  text-decoration: none;
  font-weight: normal;
  line-height: 1rem;
  padding: 0.6875rem 0;
  margin: 0 1rem;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-top-color: #e0e0e0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--list-box__menu-item__option:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    margin: 0;
    padding: 0.6875rem 1rem;
    border-color: transparent; }
  .bx--list-box__menu-item__option:hover {
    color: #161616;
    border-color: transparent; }

.bx--list-box--disabled
.bx--list-box__menu-item:hover
.bx--list-box__menu-item__option,
.bx--list-box--disabled .bx--list-box__menu-item__option {
  color: #c6c6c6; }

.bx--list-box.bx--list-box--inline
.bx--list-box__menu-item__option {
  margin: 0 0.5rem; }
  .bx--list-box.bx--list-box--inline
.bx--list-box__menu-item__option:focus {
    margin: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.bx--list-box__menu-item--highlighted {
  background-color: #e5e5e5;
  color: #161616;
  border-color: transparent; }

.bx--list-box__menu-item--highlighted
.bx--list-box__menu-item__option,
.bx--list-box__menu-item--highlighted
+ .bx--list-box__menu-item
.bx--list-box__menu-item__option {
  border-top-color: transparent; }

.bx--list-box__menu-item--highlighted
.bx--list-box__menu-item__option {
  color: #161616; }

.bx--list-box__menu-item--active {
  color: #161616;
  border-bottom-color: #e0e0e0; }
  .bx--list-box__menu-item--active:hover {
    background-color: #e0e0e0; }

.bx--list-box__menu-item--active
.bx--list-box__menu-item__option {
  color: #161616; }

.bx--list-box__menu-item .bx--checkbox-label {
  width: 100%; }

.bx--list-box__menu-item .bx--checkbox-label-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.bx--list-box input[role='combobox'] {
  background-color: inherit;
  min-width: 0; }

.bx--combo-box .bx--text-input::-webkit-input-placeholder {
  color: #393939;
  opacity: 1; }

.bx--combo-box .bx--text-input::-moz-placeholder {
  color: #393939;
  opacity: 1; }

.bx--combo-box .bx--text-input:-ms-input-placeholder {
  color: #393939;
  opacity: 1; }

.bx--combo-box .bx--text-input::-ms-input-placeholder {
  color: #393939;
  opacity: 1; }

.bx--combo-box .bx--text-input::placeholder {
  color: #393939;
  opacity: 1; }

.bx--combo-box .bx--text-input[disabled]::-webkit-input-placeholder {
  color: #c6c6c6; }

.bx--combo-box .bx--text-input[disabled]::-moz-placeholder {
  color: #c6c6c6; }

.bx--combo-box .bx--text-input[disabled]:-ms-input-placeholder {
  color: #c6c6c6; }

.bx--combo-box .bx--text-input[disabled]::-ms-input-placeholder {
  color: #c6c6c6; }

.bx--combo-box .bx--text-input[disabled]::placeholder {
  color: #c6c6c6; }

.bx--combo-box.bx--list-box--expanded
.bx--text-input {
  border-bottom-color: #e0e0e0; }

.bx--combo-box .bx--list-box__field,
.bx--combo-box.bx--list-box[data-invalid]
.bx--list-box__field {
  padding: 0; }

.bx--radio-button-group {
  display: flex;
  align-items: center;
  margin-top: 0.375rem; }

.bx--label + .bx--form-item .bx--radio-button-group {
  margin-top: 0; }

.bx--radio-button-group--vertical {
  flex-direction: column;
  align-items: flex-start; }
  .bx--radio-button-group--vertical.bx--radio-button-group--label-left {
    align-items: flex-end; }
  .bx--radio-button-group--vertical .bx--radio-button__label {
    margin-right: 0;
    line-height: 1.25rem; }
  .bx--radio-button-group--vertical .bx--radio-button__label:not(:last-of-type) {
    margin-bottom: 0.5rem; }

.bx--radio-button {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
  visibility: inherit; }

.bx--radio-button__label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 1rem; }

.bx--radio-button__appearance {
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #161616;
  flex-shrink: 0;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.5rem; }

.bx--radio-button:checked
+ .bx--radio-button__label
.bx--radio-button__appearance {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #161616; }
  .bx--radio-button:checked
+ .bx--radio-button__label
.bx--radio-button__appearance:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #161616; }
    @media screen and (-ms-high-contrast: active) {
      .bx--radio-button:checked
+ .bx--radio-button__label
.bx--radio-button__appearance:before {
        background-color: windowText; } }

[disabled] ~ _ {
  font-size: inherit; }

.bx--radio-button:disabled + .bx--radio-button__label {
  color: #c6c6c6;
  cursor: not-allowed; }

.bx--radio-button:disabled
+ .bx--radio-button__label
.bx--radio-button__appearance,
.bx--radio-button:disabled:checked
+ .bx--radio-button__label
.bx--radio-button__appearance {
  border-color: #c6c6c6; }
  .bx--radio-button:disabled
+ .bx--radio-button__label
.bx--radio-button__appearance::before,
  .bx--radio-button:disabled:checked
+ .bx--radio-button__label
.bx--radio-button__appearance::before {
    background-color: #c6c6c6; }

.bx--radio-button:focus
+ .bx--radio-button__label
.bx--radio-button__appearance {
  box-shadow: 0 0 0 2px #0f62fe;
  outline: 1px solid transparent; }

.bx--radio-button__label.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 6.25rem;
  height: 1.125rem; }
  .bx--radio-button__label.bx--skeleton:hover, .bx--radio-button__label.bx--skeleton:focus, .bx--radio-button__label.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--radio-button__label.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--radio-button__label.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

.bx--radio-button__label.bx--skeleton
.bx--radio-button__appearance {
  display: none; }

.bx--radio-button-wrapper .bx--radio-button__label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0; }

.bx--radio-button-wrapper:not(:last-of-type) {
  margin-right: 1rem; }

.bx--radio-button-group--vertical
.bx--radio-button-wrapper:not(:last-of-type) {
  margin-right: 0;
  margin-bottom: 0.5rem; }

.bx--radio-button-group--label-right .bx--radio-button__label,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-right
.bx--radio-button__label {
  flex-direction: row; }

.bx--radio-button-group--label-left .bx--radio-button__label,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-left
.bx--radio-button__label {
  flex-direction: row-reverse; }

.bx--radio-button-group--label-left
.bx--radio-button__appearance,
.bx--radio-button-wrapper.bx--radio-button-wrapper--label-left
.bx--radio-button__appearance {
  margin-right: 0;
  margin-left: 0.5rem; }

.bx--toggle {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }
  .bx--toggle:focus {
    outline: none; }

.bx--toggle__label {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0.5rem 0; }

.bx--toggle__appearance {
  position: relative;
  width: 3rem;
  height: 1.5rem; }
  .bx--toggle__appearance:before {
    position: absolute;
    display: block;
    content: '';
    background-color: #8d8d8d;
    cursor: pointer;
    box-sizing: border-box;
    height: 1.5rem;
    width: 3rem;
    border-radius: 0.9375rem;
    top: 0;
    will-change: box-shadow;
    box-shadow: 0 0 0 1px transparent, 0 0 0 3px transparent;
    -webkit-transition: box-shadow 70ms cubic-bezier(0.2, 0, 1, 0.9), background-color 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: box-shadow 70ms cubic-bezier(0.2, 0, 1, 0.9), background-color 70ms cubic-bezier(0.2, 0, 1, 0.9); }
  .bx--toggle__appearance:after {
    box-sizing: border-box;
    position: absolute;
    display: block;
    cursor: pointer;
    left: 0.1875rem;
    top: 0.1875rem;
    width: 1.125rem;
    height: 1.125rem;
    background-color: #ffffff;
    border-radius: 50%;
    content: '';
    -webkit-transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: transform 70ms cubic-bezier(0.2, 0, 1, 0.9), -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9); }

.bx--toggle__check {
  fill: #ffffff;
  position: absolute;
  left: 0.375rem;
  top: 0.375rem;
  width: 0.375rem;
  height: 0.3125rem;
  z-index: 1;
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: 70ms cubic-bezier(0.2, 0, 1, 0.9);
  -webkit-transform: scale(0.2);
          transform: scale(0.2); }

.bx--toggle__text--left,
.bx--toggle__text--right {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  position: relative;
  margin-left: 0.5rem; }

.bx--toggle__text--left {
  position: absolute;
  left: 3rem; }

.bx--toggle:checked
+ .bx--toggle__label
.bx--toggle__text--left,
.bx--toggle:not(:checked)
+ .bx--toggle__label
.bx--toggle__text--right {
  visibility: hidden; }

.bx--toggle:checked
+ .bx--toggle__label
.bx--toggle__text--right,
.bx--toggle:not(:checked)
+ .bx--toggle__label
.bx--toggle__text--left {
  display: inline; }

.bx--toggle:checked
+ .bx--toggle__label
.bx--toggle__appearance:before {
  background-color: #24a148; }

.bx--toggle:checked
+ .bx--toggle__label
.bx--toggle__appearance:after {
  background-color: #ffffff;
  -webkit-transform: translateX(1.5rem);
          transform: translateX(1.5rem); }

.bx--toggle + .bx--toggle__label .bx--toggle__appearance:before,
.bx--toggle + .bx--toggle__label .bx--toggle__appearance:before {
  box-shadow: 0 0 0 1px transparent, 0 0 0 3px transparent; }

.bx--toggle:focus + .bx--toggle__label .bx--toggle__appearance:before,
.bx--toggle:active + .bx--toggle__label .bx--toggle__appearance:before {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #0f62fe; }

.bx--toggle:disabled + .bx--toggle__label {
  cursor: not-allowed; }

.bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__appearance:before {
  background-color: #f4f4f4; }

.bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__appearance:after {
  background-color: #c6c6c6; }

.bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__appearance:before, .bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__appearance:after {
  cursor: not-allowed;
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 1, 0.9);
  transition: 70ms cubic-bezier(0.2, 0, 1, 0.9); }

.bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__text--left,
.bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__text--right {
  color: #c6c6c6; }

.bx--toggle:disabled:active
+ .bx--toggle__label
.bx--toggle__appearance:before {
  box-shadow: none; }

.bx--toggle:disabled
+ .bx--toggle__label
.bx--toggle__check {
  fill: #c6c6c6; }

.bx--toggle--small
+ .bx--toggle__label
.bx--toggle__appearance {
  width: 2rem;
  height: 1rem; }
  .bx--toggle--small
+ .bx--toggle__label
.bx--toggle__appearance:before {
    box-sizing: border-box;
    height: 1rem;
    width: 2rem;
    border-radius: 0.9375rem;
    top: 0; }
  .bx--toggle--small
+ .bx--toggle__label
.bx--toggle__appearance:after {
    width: 0.625rem;
    height: 0.625rem;
    top: 0.1875rem;
    left: 0.1875rem; }

.bx--toggle--small:checked
+ .bx--toggle__label
.bx--toggle__check {
  fill: #24a148;
  -webkit-transform: scale(1) translateX(1rem);
          transform: scale(1) translateX(1rem); }

.bx--toggle--small
+ .bx--toggle__label
.bx--toggle__text--left {
  left: 2rem; }

.bx--toggle--small:checked
+ .bx--toggle__label
.bx--toggle__appearance:after {
  margin-left: 0px;
  -webkit-transform: translateX(1.0625rem);
          transform: translateX(1.0625rem); }

.bx--toggle-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }
  .bx--toggle-input:focus {
    outline: none; }

.bx--toggle-input__label {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #393939;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer; }

.bx--toggle__switch {
  position: relative;
  display: flex;
  align-items: center;
  width: 3rem;
  height: 1.5rem;
  margin: 0.5rem 0;
  cursor: pointer; }
  .bx--toggle__switch::before {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    display: block;
    width: 3rem;
    height: 1.5rem;
    border-radius: 0.9375rem;
    content: '';
    background-color: #8d8d8d;
    will-change: box-shadow;
    box-shadow: 0 0 0 1px transparent, 0 0 0 3px transparent;
    -webkit-transition: box-shadow 70ms cubic-bezier(0.2, 0, 1, 0.9), background-color 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: box-shadow 70ms cubic-bezier(0.2, 0, 1, 0.9), background-color 70ms cubic-bezier(0.2, 0, 1, 0.9); }
  .bx--toggle__switch::after {
    box-sizing: border-box;
    position: absolute;
    top: 0.1875rem;
    left: 0.1875rem;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    background-color: #ffffff;
    content: '';
    -webkit-transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: transform 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: transform 70ms cubic-bezier(0.2, 0, 1, 0.9), -webkit-transform 70ms cubic-bezier(0.2, 0, 1, 0.9); }

.bx--toggle__text--off,
.bx--toggle__text--on {
  position: absolute;
  margin-left: 3.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.bx--toggle-input:checked
+ .bx--toggle-input__label
> .bx--toggle__switch
> .bx--toggle__text--off,
.bx--toggle-input:not(:checked)
+ .bx--toggle-input__label
> .bx--toggle__switch
> .bx--toggle__text--on {
  visibility: hidden; }

.bx--toggle-input:checked
+ .bx--toggle-input__label
> .bx--toggle__switch::before {
  background-color: #24a148; }

.bx--toggle-input:checked
+ .bx--toggle-input__label
> .bx--toggle__switch::after {
  background-color: #ffffff;
  -webkit-transform: translateX(1.5rem);
          transform: translateX(1.5rem); }

.bx--toggle-input:focus
+ .bx--toggle-input__label
> .bx--toggle__switch::before,
.bx--toggle-input:active
+ .bx--toggle-input__label
> .bx--toggle__switch::before {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 3px #0f62fe; }

.bx--toggle-input:disabled + .bx--toggle-input__label {
  cursor: not-allowed; }

.bx--toggle-input:disabled
+ .bx--toggle-input__label
> .bx--toggle__switch {
  cursor: not-allowed; }
  .bx--toggle-input:disabled
+ .bx--toggle-input__label
> .bx--toggle__switch::before {
    background-color: #f4f4f4; }
  .bx--toggle-input:disabled
+ .bx--toggle-input__label
> .bx--toggle__switch::after {
    background-color: #c6c6c6; }
  .bx--toggle-input:disabled
+ .bx--toggle-input__label
> .bx--toggle__switch::before, .bx--toggle-input:disabled
+ .bx--toggle-input__label
> .bx--toggle__switch::after {
    cursor: not-allowed;
    -webkit-transition: 70ms cubic-bezier(0.2, 0, 1, 0.9);
    transition: 70ms cubic-bezier(0.2, 0, 1, 0.9); }

.bx--toggle-input:disabled + .bx--toggle-input__label {
  color: #c6c6c6; }

.bx--toggle-input:disabled:active
+ .bx--toggle-input__label
> .bx--toggle__switch::before {
  box-shadow: none; }

.bx--toggle-input--small + .bx--toggle-input__label > .bx--toggle__switch {
  width: 2rem;
  height: 1rem; }
  .bx--toggle-input--small + .bx--toggle-input__label > .bx--toggle__switch::before {
    width: 2rem;
    height: 1rem;
    border-radius: 0.9375rem; }
  .bx--toggle-input--small + .bx--toggle-input__label > .bx--toggle__switch::after {
    width: 0.625rem;
    height: 0.625rem; }

.bx--toggle-input--small + .bx--toggle-input__label .bx--toggle__text--off,
.bx--toggle-input--small + .bx--toggle-input__label .bx--toggle__text--on {
  margin-left: 2.5rem; }

.bx--toggle-input--small:checked + .bx--toggle-input__label > .bx--toggle__switch::after {
  -webkit-transform: translateX(1.0625rem);
          transform: translateX(1.0625rem); }

.bx--toggle-input--small:checked + .bx--toggle-input__label .bx--toggle__check {
  fill: #24a148;
  -webkit-transform: scale(1) translateX(1rem);
          transform: scale(1) translateX(1rem); }

.bx--toggle-input--small:disabled:checked
+ .bx--toggle-input__label
.bx--toggle__check {
  fill: #c6c6c6; }

.bx--search {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%; }

.bx--search .bx--label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--search-input {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0;
  outline: 2px solid transparent;
  outline-offset: -2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background-color: #f4f4f4;
  color: #161616;
  padding: 0 2.5rem;
  text-overflow: ellipsis;
  width: 100%;
  order: 1;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  border-bottom: 1px solid #8d8d8d; }
  .bx--search-input:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--search-input::-webkit-input-placeholder {
    color: #6f6f6f;
    font-weight: 400; }
  .bx--search-input::-moz-placeholder {
    color: #6f6f6f;
    font-weight: 400; }
  .bx--search-input:-ms-input-placeholder {
    color: #6f6f6f;
    font-weight: 400; }
  .bx--search-input::-ms-input-placeholder {
    color: #6f6f6f;
    font-weight: 400; }
  .bx--search-input::placeholder {
    color: #6f6f6f;
    font-weight: 400; }
  .bx--search-input::-ms-clear {
    display: none; }

.bx--search-input[disabled] {
  color: #c6c6c6;
  background-color: #f4f4f4;
  border-bottom: 1px solid transparent;
  cursor: not-allowed; }
  .bx--search-input[disabled]::-webkit-input-placeholder {
    color: #c6c6c6;
    font-weight: 400; }
  .bx--search-input[disabled]::-moz-placeholder {
    color: #c6c6c6;
    font-weight: 400; }
  .bx--search-input[disabled]:-ms-input-placeholder {
    color: #c6c6c6;
    font-weight: 400; }
  .bx--search-input[disabled]::-ms-input-placeholder {
    color: #c6c6c6;
    font-weight: 400; }
  .bx--search-input[disabled]::placeholder {
    color: #c6c6c6;
    font-weight: 400; }

.bx--search-input[disabled] ~ .bx--search-magnifier {
  fill: #c6c6c6; }

.bx--search--light .bx--search-input {
  background: #ffffff; }

.bx--search--sm .bx--search-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  height: 2rem; }

.bx--search--xl .bx--search-input {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0;
  height: 3rem;
  padding: 0 4rem 0 3rem; }

.bx--search-magnifier {
  left: 0.75rem;
  z-index: 2;
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  fill: #393939;
  pointer-events: none; }

.bx--search--xl .bx--search-magnifier {
  height: 1.25rem;
  width: 1.25rem;
  left: 1.5rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.bx--search-close {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: absolute;
  right: 0; }
  .bx--search-close::-moz-focus-inner {
    border: 0; }
  .bx--search-close::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0.0625rem;
    height: calc(100% - 2px);
    width: 2px;
    background-color: #f4f4f4;
    -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--search-close:hover {
    border-bottom: 1px solid #8d8d8d; }

.bx--search-button {
  flex-shrink: 0;
  margin-left: 0.125rem;
  background-color: #f4f4f4; }
  .bx--search-button svg {
    vertical-align: middle;
    fill: currentColor; }

.bx--search-close,
.bx--search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), border 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  cursor: pointer;
  visibility: visible;
  opacity: 1;
  height: 2.5rem;
  width: 2.5rem;
  fill: #161616;
  border: 1px solid transparent;
  border-left: 0; }
  .bx--search-close:hover,
  .bx--search-button:hover {
    background-color: #e5e5e5; }
  .bx--search-close:focus,
  .bx--search-button:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--search-close:active,
  .bx--search-button:active {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    background-color: #e0e0e0; }

.bx--search-close:hover::before {
  background-color: #e5e5e5; }

.bx--search-close:focus::before,
.bx--search-close:active::before {
  background-color: #0f62fe; }

.bx--search-input:focus ~ .bx--search-close:hover {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--search--sm .bx--search-close,
.bx--search--sm ~ .bx--search-button {
  height: 2rem;
  width: 2rem; }

.bx--search--xl .bx--search-close,
.bx--search--xl ~ .bx--search-button {
  height: 3rem;
  width: 3rem; }

.bx--search-close--hidden {
  visibility: hidden;
  opacity: 0; }

.bx--search--lg.bx--skeleton .bx--search-input,
.bx--search--sm.bx--skeleton .bx--search-input {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%; }
  .bx--search--lg.bx--skeleton .bx--search-input:hover, .bx--search--lg.bx--skeleton .bx--search-input:focus, .bx--search--lg.bx--skeleton .bx--search-input:active,
  .bx--search--sm.bx--skeleton .bx--search-input:hover,
  .bx--search--sm.bx--skeleton .bx--search-input:focus,
  .bx--search--sm.bx--skeleton .bx--search-input:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--search--lg.bx--skeleton .bx--search-input:before,
  .bx--search--sm.bx--skeleton .bx--search-input:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--search--lg.bx--skeleton .bx--search-input:before,
      .bx--search--sm.bx--skeleton .bx--search-input:before {
        -webkit-animation: none;
                animation: none; } }
  .bx--search--lg.bx--skeleton .bx--search-input::-webkit-input-placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input::-webkit-input-placeholder {
    color: transparent; }
  .bx--search--lg.bx--skeleton .bx--search-input::-moz-placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input::-moz-placeholder {
    color: transparent; }
  .bx--search--lg.bx--skeleton .bx--search-input:-ms-input-placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input:-ms-input-placeholder {
    color: transparent; }
  .bx--search--lg.bx--skeleton .bx--search-input::-ms-input-placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input::-ms-input-placeholder {
    color: transparent; }
  .bx--search--lg.bx--skeleton .bx--search-input::placeholder,
  .bx--search--sm.bx--skeleton .bx--search-input::placeholder {
    color: transparent; }

.bx--select {
  position: relative;
  display: flex;
  flex-direction: column; }

.bx--select-input__wrapper {
  position: relative;
  display: flex;
  align-items: center; }
  .bx--select-input__wrapper:hover .bx--select-input {
    background-color: #e5e5e5; }

.bx--select-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  height: 2.5rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: block;
  width: 14rem;
  min-width: 8rem;
  max-width: 28rem;
  padding: 0 2.625rem 0 1rem;
  color: #161616;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  border-radius: 0;
  cursor: pointer;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--select-input:hover {
    background-color: #e5e5e5; }
  .bx--select-input::-ms-expand {
    display: none; }

@-moz-document url-prefix() {
  .bx--select-input {
    padding-top: 0.25rem; }
    .bx--select-input:-moz-focusring, .bx--select-input::-moz-focus-inner {
      color: transparent;
      text-shadow: 0 0 0 #000;
      background-image: none; } }
  .bx--select-input:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    color: #161616; }
  .bx--select-input:disabled, .bx--select-input:hover:disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
    color: #c6c6c6;
    border-bottom-color: #f4f4f4; }

.bx--select--disabled .bx--label,
.bx--select--disabled .bx--form__helper-text {
  color: #c6c6c6; }

.bx--select-input__wrapper[data-invalid] .bx--select-input {
  padding-right: 4rem; }

.bx--select-input__wrapper[data-invalid] .bx--select-input,
.bx--select-input__wrapper[data-invalid]
.bx--select-input:focus {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }

.bx--form-requirement {
  display: block;
  color: #da1e28;
  font-weight: 400;
  overflow: visible; }

.bx--select-input:disabled ~ .bx--select__arrow {
  fill: #c6c6c6; }

.bx--select--light .bx--select-input {
  background-color: #ffffff; }
  .bx--select--light .bx--select-input:hover {
    background-color: #f4f4f4; }
  .bx--select--light .bx--select-input:disabled, .bx--select--light .bx--select-input:hover:disabled {
    cursor: not-allowed;
    background-color: #f4f4f4;
    color: #c6c6c6; }

.bx--select__arrow {
  fill: #161616;
  position: absolute;
  right: 1rem;
  pointer-events: none; }

.bx--select-input__wrapper[data-invalid]
.bx--select-input
~ .bx--select__invalid-icon {
  position: absolute;
  right: 2.125rem; }

.bx--select-input__wrapper[data-invalid]
.bx--select-input
~ .bx--select__invalid-icon {
  fill: #da1e28; }

.bx--select-optgroup,
.bx--select-option {
  color: #161616; }

.bx--select-option[disabled] {
  opacity: 0.5;
  cursor: not-allowed; }

@-moz-document url-prefix() {
  .bx--select-option {
    background-color: #f4f4f4;
    color: #161616; }
  .bx--select-optgroup {
    color: #161616; } }

.bx--select--inline {
  display: flex;
  flex-direction: row;
  align-items: center; }

.bx--select--inline.bx--select--invalid .bx--label,
.bx--select--inline.bx--select--invalid
.bx--form__helper-text {
  margin-top: 0.8125rem;
  align-self: flex-start; }

.bx--select--inline .bx--form__helper-text {
  margin-bottom: 0;
  margin-left: 0.5rem; }

.bx--select--inline .bx--label {
  white-space: nowrap;
  margin: 0 0.5rem 0 0; }

.bx--select--inline .bx--select-input {
  background-color: transparent;
  color: #161616;
  border-bottom: none;
  padding-left: 0.5rem;
  padding-right: 1.625rem; }

@-moz-document url-prefix() {
  .bx--select--inline .bx--select-input {
    padding-top: 0; } }
  .bx--select--inline .bx--select-input:hover {
    background-color: #e5e5e5; }

.bx--select--inline .bx--select__arrow {
  bottom: auto;
  top: 1.125rem;
  right: 0.5rem; }

.bx--select--inline.bx--select--invalid
.bx--select-input {
  padding-right: 3.125rem; }

.bx--select--inline.bx--select--invalid
.bx--select-input
~ .bx--select__invalid-icon {
  right: 1.5rem; }

.bx--select--inline .bx--select-input:disabled {
  color: #c6c6c6;
  cursor: not-allowed; }
  .bx--select--inline .bx--select-input:disabled:hover {
    background-color: transparent; }
  .bx--select--inline .bx--select-input:disabled ~ * {
    cursor: not-allowed; }

.bx--select--inline .bx--select-input:disabled {
  cursor: not-allowed; }

.bx--select.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%;
  height: 2.5rem; }
  .bx--select.bx--skeleton:hover, .bx--select.bx--skeleton:focus, .bx--select.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--select.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--select.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

.bx--select.bx--skeleton .bx--select-input {
  display: none; }

.bx--text-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #f4f4f4;
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  color: #161616;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-input:focus, .bx--text-input:active {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--text-input-wrapper svg[hidden] {
    display: none; }

.bx--password-input {
  padding-right: 2.5rem; }

.bx--text-input::-webkit-input-placeholder {
  color: #a8a8a8; }

.bx--text-input--light {
  background-color: #ffffff; }

.bx--text-input__field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; }
  .bx--text-input__field-wrapper .bx--text-input__invalid-icon {
    position: absolute;
    right: 1rem;
    fill: #da1e28; }
  .bx--text-input__field-wrapper .bx--text-input--password__visibility {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    overflow: visible; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus {
      outline: 1px solid #0f62fe; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus {
      outline: 1px solid transparent; }
      .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus svg {
        outline: 1px solid #0f62fe; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility + .bx--assistive-text {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      position: absolute;
      z-index: 10000;
      display: flex;
      align-items: center;
      opacity: 0;
      pointer-events: none; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .bx--text-input__field-wrapper .bx--text-input--password__visibility::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility + .bx--assistive-text {
          display: inline-block; } }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility::after {
      -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
      transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility.bx--tooltip--a11y::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility.bx--tooltip--a11y::after {
      -webkit-transition: none;
      transition: none; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before {
      width: 0;
      height: 0;
      border-style: solid;
      content: ''; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility + .bx--assistive-text {
      box-sizing: content-box;
      color: inherit;
      opacity: 1;
      white-space: normal; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      max-width: 13rem;
      height: auto;
      padding: 0.1875rem 1rem;
      border-radius: 0.125rem;
      color: #ffffff;
      font-weight: 400;
      text-align: left;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      pointer-events: none;
      background-color: #393939; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
          width: 13rem; } }
      @supports (-ms-accelerator: true) {
        .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
          width: 13rem; } }
      @supports (-ms-ime-align: auto) {
        .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text,
        .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
          width: 13rem; } }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::after {
      content: attr(aria-label); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility.bx--tooltip--a11y::after {
      content: none; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover::after, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus::after {
      opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
      clip: auto;
      margin: auto;
      overflow: visible; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text, .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover.bx--tooltip--a11y::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text, .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus.bx--tooltip--a11y::before {
      -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
              animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before, .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
      bottom: 0;
      left: 50%; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::before {
      bottom: -0.5rem;
      border-width: 0 0.25rem 0.3125rem 0.25rem;
      border-color: transparent transparent #393939 transparent;
      -webkit-transform: translate(-50%, 100%);
              transform: translate(-50%, 100%); }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility::after,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:hover + .bx--assistive-text,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility:focus + .bx--assistive-text {
      bottom: -0.8125rem;
      -webkit-transform: translate(-50%, 100%);
              transform: translate(-50%, 100%); }
  .bx--text-input__field-wrapper .bx--text-input--password__visibility,
  .bx--text-input__field-wrapper .bx--text-input--password__visibility__toggle.bx--tooltip__trigger {
    position: absolute;
    height: 1rem;
    width: 1rem;
    right: 1rem;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer; }
    .bx--text-input__field-wrapper .bx--text-input--password__visibility svg,
    .bx--text-input__field-wrapper .bx--text-input--password__visibility__toggle.bx--tooltip__trigger svg {
      fill: #525252; }
  .bx--text-input__field-wrapper .bx--text-input--invalid {
    padding-right: 2.5rem; }
  .bx--text-input__field-wrapper .bx--text-input--invalid.bx--password-input {
    padding-right: 4rem; }
  .bx--text-input__field-wrapper .bx--text-input--invalid
+ .bx--text-input--password__visibility,
  .bx--text-input__field-wrapper .bx--text-input--invalid
+ .bx--text-input--password__visibility__toggle {
    right: 1rem; }

.bx--password-input-wrapper .bx--text-input__invalid-icon {
  right: 2.5rem; }

.bx--text-input:disabled
+ .bx--text-input--password__visibility
svg,
.bx--text-input:disabled
+ .bx--text-input--password__visibility__toggle
svg {
  opacity: 0.5;
  cursor: not-allowed; }

.bx--text-input:disabled {
  cursor: not-allowed;
  outline: none;
  background-color: #f4f4f4;
  border-bottom: 1px solid transparent;
  color: #c6c6c6; }

.bx--text-input--light:disabled {
  background-color: #ffffff; }

.bx--text-input:disabled::-webkit-input-placeholder {
  opacity: 1;
  color: #c6c6c6; }

.bx--text-input:disabled::-moz-placeholder {
  opacity: 1;
  color: #c6c6c6; }

.bx--text-input:disabled:-ms-input-placeholder {
  opacity: 1;
  color: #c6c6c6; }

.bx--text-input:disabled::-ms-input-placeholder {
  opacity: 1;
  color: #c6c6c6; }

.bx--text-input:disabled::placeholder {
  opacity: 1;
  color: #c6c6c6; }

.bx--text-input--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  box-shadow: none; }
  .bx--text-input--invalid .bx--text-input--password__visibility,
  .bx--text-input--invalid .bx--text-input--password__visibility__toggle {
    right: 2.5rem; }

.bx--text-area {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #f4f4f4;
  width: 100%;
  min-width: 10rem;
  min-height: 2.5rem;
  padding: 0.6875rem 1rem;
  color: #161616;
  order: 3;
  resize: vertical;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--text-area ~ .bx--form__helper-text {
    margin-top: 0;
    order: 2; }
  .bx--text-area ~ .bx--form-requirement {
    order: 4;
    color: #da1e28;
    font-weight: 400;
    margin-top: 0.25rem; }
    .bx--text-area ~ .bx--form-requirement::before {
      display: none; }

.bx--text-area:focus,
.bx--text-area:active {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--text-area::-webkit-input-placeholder {
  color: #6f6f6f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area::-moz-placeholder {
  color: #6f6f6f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area:-ms-input-placeholder {
  color: #6f6f6f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area::-ms-input-placeholder {
  color: #6f6f6f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area::placeholder {
  color: #6f6f6f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  opacity: 1; }

.bx--text-area--light {
  background-color: #ffffff; }

.bx--text-area--invalid {
  padding-right: 2.5rem; }

.bx--text-area__wrapper {
  position: relative;
  display: flex; }

.bx--text-area__invalid-icon {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  fill: #da1e28; }

.bx--text-area:disabled {
  cursor: not-allowed;
  outline: none;
  background-color: #f4f4f4;
  border-bottom: 1px solid transparent; }

.bx--text-area:disabled::-webkit-input-placeholder {
  color: #c6c6c6; }

.bx--text-area:disabled::-moz-placeholder {
  color: #c6c6c6; }

.bx--text-area:disabled:-ms-input-placeholder {
  color: #c6c6c6; }

.bx--text-area:disabled::-ms-input-placeholder {
  color: #c6c6c6; }

.bx--text-area:disabled::placeholder {
  color: #c6c6c6; }

bx--text-area.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  height: 6.25rem; }
  bx--text-area.bx--skeleton:hover, bx--text-area.bx--skeleton:focus, bx--text-area.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  bx--text-area.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      bx--text-area.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }
  bx--text-area.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton::-moz-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton:-ms-input-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton::-ms-input-placeholder {
    color: transparent; }
  bx--text-area.bx--skeleton::placeholder {
    color: transparent; }

.bx--number {
  display: flex;
  flex-direction: column;
  position: relative; }

.bx--number input[type='number'] {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
  min-width: 9.375rem;
  padding-left: 1rem;
  padding-right: 2rem;
  font-weight: 300;
  height: 2.5rem;
  color: #161616;
  background-color: #f4f4f4;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #8d8d8d;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -moz-appearance: textfield; }
  .bx--number input[type='number']:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--number input[type='number']:disabled ~ .bx--number__controls {
    cursor: not-allowed;
    pointer-events: none; }
  .bx--number input[type='number']:disabled ~ .bx--number__controls svg {
    fill: #c6c6c6; }
  .bx--number input[type='number']::-ms-clear {
    display: none; }
  .bx--number input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
            appearance: none; }

.bx--number input[type='number']:disabled,
.bx--number--readonly input[type='number'] {
  cursor: not-allowed;
  background-color: #f4f4f4;
  color: #c6c6c6; }

.bx--number__input-wrapper {
  display: flex;
  align-items: center; }
  .bx--number__input-wrapper ~ .bx--form-requirement {
    color: #da1e28;
    font-weight: 400;
    margin-top: 0.25rem;
    overflow: visible; }
    .bx--number__input-wrapper ~ .bx--form-requirement::before {
      display: none; }

.bx--number__controls {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.bx--number__control-btn {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 1.25rem;
  color: #161616; }
  .bx--number__control-btn::-moz-focus-inner {
    border: 0; }
  .bx--number__control-btn svg {
    fill: currentColor;
    position: relative; }
  .bx--number__control-btn.up-icon svg {
    top: 0.3125rem; }
  .bx--number__control-btn.down-icon svg {
    top: -0.3125rem; }
  .bx--number__control-btn:focus {
    outline: 1px solid #0f62fe;
    color: #161616;
    outline-width: 2px;
    outline-offset: -2px; }
  .bx--number__control-btn:hover {
    cursor: pointer; }
  .bx--number__control-btn:hover {
    color: #161616; }
  .bx--number__control-btn:disabled {
    cursor: not-allowed;
    color: #c6c6c6; }

.bx--number--readonly .bx--number__control-btn {
  display: none; }

.bx--number[data-invalid] .bx--form-requirement {
  display: inline-block;
  max-height: 12.5rem; }

.bx--number[data-invalid] input[type='number'] {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }

.bx--number__invalid {
  position: absolute;
  right: 2rem;
  fill: #da1e28; }

.bx--number--light input[type='number'] {
  background-color: #ffffff; }

.bx--number--mobile {
  min-width: 9rem;
  width: auto; }
  .bx--number--mobile .bx--number__control-btn,
  .bx--number--mobile.bx--number--light .bx--number__control-btn {
    position: static;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #f4f4f4; }
    .bx--number--mobile .bx--number__control-btn:hover, .bx--number--mobile .bx--number__control-btn:focus,
    .bx--number--mobile.bx--number--light .bx--number__control-btn:hover,
    .bx--number--mobile.bx--number--light .bx--number__control-btn:focus {
      background-color: #e5e5e5; }
    .bx--number--mobile .bx--number__control-btn:focus,
    .bx--number--mobile.bx--number--light .bx--number__control-btn:focus {
      outline-width: 2px;
      outline-offset: -2px; }
    .bx--number--mobile .bx--number__control-btn svg,
    .bx--number--mobile.bx--number--light .bx--number__control-btn svg {
      position: static; }
  .bx--number--mobile input[type='number'] {
    min-width: 4rem;
    width: auto;
    margin: 0;
    border-right: 1px solid #e0e0e0;
    border-left: 1px solid #e0e0e0;
    padding: 0;
    text-align: center;
    background-color: #f4f4f4; }
  .bx--number--mobile.bx--number--light input[type='number'] {
    background-color: #ffffff; }
  .bx--number--mobile.bx--number--light .bx--number__control-btn {
    background-color: #ffffff; }

.bx--number.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%;
  height: 2.5rem; }
  .bx--number.bx--skeleton:hover, .bx--number.bx--skeleton:focus, .bx--number.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--number.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--number.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }
  .bx--number.bx--skeleton input[type='number'] {
    display: none; }

.bx--link {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #0f62fe;
  text-decoration: none;
  outline: none;
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--link:hover {
    color: #0f62fe;
    text-decoration: underline; }
  .bx--link:active, .bx--link:active:visited {
    color: #161616;
    text-decoration: underline; }
  .bx--link:focus {
    outline: 1px solid #0f62fe; }
  .bx--link:not([href]) {
    color: #c6c6c6;
    cursor: not-allowed;
    pointer-events: none;
    touch-action: none; }
  .bx--link:visited {
    color: #8a3ffc; }
  .bx--link:visited:hover {
    color: #0043ce; }

.bx--link--disabled {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: inline;
  color: #c6c6c6;
  font-weight: 400;
  cursor: not-allowed; }

.bx--link.bx--link--inline {
  text-decoration: underline; }
  .bx--link.bx--link--inline:hover {
    color: #0043ce; }
  .bx--link.bx--link--inline:focus {
    text-decoration: none; }
  .bx--link.bx--link--inline:visited {
    text-decoration: none; }

.bx--link--disabled.bx--link--inline {
  text-decoration: underline; }

.bx--list--nested,
.bx--list--unordered,
.bx--list--ordered {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  counter-reset: listitem; }

.bx--list__item {
  font-weight: 400;
  color: #161616;
  list-style-type: none;
  counter-increment: listitem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  position: relative; }

.bx--list__item:before {
  position: absolute;
  left: -0.5rem; }

.bx--list--nested {
  margin-bottom: 0.25rem;
  margin-left: 2rem; }

.bx--list--unordered > .bx--list__item:before,
.bx--list--ordered > .bx--list__item:before {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem; }

.bx--list--unordered
.bx--list--nested
> .bx--list__item:before,
.bx--list--ordered
.bx--list--nested
> .bx--list__item:before {
  margin-right: 0.5rem;
  display: inline-block; }

.bx--list--unordered > .bx--list__item:before {
  content: '\002013'; }

.bx--list--unordered
ul.bx--list--nested
> .bx--list__item:before {
  content: '\0025AA'; }

.bx--list--unordered
ol.bx--list--nested
> .bx--list__item:before {
  content: counter(listitem, lower-alpha) "."; }

.bx--list--ordered > .bx--list__item:before {
  content: counter(listitem) "."; }

.bx--list--ordered ol.bx--list--nested {
  counter-reset: ol-counter; }

.bx--list--ordered
ol.bx--list--nested
> .bx--list__item {
  counter-increment: ol-counter; }

.bx--list--ordered
ol.bx--list--nested
> .bx--list__item:before {
  content: counter(ol-counter, lower-alpha) ".";
  width: 0.6rem; }

.bx--list--ordered
ul.bx--list--nested
> .bx--list__item:before {
  content: '\0025AA'; }

.bx--table-toolbar {
  background: #f4f4f4;
  display: flex;
  height: 3rem;
  overflow: hidden;
  position: relative;
  width: 100%; }

.bx--toolbar-content {
  display: flex;
  height: 3rem;
  width: 100%;
  justify-content: flex-end;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-actions ~ .bx--toolbar-content {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); }

.bx--toolbar-content .bx--search .bx--search-input {
  background-color: transparent; }

.bx--batch-actions ~ .bx--toolbar-search-container {
  opacity: 1;
  -webkit-transition: opacity 110ms;
  transition: opacity 110ms;
  display: flex;
  align-items: center; }

.bx--toolbar-search-container-expandable {
  height: 3rem;
  width: 3rem;
  position: relative;
  -webkit-transition: flex 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: flex 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  box-shadow: none; }

.bx--toolbar-search-container-expandable .bx--search {
  width: 3rem;
  height: 100%;
  position: static;
  position: initial; }

.bx--toolbar-search-container-expandable
.bx--search
.bx--search-magnifier {
  height: 3rem;
  width: 3rem;
  padding: 1rem;
  left: 0;
  cursor: pointer;
  pointer-events: all;
  -webkit-transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--toolbar-search-container-expandable
.bx--search
.bx--search-magnifier:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--toolbar-search-container-expandable
.bx--search
.bx--search-magnifier:hover {
  background: #e5e5e5; }

.bx--toolbar-search-container-expandable
.bx--search
.bx--search-input {
  border: none;
  height: 100%;
  visibility: hidden;
  padding: 0;
  background-color: transparent; }

.bx--toolbar-search-container-expandable
.bx--search
.bx--search-close {
  height: 3rem;
  width: 3rem; }
  .bx--toolbar-search-container-expandable
.bx--search
.bx--search-close:before {
    top: 2px;
    height: calc(100% - 4px);
    background-color: #e5e5e5; }

.bx--toolbar-search-container-active {
  flex: auto;
  -webkit-transition: flex 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: flex 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--toolbar-search-container-active .bx--search {
  width: 100%; }

.bx--toolbar-search-container-active
.bx--search
.bx--search-input {
  padding-left: 3rem;
  visibility: visible; }

.bx--toolbar-search-container-active
.bx--search
.bx--search-input:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--toolbar-search-container-active
.bx--search
.bx--search-input:focus
+ .bx--search-close {
  border: none;
  box-shadow: none;
  outline: none; }

.bx--toolbar-search-container-active
.bx--search
.bx--search-input:not(:placeholder-shown) {
  background: #e5e5e5;
  border: none; }

.bx--toolbar-search-container-active
.bx--search
.bx--search-magnifier:focus,
.bx--toolbar-search-container-active
.bx--search
.bx--search-magnifier:active,
.bx--toolbar-search-container-active
.bx--search
.bx--search-magnifier:hover {
  outline: none;
  border: none;
  background: transparent; }

.bx--toolbar-search-container-persistent .bx--search-close,
.bx--toolbar-search-container-persistent
.bx--search-close:hover,
.bx--toolbar-search-container-active
.bx--search
.bx--search-close,
.bx--toolbar-search-container-active
.bx--search
.bx--search-close:hover {
  border: none;
  background-color: transparent; }

.bx--overflow-menu.bx--toolbar-action {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  padding: 1rem;
  -webkit-transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu.bx--toolbar-action::-moz-focus-inner {
    border: 0; }

.bx--toolbar-action {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  -webkit-transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--toolbar-action::-moz-focus-inner {
    border: 0; }

.bx--toolbar-action:hover:not([disabled]) {
  background: #e5e5e5; }

.bx--toolbar-action:hover[aria-expanded='true'] {
  background: #f4f4f4; }

.bx--toolbar-action[disabled] {
  cursor: not-allowed; }

.bx--toolbar-action[disabled] .bx--toolbar-action__icon {
  fill: #c6c6c6;
  cursor: not-allowed; }

.bx--toolbar-action:focus:not([disabled]),
.bx--toolbar-action:active:not([disabled]) {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  .bx--toolbar-action:focus:not([disabled]).bx--toolbar-search-container-expandable,
  .bx--toolbar-action:active:not([disabled]).bx--toolbar-search-container-expandable {
    outline: none; }

.bx--toolbar-action ~ .bx--btn {
  margin: 0;
  height: 3rem; }

.bx--overflow-menu--data-table {
  height: 3rem; }

.bx--toolbar-action__icon {
  height: 1rem;
  width: auto;
  max-width: 1rem;
  fill: #161616; }

.bx--toolbar-search-container-persistent {
  width: 100%;
  height: 3rem;
  opacity: 1;
  position: relative; }

.bx--toolbar-search-container-persistent
+ .bx--toolbar-content {
  position: relative;
  width: auto; }

.bx--toolbar-search-container-persistent .bx--search {
  position: static;
  position: initial; }

.bx--toolbar-search-container-persistent
.bx--search
.bx--search-magnifier {
  left: 1rem; }

.bx--toolbar-search-container-persistent
.bx--search
.bx--search-input {
  height: 3rem;
  padding-left: 3rem;
  border: none; }

.bx--toolbar-search-container-persistent
.bx--search
.bx--search-input:focus:not([disabled]) {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--toolbar-search-container-persistent
.bx--search
.bx--search-input:hover:not([disabled]) {
  background: #e5e5e5; }

.bx--toolbar-search-container-persistent
.bx--search
.bx--search-input:active:not([disabled]),
.bx--toolbar-search-container-persistent
.bx--search
.bx--search-input:not(:placeholder-shown) {
  background: #e5e5e5; }

.bx--toolbar-search-container-persistent
.bx--search
.bx--search-close {
  height: 3rem;
  width: 3rem; }

.bx--batch-actions--active ~ .bx--toolbar-search-container,
.bx--batch-actions--active ~ .bx--toolbar-content {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  -webkit-transform: translate3d(0, 48px, 0);
          transform: translate3d(0, 48px, 0);
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-actions {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  height: 100%;
  pointer-events: none;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
          clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  will-change: transform;
  background-color: #0f62fe;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-clip-path 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-transform: translate3d(0, 48px, 0);
          transform: translate3d(0, 48px, 0); }

.bx--batch-actions:focus {
  outline: 1px solid #0f62fe; }

.bx--batch-actions--active {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  pointer-events: all;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.bx--action-list {
  position: absolute;
  top: 0;
  right: 0;
  display: flex; }

.bx--action-list .bx--btn {
  color: #ffffff; }

.bx--action-list .bx--btn .bx--btn__icon {
  fill: #ffffff;
  margin-left: 0.5rem; }

.bx--action-list .bx--btn .bx--btn__icon .st0 {
  fill: none; }

.bx--batch-download {
  padding: 0.0625rem; }

.bx--action-list .bx--btn--primary:focus::before,
.bx--action-list .bx--btn--primary:focus::before,
.bx--action-list .bx--btn--primary::before,
.bx--action-list .bx--btn--primary::before,
.bx--action-list .bx--btn--primary:focus::after,
.bx--action-list .bx--btn--primary:focus::after,
.bx--action-list .bx--btn--primary::after,
.bx--action-list .bx--btn--primary::after {
  display: none; }

.bx--action-list .bx--btn--primary:focus {
  outline: 2px solid #f4f4f4;
  outline-offset: -0.125rem; }

.bx--action-list .bx--btn {
  min-width: 0; }

.bx--action-list
.bx--btn--primary:nth-child(3):hover
+ .bx--btn--primary.bx--batch-summary__cancel::before,
.bx--action-list
.bx--btn--primary:nth-child(3):focus
+ .bx--btn--primary.bx--batch-summary__cancel::before {
  opacity: 0; }

.bx--btn--primary.bx--batch-summary__cancel::before {
  display: block;
  position: absolute;
  opacity: 1;
  top: 0.9375rem;
  left: 0;
  height: 1rem;
  width: 0.0625rem;
  content: '';
  background-color: #ffffff;
  border: none;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--btn--primary.bx--batch-summary__cancel:hover::before {
  opacity: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--batch-summary__cancel {
  padding-right: 1rem;
  position: relative; }

.bx--batch-summary {
  position: absolute;
  left: 0;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  color: #ffffff; }

.bx--batch-summary__para {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px; }

.bx--table-toolbar--small {
  height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent {
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable
.bx--search
.bx--search-input,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent
.bx--search
.bx--search-input {
    height: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable
.bx--search
.bx--search-close,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent
.bx--search
.bx--search-close {
    height: 2rem;
    width: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable
.bx--search
.bx--search-magnifier,
  .bx--table-toolbar--small .bx--toolbar-search-container-persistent
.bx--search
.bx--search-magnifier {
    height: 2rem;
    width: 2rem;
    padding: 0.5rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable {
    width: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-expandable
.bx--search
.bx--search-input {
    padding-left: 2rem; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active {
    flex: auto;
    -webkit-transition: flex 175ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: flex 175ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-input {
    visibility: visible; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-input:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    background: #e5e5e5; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-input:active,
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-input:not(:placeholder-shown) {
    background: #e5e5e5; }
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-magnifier:focus,
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-magnifier:active,
  .bx--table-toolbar--small .bx--toolbar-search-container-active
.bx--search
.bx--search-magnifier:hover {
    outline: 2px solid transparent;
    outline-offset: -2px;
    background: transparent; }

.bx--table-toolbar--small
.bx--batch-actions
.bx--action-list {
  height: 2rem; }

.bx--table-toolbar--small .bx--toolbar-action {
  height: 2rem;
  width: 2rem;
  padding: 0.5rem; }

.bx--table-toolbar--small .bx--btn--primary {
  padding-top: 0.1875rem;
  height: 2rem; }

.bx--table-toolbar--small
.bx--btn--primary.bx--batch-summary__cancel::before {
  top: 0.5rem; }

.bx--table-toolbar--small
.bx--toolbar-action
~ .bx--btn {
  height: 2rem;
  width: 10rem;
  overflow: hidden; }

.bx--data-table-container {
  min-width: 31.25rem;
  overflow-x: auto;
  padding-top: 0.125rem; }

.bx--data-table-header {
  background: #f4f4f4;
  padding: 1rem 0 1.5rem 1rem; }

.bx--data-table-header__title {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
  letter-spacing: 0;
  color: #161616; }

.bx--data-table-header__description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #393939; }

.bx--data-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%; }

.bx--data-table thead {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  background-color: #e0e0e0; }

.bx--data-table tbody {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  background-color: #f4f4f4;
  width: 100%; }

.bx--data-table tr {
  border: none;
  height: 3rem;
  width: 100%; }

.bx--data-table tbody tr,
.bx--data-table tbody tr td,
.bx--data-table tbody tr th {
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--data-table tbody tr:hover {
  background: #e5e5e5; }

.bx--data-table tbody tr:hover td,
.bx--data-table tbody tr:hover th {
  color: #161616;
  background: #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5; }

.bx--data-table th,
.bx--data-table td {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  vertical-align: top;
  text-align: left; }

.bx--data-table th {
  color: #161616;
  background-color: #e0e0e0; }

.bx--data-table th:first-of-type:not(.bx--table-expand) {
  padding-left: 1rem; }

.bx--data-table th:last-of-type {
  position: relative;
  width: auto; }

.bx--data-table .bx--table-header-label {
  display: block;
  padding: 0.875rem 0;
  text-align: left; }

.bx--data-table td,
.bx--data-table tbody th {
  background: #f4f4f4;
  color: #393939;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #e0e0e0;
  padding: 0.875rem 0.75rem;
  padding-bottom: 0.8125rem; }
  .bx--data-table td + td:first-of-type,
  .bx--data-table tbody th + td:first-of-type {
    padding-left: 0.75rem; }

@supports (-moz-appearance: none) {
  .bx--data-table td {
    background-clip: padding-box; } }

.bx--data-table td:first-of-type {
  padding-left: 1rem; }

.bx--data-table td:last-of-type {
  padding-right: 1rem; }

.bx--data-table .bx--table-column-menu,
.bx--data-table .bx--table-column-menu:last-of-type {
  width: 3.25rem;
  min-width: 3.25rem;
  padding-top: 0.5rem;
  padding-right: 0.5rem; }

.bx--data-table td.bx--table-column-menu {
  padding-bottom: 0; }

.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu[aria-expanded='false']:hover {
  background: #e0e0e0; }

.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu[aria-expanded='false']:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu[aria-expanded='true']:focus {
  outline: none; }

@media screen and (hover: hover), (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .bx--data-table
td.bx--table-column-menu
.bx--overflow-menu
.bx--overflow-menu__icon {
    opacity: 0; } }

.bx--data-table.bx--data-table--visible-overflow-menu
td.bx--table-column-menu
.bx--overflow-menu
.bx--overflow-menu__icon,
.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu:hover
.bx--overflow-menu__icon,
.bx--data-table
td.bx--table-column-menu
.bx--overflow-menu:focus
.bx--overflow-menu__icon,
.bx--data-table
tr:hover
td.bx--table-column-menu
.bx--overflow-menu
.bx--overflow-menu__icon {
  opacity: 1; }

.bx--table-row--menu-option
.bx--overflow-menu-options__btn
.bx--overflow-menu-options__option-content
svg {
  margin-right: 0.5rem;
  position: relative;
  top: 0.1875rem; }

.bx--data-table--selected .bx--overflow-menu:hover,
.bx--data-table--selected .bx--overflow-menu__trigger:hover {
  background-color: #e5e5e5; }

.bx--data-table--zebra tbody tr:nth-child(even) td {
  border-bottom: 1px solid #f4f4f4; }

.bx--data-table--zebra tbody tr:nth-child(odd) td {
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff; }

.bx--data-table--zebra tbody tr:hover td {
  background-color: #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5; }

.bx--table-column-checkbox .bx--checkbox-label {
  padding-left: 1rem; }

.bx--data-table th.bx--table-column-checkbox {
  position: relative;
  background: #e0e0e0;
  padding: 0.75rem 0.5rem 0 1rem;
  width: 2.75rem;
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--data-table td.bx--table-column-checkbox {
  padding-top: 0.6875rem;
  padding-bottom: 0; }

th.bx--table-column-checkbox:hover {
  background: #cacaca; }

.bx--data-table--zebra
tbody
tr:nth-child(odd).bx--data-table--selected
td,
tr.bx--data-table--selected td {
  color: #161616;
  background-color: #e0e0e0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #c6c6c6; }

.bx--data-table--zebra
tbody
tr:first-of-type:nth-child(odd).bx--data-table--selected
td,
tr.bx--data-table--selected:first-of-type td {
  border-top: 1px solid #c6c6c6; }

.bx--data-table--zebra
tbody
tr:last-of-type:nth-child(odd).bx--data-table--selected
td,
.bx--data-table--zebra
tbody
tr:last-of-type:nth-child(even).bx--data-table--selected
td,
tr.bx--data-table--selected:last-of-type td {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0; }

.bx--data-table--zebra
tbody
tr:nth-child(even).bx--data-table--selected
td {
  border-bottom: 1px solid #c6c6c6; }

.bx--data-table--zebra
tbody
tr:nth-child(even).bx--data-table--selected:hover
td {
  border-bottom: 1px solid #cacaca; }

.bx--data-table--zebra
tbody
tr:nth-child(odd).bx--data-table--selected:hover
td,
.bx--data-table tbody .bx--data-table--selected:hover td {
  color: #161616;
  background: #cacaca;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca; }

.bx--data-table--selected
.bx--overflow-menu
.bx--overflow-menu__icon {
  opacity: 1; }

.bx--data-table--compact thead tr,
.bx--data-table--compact tbody tr {
  height: 1.5rem; }

.bx--data-table--compact .bx--table-header-label {
  padding: 0.125rem 0; }

.bx--data-table--compact td {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.bx--data-table--compact .bx--overflow-menu {
  width: 2rem;
  height: 1.4375rem; }

.bx--data-table.bx--data-table--compact
.bx--table-column-menu {
  padding-top: 0; }

.bx--data-table.bx--data-table--compact
.bx--table-column-checkbox {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table.bx--data-table--compact
.bx--table-column-checkbox
.bx--checkbox-label {
  min-height: 1.4375rem;
  height: 1.4375rem; }

.bx--data-table--short thead tr,
.bx--data-table--short tbody tr {
  height: 2rem; }

.bx--data-table--short .bx--table-header-label {
  padding: 0.4375rem 0; }

.bx--data-table--short td {
  padding-top: 0.4375rem;
  padding-bottom: 0.375rem; }

.bx--data-table--short .bx--overflow-menu {
  height: 1.9375rem; }

.bx--data-table.bx--data-table--short
.bx--table-column-menu {
  padding-top: 0; }

.bx--data-table.bx--data-table--short
.bx--table-column-checkbox {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.bx--data-table--tall thead tr,
.bx--data-table--tall tbody tr {
  height: 4rem; }

.bx--data-table--tall .bx--table-header-label {
  padding: 1rem 0; }

.bx--data-table--tall td {
  padding-top: 1rem; }

.bx--data-table--cell-secondary-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px; }

.bx--data-table.bx--data-table--tall
.bx--table-column-menu,
.bx--data-table.bx--data-table--tall
.bx--table-column-checkbox {
  padding-top: 0.75rem; }

.bx--data-table--static {
  width: auto; }

.bx--data-table_inner-container {
  background-color: #e0e0e0;
  padding-top: 3rem;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.bx--data-table--sticky-header {
  display: block;
  overflow-y: scroll; }
  .bx--data-table--sticky-header thead,
  .bx--data-table--sticky-header tbody,
  .bx--data-table--sticky-header tr,
  .bx--data-table--sticky-header th,
  .bx--data-table--sticky-header td {
    display: flex; }
  .bx--data-table--sticky-header thead {
    position: fixed;
    top: 0;
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    will-change: transform; }
  .bx--data-table--sticky-header thead tr th {
    border-bottom: 1px solid #c6c6c6; }
  .bx--data-table--sticky-header tbody {
    flex-direction: column;
    overflow-x: scroll;
    -ms-overflow-style: none;
    will-change: transform; }
  .bx--data-table--sticky-header tr {
    min-height: 3rem;
    height: auto; }
  .bx--data-table--sticky-header tr.bx--data-table--selected:first-of-type td {
    border-top: none; }
  .bx--data-table--sticky-header thead::-webkit-scrollbar,
  .bx--data-table--sticky-header tbody::-webkit-scrollbar {
    display: none; }

@-moz-document url-prefix() {
  .bx--data-table--sticky-header thead,
  .bx--data-table--sticky-header tbody {
    scrollbar-width: none; } }
  .bx--data-table--sticky-header tbody tr:last-of-type {
    border-bottom: 0; }
  .bx--data-table--sticky-header th:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon),
  .bx--data-table--sticky-header td:not(.bx--table-column-checkbox):not(.bx--table-column-menu):not(.bx--table-expand-v2):not(.bx--table-column-icon) {
    width: 100%;
    min-width: 0; }
  .bx--data-table--sticky-header .bx--table-header-label {
    max-width: calc(100% - 10px);
    display: block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.bx--data-table--max-width {
  max-width: 56.25rem; }

.bx--data-table--sticky-header {
  max-height: 18.75rem; }

.bx--data-table tr.bx--parent-row:first-of-type td {
  border-top: 1px solid #e0e0e0; }

.bx--expandable-row--hidden td {
  border-top: 0;
  width: auto;
  padding: 1rem; }

tr.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row] {
  height: 0;
  -webkit-transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row]
td {
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  background-color: #e5e5e5;
  -webkit-transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row]
td
.bx--child-row-inner-container {
  overflow: hidden;
  max-height: 0; }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] {
  -webkit-transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td {
  padding-bottom: 1.4375rem;
  -webkit-transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: padding 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row
+ tr[data-child-row]
td
.bx--child-row-inner-container {
  max-height: 100%; }

.bx--parent-row.bx--expandable-row > td,
.bx--parent-row.bx--expandable-row + tr[data-child-row] > td {
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #e0e0e0; }

.bx--parent-row:not(.bx--expandable-row)
+ tr[data-child-row]
> td {
  box-shadow: none; }

.bx--parent-row.bx--expandable-row > td:first-of-type {
  box-shadow: none; }

tr.bx--parent-row:not(.bx--expandable-row) td,
tr.bx--parent-row.bx--expandable-row td,
tr.bx--parent-row.bx--expandable-row {
  -webkit-transition: height 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: height 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row.bx--expandable-row + tr[data-child-row] td {
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

tr.bx--parent-row:not(.bx--expandable-row):first-of-type:hover
td {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5; }

tr.bx--parent-row.bx--expandable-row:hover td {
  background-color: #e5e5e5;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e0e0e0;
  color: #161616; }

tr.bx--parent-row.bx--expandable-row:hover td:first-of-type {
  border-bottom: 1px solid #e5e5e5; }

tr.bx--parent-row.bx--expandable-row:hover
+ tr[data-child-row]
td {
  background-color: #e5e5e5;
  color: #161616;
  border-bottom: 1px solid #e0e0e0; }

tr.bx--expandable-row--hover + tr[data-child-row] td {
  border-bottom: 1px solid #e0e0e0; }

tr.bx--expandable-row--hover {
  background-color: #e5e5e5; }

tr.bx--expandable-row--hover td {
  background-color: #e5e5e5;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e5e5e5;
  color: #161616; }

tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover
td:first-of-type {
  border-bottom: 1px solid #e5e5e5; }

.bx--data-table td.bx--table-expand {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 3rem;
  vertical-align: top;
  padding: 0; }

.bx--table-expand[data-previous-value='collapsed']
.bx--table-expand__svg {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--table-expand__button {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%; }
  .bx--table-expand__button::-moz-focus-inner {
    border: 0; }

.bx--data-table--short .bx--table-expand__button {
  height: auto; }

.bx--table-expand__button:focus {
  outline: 1px solid transparent; }

.bx--table-expand__button:focus .bx--table-expand__svg {
  box-shadow: inset 0px 0px 0px 1px #0f62fe; }

.bx--table-expand__svg {
  fill: #161616;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

th.bx--table-expand {
  position: relative;
  vertical-align: middle; }

th.bx--table-expand + th.bx--table-column-checkbox {
  padding-left: 0.5rem; }

tr.bx--parent-row.bx--expandable-row
td.bx--table-expand
+ td::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -0.0625rem;
  height: 0.0625rem;
  width: 0.5rem;
  background: #f4f4f4; }

tr.bx--parent-row.bx--expandable-row:hover
td.bx--table-expand
+ td::after,
tr.bx--parent-row.bx--expandable-row.bx--expandable-row--hover
td.bx--table-expand
+ td::after {
  background: #e5e5e5; }

tr.bx--parent-row.bx--data-table--selected
td.bx--table-expand
+ td::after {
  display: none; }

tr.bx--parent-row.bx--data-table--selected:first-of-type td {
  background: #e0e0e0;
  border-top: 1px solid #c6c6c6;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #c6c6c6; }

tr.bx--parent-row.bx--data-table--selected td {
  background: #e0e0e0;
  color: #161616;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #c6c6c6; }

tr.bx--parent-row.bx--data-table--selected:last-of-type td {
  background: #e0e0e0;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #e0e0e0; }

tr.bx--parent-row.bx--data-table--selected:not(.bx--expandable-row):hover
td {
  background: #cacaca;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #cacaca; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
td:first-of-type {
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #e0e0e0; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover
td:first-of-type,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover
td:first-of-type {
  background: #cacaca;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #cacaca; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
+ tr[data-child-row]
td {
  color: #161616;
  background-color: #e5e5e5;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px #c6c6c6;
  border-top: 1px solid #c6c6c6; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row
+ tr[data-child-row]:last-of-type
td {
  box-shadow: inset 0 -1px #c6c6c6;
  padding-bottom: 1.5rem; }

tr.bx--parent-row.bx--data-table--selected.bx--expandable-row:hover
+ tr[data-child-row]
td,
tr.bx--parent-row.bx--data-table--selected.bx--expandable-row--hover
+ tr[data-child-row]
td {
  background: #e0e0e0; }

.bx--data-table--sort th,
.bx--data-table--sort
th:first-of-type:not(.bx--table-column-checkbox):not(.bx--table-expand) {
  padding: 0; }

.bx--data-table--sort th {
  height: 3rem;
  border-top: none;
  border-bottom: none; }

.bx--data-table--sort td {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.bx--data-table--sort th:first-of-type .bx--table-sort {
  padding-left: 0.75rem; }

.bx--table-sort {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  font: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #161616;
  padding: 0 0.5rem;
  height: 100%;
  background-color: #e0e0e0;
  -webkit-transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0, 0, 0.38, 0.9), outline 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--table-sort::-moz-focus-inner {
    border: 0; }

.bx--table-sort:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--table-sort:hover {
  background: #cacaca; }

.bx--table-sort:focus svg,
.bx--table-sort:hover svg {
  opacity: 1; }

.bx--table-sort .bx--table-sort__icon-inactive {
  display: block; }

.bx--table-sort .bx--table-sort__icon {
  display: none; }

.bx--table-sort__icon-unsorted {
  position: relative;
  left: 0.125rem;
  margin-left: 0.5rem;
  margin-right: 0;
  opacity: 0;
  fill: #161616;
  width: auto;
  min-width: 1rem; }

.bx--table-sort.bx--table-sort--active
.bx--table-sort__icon-unsorted {
  display: none; }

.bx--table-sort.bx--table-sort--active
.bx--table-sort__icon {
  display: block;
  opacity: 1; }

.bx--table-sort--ascending .bx--table-sort__icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--table-sort__icon {
  position: relative;
  left: 0.125rem;
  margin-left: 0.5rem;
  margin-right: 0;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-transform: rotate(0);
          transform: rotate(0);
  opacity: 0;
  fill: #161616;
  width: auto;
  min-width: 1rem; }

.bx--data-table--compact.bx--data-table--sort th {
  height: 1.5rem; }

.bx--data-table--compact.bx--data-table--sort
th
.bx--table-sort {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table--short.bx--data-table--sort th {
  height: 2rem; }

.bx--data-table--short.bx--data-table--sort
th
.bx--table-sort {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table--tall.bx--data-table--sort th {
  height: 4rem; }

.bx--data-table--tall.bx--data-table--sort
th
.bx--table-sort {
  padding-top: 0;
  padding-bottom: 0; }

.bx--data-table--tall.bx--data-table--sort
th
.bx--table-sort
svg {
  align-self: flex-start;
  top: 0.5rem; }

.bx--data-table--tall.bx--data-table--sort
th
.bx--table-sort
.bx--table-header-label {
  align-self: flex-start;
  position: relative;
  top: -0.5rem;
  max-height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.bx--inline-edit-label {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .bx--inline-edit-label:hover .bx--inline-edit-label__icon {
    opacity: 1; }

.bx--inline-edit-label--inactive {
  display: none; }

.bx--inline-edit-label__action {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer; }
  .bx--inline-edit-label__action::-moz-focus-inner {
    border: 0; }
  .bx--inline-edit-label__action:hover {
    cursor: pointer; }
  .bx--inline-edit-label__action:focus {
    outline: 1px solid #0f62fe;
    padding: 0.125rem; }
    .bx--inline-edit-label__action:focus .bx--inline-edit-label__icon {
      width: auto;
      opacity: 1; }

.bx--inline-edit-label__icon {
  fill: #161616;
  opacity: 0; }

.bx--inline-edit-input {
  display: none; }

.bx--inline-edit-input--active {
  display: block;
  margin-left: -0.75rem; }
  .bx--inline-edit-input--active input {
    padding-left: 0.75rem; }

.bx--data-table--short input {
  height: 2rem; }

.bx--data-table--short select {
  padding: 0.45rem 2.75rem 0.45rem 1rem; }

.bx--data-table--short .bx--select__arrow {
  top: 0.875rem; }

.bx--data-table.bx--skeleton th {
  border-bottom: 1px solid #0f62fe;
  vertical-align: middle; }
  .bx--data-table.bx--skeleton th:nth-child(3n + 1) {
    width: 10%; }
  .bx--data-table.bx--skeleton th:nth-child(3n + 2) {
    width: 30%; }
  .bx--data-table.bx--skeleton th:nth-child(3n + 3) {
    width: 15%; }

.bx--data-table.bx--skeleton th span,
.bx--data-table.bx--skeleton td span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 75%;
  height: 1rem;
  display: block; }
  .bx--data-table.bx--skeleton th span:hover, .bx--data-table.bx--skeleton th span:focus, .bx--data-table.bx--skeleton th span:active,
  .bx--data-table.bx--skeleton td span:hover,
  .bx--data-table.bx--skeleton td span:focus,
  .bx--data-table.bx--skeleton td span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--data-table.bx--skeleton th span:before,
  .bx--data-table.bx--skeleton td span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--data-table.bx--skeleton th span:before,
      .bx--data-table.bx--skeleton td span:before {
        -webkit-animation: none;
                animation: none; } }

.bx--data-table.bx--skeleton tr:hover td {
  border-color: #e0e0e0;
  background: transparent; }
  .bx--data-table.bx--skeleton tr:hover td:first-of-type, .bx--data-table.bx--skeleton tr:hover td:last-of-type {
    border-color: #e0e0e0; }

.bx--data-table-v2.bx--skeleton .bx--table-sort-v2 {
  pointer-events: none; }

.bx--structured-list--selection .bx--structured-list-td,
.bx--structured-list--selection .bx--structured-list-th {
  padding-left: 1rem;
  padding-right: 1rem; }
  .bx--structured-list--selection .bx--structured-list-td:first-child,
  .bx--structured-list--selection .bx--structured-list-th:first-child {
    padding-left: 1rem;
    padding-right: 1rem; }

.bx--structured-list-input {
  display: none; }

.bx--structured-list {
  display: table;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 5rem;
  background-color: transparent; }
  .bx--structured-list.bx--structured-list--condensed .bx--structured-list-td,
  .bx--structured-list.bx--structured-list--condensed .bx--structured-list-th {
    padding: 0.5rem;
    padding-left: 0; }

.bx--structured-list-row {
  display: table-row;
  border-bottom: 1px solid #e0e0e0;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--structured-list--selection
.bx--structured-list-row:hover:not(.bx--structured-list-row--header-row):not(.bx--structured-list-row--selected) {
  background-color: #e5e5e5;
  cursor: pointer;
  border-bottom: 1px solid #e5e5e5; }

.bx--structured-list-row.bx--structured-list-row--selected {
  background-color: #e0e0e0; }

.bx--structured-list-row.bx--structured-list-row--header-row {
  border-bottom: 1px solid #e0e0e0;
  cursor: inherit; }

.bx--structured-list-row:focus:not(.bx--structured-list-row--header-row) {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--structured-list--selection
.bx--structured-list-row:hover:not(.bx--structured-list-row--header-row)
> .bx--structured-list-td,
.bx--structured-list-row.bx--structured-list-row--selected
> .bx--structured-list-td {
  color: #161616; }

.bx--structured-list--selection
.bx--structured-list-row:hover:not(.bx--structured-list-row--header-row)
> .bx--structured-list-td {
  border-top: 1px solid #f4f4f4; }

.bx--structured-list-thead {
  display: table-header-group;
  vertical-align: middle; }

.bx--structured-list-th {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: table-cell;
  font-weight: 600;
  height: 2.5rem;
  text-align: left;
  text-transform: none;
  vertical-align: bottom; }

.bx--structured-list-tbody {
  display: table-row-group;
  vertical-align: middle; }

.bx--structured-list-td {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  line-height: 1.3125rem;
  position: relative;
  display: table-cell;
  max-width: 36rem;
  -webkit-transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--structured-list-th {
  color: #161616; }

.bx--structured-list-td {
  color: #393939; }

.bx--structured-list-content--nowrap {
  white-space: nowrap; }

.bx--structured-list-svg {
  display: inline-block;
  fill: transparent;
  vertical-align: middle;
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--structured-list-row:hover .bx--structured-list-svg {
  fill: #a8a8a8; }

.bx--structured-list-input:checked
+ .bx--structured-list-row
.bx--structured-list-svg,
.bx--structured-list-input:checked
+ .bx--structured-list-td
.bx--structured-list-svg {
  fill: #161616; }

.bx--structured-list.bx--skeleton .bx--structured-list-th:first-child {
  width: 8%; }

.bx--structured-list.bx--skeleton .bx--structured-list-th:nth-child(3n + 2) {
  width: 30%; }

.bx--structured-list.bx--skeleton .bx--structured-list-th:nth-child(3n + 3) {
  width: 15%; }

.bx--structured-list.bx--skeleton .bx--structured-list-th span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 75%;
  height: 1rem;
  display: block; }
  .bx--structured-list.bx--skeleton .bx--structured-list-th span:hover, .bx--structured-list.bx--skeleton .bx--structured-list-th span:focus, .bx--structured-list.bx--skeleton .bx--structured-list-th span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--structured-list.bx--skeleton .bx--structured-list-th span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--structured-list.bx--skeleton .bx--structured-list-th span:before {
        -webkit-animation: none;
                animation: none; } }

.bx--structured-list.bx--skeleton
.bx--structured-list-th
span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 75%;
  height: 1rem;
  display: block; }
  .bx--structured-list.bx--skeleton
.bx--structured-list-th
span:hover, .bx--structured-list.bx--skeleton
.bx--structured-list-th
span:focus, .bx--structured-list.bx--skeleton
.bx--structured-list-th
span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--structured-list.bx--skeleton
.bx--structured-list-th
span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--structured-list.bx--skeleton
.bx--structured-list-th
span:before {
        -webkit-animation: none;
                animation: none; } }

.bx--structured-list.bx--structured-list--selection.bx--skeleton
.bx--structured-list-th:first-child {
  width: 5%; }
  .bx--structured-list.bx--structured-list--selection.bx--skeleton
.bx--structured-list-th:first-child span {
    display: none; }

.bx--snippet code {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px; }

.bx--snippet--inline {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  position: relative;
  display: inline;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #f4f4f4;
  color: #161616;
  cursor: pointer; }
  .bx--snippet--inline:hover {
    background-color: #e0e0e0; }
  .bx--snippet--inline:active {
    background-color: #c6c6c6; }
  .bx--snippet--inline:focus {
    outline: none;
    border: 2px solid #0f62fe; }

.bx--snippet--inline code {
  padding: 0 0.5rem; }

.bx--snippet--inline.bx--snippet--light {
  background-color: #ffffff; }
  .bx--snippet--inline.bx--snippet--light:hover {
    background-color: rgba(57, 57, 57, 0.1); }

.bx--snippet--single {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  background: #f4f4f4;
  border: 1px solid #e0e0e0;
  position: relative;
  max-width: 37.5rem;
  width: 100%;
  border: none;
  max-width: 47.5rem;
  min-width: 20rem;
  height: 2.5rem;
  padding: 0 56px 0 1rem; }

.bx--snippet--single .bx--snippet-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  position: relative;
  padding: 0 0 1rem 0;
  height: 3.5rem; }

.bx--snippet--single pre {
  white-space: nowrap;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  padding-right: 0.5rem; }

.bx--snippet--single::after {
  width: 1rem;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  right: 3.5rem;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(#f4f4f4));
  background-image: linear-gradient(to right, transparent, #f4f4f4); }

.bx--snippet--multi {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  background: #f4f4f4;
  border: 1px solid #e0e0e0;
  position: relative;
  max-width: 37.5rem;
  width: 100%;
  border: none;
  padding: 1rem;
  min-width: 20rem;
  max-width: 100%; }

.bx--snippet--multi .bx--snippet-container {
  overflow: hidden;
  position: relative;
  max-height: 14.875rem;
  min-height: 3.5rem;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--snippet--multi.bx--snippet--expand
.bx--snippet-container {
  max-height: 93.75rem;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--snippet--multi .bx--snippet-container pre {
  overflow: hidden;
  padding-bottom: 1.5rem; }

.bx--snippet--multi.bx--snippet--expand
.bx--snippet-container
pre {
  overflow-x: auto; }

.bx--snippet--multi .bx--snippet-container pre::after {
  width: 1rem;
  height: 100%;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(#f4f4f4));
  background-image: linear-gradient(to right, transparent, #f4f4f4); }

.bx--snippet--multi .bx--snippet-container pre code {
  overflow: hidden; }

.bx--snippet__icon {
  fill: #161616;
  height: 1rem;
  width: 1rem;
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--snippet-button {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background-color: #f4f4f4;
  outline: none;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;
  overflow: visible; }
  .bx--snippet-button:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    outline-color: #0f62fe; }

.bx--snippet--multi .bx--snippet-button {
  height: 2rem;
  width: 2rem;
  top: 0.5rem;
  right: 0.5rem; }

.bx--snippet-button:hover {
  background: #e5e5e5; }

.bx--snippet-button:active {
  background-color: #c6c6c6; }

.bx--btn--copy__feedback {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  z-index: 8000;
  font-weight: 400;
  left: inherit;
  top: 0.75rem;
  right: 1.25rem; }

.bx--btn--copy__feedback:before,
.bx--btn--copy__feedback:after {
  background: #393939; }

.bx--btn--copy__feedback:after {
  border: none; }

button.bx--btn.bx--snippet-btn--expand {
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  padding: 0.5rem;
  padding-left: 1rem;
  color: #161616;
  background-color: #f4f4f4; }

button.bx--btn.bx--snippet-btn--expand
.bx--snippet-btn--text {
  position: relative;
  top: -0.0625rem; }

.bx--snippet-btn--expand--hide.bx--snippet-btn--expand {
  display: none; }

.bx--snippet-btn--expand .bx--icon-chevron--down {
  fill: #161616;
  margin-left: 0.5rem;
  margin-bottom: 0.0625rem;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

button.bx--btn.bx--snippet-btn--expand:hover {
  background: #e5e5e5;
  color: #161616; }

.bx--snippet-btn--expand:active {
  background-color: #c6c6c6; }

.bx--snippet-btn--expand:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--snippet--expand
.bx--snippet-btn--expand
.bx--icon-chevron--down {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-transition: -webkit-transform 240ms;
  transition: -webkit-transform 240ms;
  transition: transform 240ms;
  transition: transform 240ms, -webkit-transform 240ms; }

.bx--snippet--code.bx--skeleton {
  height: 6.125rem; }

.bx--snippet--terminal.bx--skeleton {
  height: 3.5rem; }

.bx--snippet.bx--skeleton .bx--snippet-container {
  height: 100%; }

.bx--snippet.bx--skeleton code {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%;
  height: 1rem;
  display: block; }
  .bx--snippet.bx--skeleton code:hover, .bx--snippet.bx--skeleton code:focus, .bx--snippet.bx--skeleton code:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--snippet.bx--skeleton code:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--snippet.bx--skeleton code:before {
        -webkit-animation: none;
                animation: none; } }

.bx--snippet-button .bx--btn--copy__feedback {
  top: 1.5625rem;
  left: 1rem;
  right: auto; }

.bx--snippet--inline .bx--btn--copy__feedback {
  right: auto;
  left: 50%; }

bx--snippet--multi.bx--skeleton {
  height: 6.125rem; }

.bx--snippet--single.bx--skeleton {
  height: 3.5rem; }

.bx--snippet.bx--skeleton .bx--snippet-container {
  height: 100%; }

.bx--snippet.bx--skeleton span {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%;
  height: 1rem;
  display: block;
  margin-top: 0.5rem; }
  .bx--snippet.bx--skeleton span:hover, .bx--snippet.bx--skeleton span:focus, .bx--snippet.bx--skeleton span:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--snippet.bx--skeleton span:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--snippet.bx--skeleton span:before {
        -webkit-animation: none;
                animation: none; } }
  .bx--snippet.bx--skeleton span:first-child {
    margin: 0; }
  .bx--snippet.bx--skeleton span:nth-child(2) {
    width: 85%; }
  .bx--snippet.bx--skeleton span:nth-child(3) {
    width: 95%; }

.bx--snippet--single.bx--skeleton
.bx--snippet-container {
  padding-bottom: 0; }

.bx--snippet--inline .bx--btn--copy__feedback {
  right: auto; }

.bx--snippet--code.bx--skeleton {
  height: 6.125rem; }

.bx--snippet--terminal.bx--skeleton {
  height: 3.5rem; }

.bx--snippet.bx--skeleton .bx--snippet-container {
  height: 100%; }

.bx--snippet.bx--skeleton code {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%;
  height: 1rem;
  display: block; }
  .bx--snippet.bx--skeleton code:hover, .bx--snippet.bx--skeleton code:focus, .bx--snippet.bx--skeleton code:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--snippet.bx--skeleton code:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--snippet.bx--skeleton code:before {
        -webkit-animation: none;
                animation: none; } }

.bx--overflow-menu__trigger {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%; }
  .bx--overflow-menu__trigger::-moz-focus-inner {
    border: 0; }

.bx--overflow-menu,
.bx--overflow-menu__trigger {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu:focus,
  .bx--overflow-menu__trigger:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--overflow-menu:hover,
  .bx--overflow-menu__trigger:hover {
    background-color: #cacaca; }

.bx--overflow-menu__trigger.bx--tooltip--a11y.bx--tooltip__trigger:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }
  .bx--overflow-menu__trigger.bx--tooltip--a11y.bx--tooltip__trigger:focus svg {
    outline: none; }

.bx--overflow-menu.bx--overflow-menu--open,
.bx--overflow-menu.bx--overflow-menu--open
.bx--overflow-menu__trigger {
  background-color: #f4f4f4;
  -webkit-transition: none;
  transition: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3); }

.bx--overflow-menu__icon {
  height: 1rem;
  width: 1rem;
  fill: #161616; }

.bx--overflow-menu-options {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  display: none;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  z-index: 10000;
  background-color: #f4f4f4;
  width: 10rem;
  list-style: none;
  top: 32px;
  left: 0; }
  .bx--overflow-menu-options::after {
    content: '';
    position: absolute;
    display: block;
    background-color: #f4f4f4;
    -webkit-transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
    transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
  top: -0.1875rem;
  left: 0;
  width: 2rem;
  height: 0.1875rem; }

.bx--overflow-menu-options[data-floating-menu-direction='top']::after {
  bottom: -0.375rem;
  left: 0;
  width: 2rem;
  height: 0.375rem; }

.bx--overflow-menu-options[data-floating-menu-direction='left']::after {
  right: -0.375rem;
  top: 0;
  height: 2rem;
  width: 0.375rem; }

.bx--overflow-menu-options[data-floating-menu-direction='right']::after {
  top: 0;
  left: -0.375rem;
  height: 2rem;
  width: 0.375rem; }

.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='top']::after,
.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='bottom']::after {
  left: auto;
  right: 0; }

.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='left']::after,
.bx--overflow-menu--flip.bx--overflow-menu-options[data-floating-menu-direction='right']::after {
  top: auto;
  bottom: 0; }

.bx--overflow-menu-options--open {
  display: flex; }

.bx--overflow-menu-options__content {
  width: 100%; }

.bx--overflow-menu-options__option {
  display: flex;
  background-color: transparent;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  padding: 0;
  -webkit-transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--overflow-menu--divider {
  border-top: 1px solid #e0e0e0; }

a.bx--overflow-menu-options__btn::before {
  content: '';
  height: 100%;
  vertical-align: middle;
  display: inline-block; }

.bx--overflow-menu-options__btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  border: none;
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  text-align: left;
  padding: 0 1rem;
  cursor: pointer;
  color: #393939;
  max-width: 11.25rem;
  -webkit-transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9), color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0, 0, 0.38, 0.9), color 110ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--overflow-menu-options__btn:hover {
    color: #161616; }
  .bx--overflow-menu-options__btn:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--overflow-menu-options__btn::-moz-focus-inner {
    border: none; }

.bx--overflow-menu-options__btn svg {
  fill: #525252; }

.bx--overflow-menu-options__btn:hover svg {
  fill: #161616; }

.bx--overflow-menu-options__option-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.bx--overflow-menu-options__option:hover {
  background-color: #e5e5e5; }

.bx--overflow-menu-options__option--danger {
  border-top: 1px solid #e0e0e0; }

.bx--overflow-menu-options__option--danger
.bx--overflow-menu-options__btn:hover,
.bx--overflow-menu-options__option--danger
.bx--overflow-menu-options__btn:focus {
  color: #ffffff;
  background-color: #da1e28; }
  .bx--overflow-menu-options__option--danger
.bx--overflow-menu-options__btn:hover svg,
  .bx--overflow-menu-options__option--danger
.bx--overflow-menu-options__btn:focus svg {
    fill: #ffffff; }

.bx--overflow-menu-options__option--disabled:hover {
  background-color: #f4f4f4;
  cursor: not-allowed; }

.bx--overflow-menu-options__option--disabled
.bx--overflow-menu-options__btn {
  color: #c6c6c6;
  pointer-events: none; }
  .bx--overflow-menu-options__option--disabled
.bx--overflow-menu-options__btn:hover, .bx--overflow-menu-options__option--disabled
.bx--overflow-menu-options__btn:active, .bx--overflow-menu-options__option--disabled
.bx--overflow-menu-options__btn:focus {
    outline: 2px solid transparent;
    outline-offset: -2px;
    background-color: #f4f4f4; }

.bx--overflow-menu-options__option--disabled
.bx--overflow-menu-options__btn
svg {
  fill: #c6c6c6; }

.bx--overflow-menu--flip {
  left: -140px; }
  .bx--overflow-menu--flip:before {
    left: 145px; }

.bx--content-switcher {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 2.5rem; }

.bx--content-switcher--disabled {
  cursor: not-allowed; }

.bx--content-switcher-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #f4f4f4;
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  text-decoration: none;
  border: none;
  color: #393939;
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  position: relative; }
  .bx--content-switcher-btn:focus {
    box-shadow: inset 0 0 0 2px #0f62fe;
    z-index: 3; }
  .bx--content-switcher-btn:hover {
    cursor: pointer; }
  .bx--content-switcher-btn:hover, .bx--content-switcher-btn:active {
    background-color: #e5e5e5;
    color: #161616;
    z-index: 3; }
  .bx--content-switcher-btn:disabled {
    color: #c6c6c6;
    background-color: #f4f4f4;
    pointer-events: none; }
    .bx--content-switcher-btn:disabled:hover {
      cursor: not-allowed; }

.bx--content-switcher-btn:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.bx--content-switcher-btn:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.bx--content-switcher-btn:not(:first-of-type)::before {
  content: '';
  display: block;
  height: 1rem;
  width: 0.0625rem;
  background-color: #e0e0e0;
  position: absolute;
  z-index: 2;
  left: 0; }

.bx--content-switcher-btn.bx--content-switcher--selected::before,
.bx--content-switcher-btn.bx--content-switcher--selected
+ .bx--content-switcher-btn::before,
.bx--content-switcher-btn:focus::before,
.bx--content-switcher-btn:focus
+ .bx--content-switcher-btn::before,
.bx--content-switcher-btn:hover::before,
.bx--content-switcher-btn:hover
+ .bx--content-switcher-btn::before {
  background-color: transparent; }

.bx--content-switcher__icon {
  fill: #393939;
  -webkit-transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: fill 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--content-switcher__icon + span {
  margin-left: 0.5rem; }

.bx--content-switcher__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; }

.bx--content-switcher-btn:hover .bx--content-switcher__icon,
.bx--content-switcher-btn:focus .bx--content-switcher__icon {
  fill: #161616; }

.bx--content-switcher-btn.bx--content-switcher--selected {
  background-color: #161616;
  color: #ffffff;
  z-index: 3; }
  .bx--content-switcher-btn.bx--content-switcher--selected:disabled {
    background-color: #8d8d8d;
    color: #c6c6c6; }

.bx--content-switcher-btn.bx--content-switcher--selected
.bx--content-switcher__icon {
  fill: #ffffff; }

.flatpickr-calendar {
  background: transparent;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding: 0;
  -webkit-animation: none;
          animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 315px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  max-height: 640px; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9);
  animation: fpFadeInDown 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.hasWeeks {
  width: auto; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden; }

.flatpickr-prev-month,
.flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  line-height: 16px;
  height: 28px;
  padding: 10px calc(3.57% - 1.5px);
  z-index: 3; }

.flatpickr-prev-month i,
.flatpickr-next-month i {
  position: relative; }

.flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month.flatpickr-next-month,
.flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */ }

/*
        /*rtl:begin:ignore*/
/*
        /*rtl:end:ignore*/
.flatpickr-prev-month:hover,
.flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-prev-month svg,
.flatpickr-next-month svg {
  width: 14px; }

.flatpickr-prev-month svg path,
.flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.05);
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: '';
  position: absolute;
  top: 33%; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6); }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6); }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month.slideLeft {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideLeftNew {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideLeftNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideRight {
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px);
  -webkit-animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms ease, fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month.slideRightNew {
  -webkit-transform: translate3d(0, 0, 0px);
  transform: translate3d(0, 0, 0px);
  -webkit-animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms ease, fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: default;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 315px;
  display: flex;
  align-items: center;
  height: 28px; }

span.flatpickr-weekday {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  cursor: default;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  text-align: center;
  display: block;
  flex: 1 1; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 315px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 315px;
  min-width: 315px;
  max-width: 315px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.flatpickr-calendar.animate .dayContainer.slideLeft {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideLeft,
.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-transform: translate3d(-100%, 0px, 0px);
  transform: translate3d(-100%, 0px, 0px); }

.flatpickr-calendar.animate .dayContainer.slideLeftNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideLeft 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.animate .dayContainer.slideRight {
  -webkit-animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeOut 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRight 400ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: translate3d(100%, 0px, 0px);
  transform: translate3d(100%, 0px, 0px); }

.flatpickr-calendar.animate .dayContainer.slideRightNew {
  -webkit-animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeIn 400ms cubic-bezier(0.23, 1, 0.32, 1), fpSlideRightNew 400ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center; }

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-weekwrapper {
  display: inline-block;
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day {
  display: block;
  width: 100%;
  max-width: none; }

.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex; }

.flatpickr-time:after {
  content: '';
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  flex: 1 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  cursor: pointer;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-grid-row-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #f0f0f0; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px); } }

@keyframes fpSlideLeft {
  from {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); }
  to {
    -webkit-transform: translate3d(-100%, 0px, 0px);
    transform: translate3d(-100%, 0px, 0px); } }

@-webkit-keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@keyframes fpSlideLeftNew {
  from {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); }
  to {
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px); } }

@-webkit-keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); } }

@keyframes fpSlideRight {
  from {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); }
  to {
    -webkit-transform: translate3d(100%, 0px, 0px);
    transform: translate3d(100%, 0px, 0px); } }

@-webkit-keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px); }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); } }

@keyframes fpSlideRightNew {
  from {
    -webkit-transform: translate3d(-100%, 0, 0px);
    transform: translate3d(-100%, 0, 0px); }
  to {
    -webkit-transform: translate3d(0, 0, 0px);
    transform: translate3d(0, 0, 0px); } }

@-webkit-keyframes fpFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fpFadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fpFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fpFadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.bx--date-picker {
  display: flex; }

.bx--date-picker--light .bx--date-picker__input {
  background: #ffffff; }

.bx--date-picker ~ .bx--label {
  order: 1; }

.bx--date-picker-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.bx--date-picker-input__wrapper {
  display: flex;
  align-items: center;
  position: relative; }
  .bx--date-picker-input__wrapper ~ .bx--form-requirement {
    max-height: 12.5rem;
    display: block;
    color: #da1e28;
    font-weight: 400;
    margin-top: 0.25rem;
    overflow: visible; }
    .bx--date-picker-input__wrapper ~ .bx--form-requirement::before {
      display: none; }

.bx--date-picker.bx--date-picker--simple .bx--date-picker__input {
  width: 7.5rem; }

.bx--date-picker.bx--date-picker--simple.bx--date-picker--short .bx--date-picker__input {
  width: 5.7rem; }

.bx--date-picker.bx--date-picker--single .bx--date-picker-container {
  max-width: 18rem; }

.bx--date-picker.bx--date-picker--single .bx--date-picker__input {
  width: 18rem; }

.bx--date-picker__input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  position: relative;
  height: 2.5rem;
  max-width: 18rem;
  padding: 0 1rem;
  background-color: #f4f4f4;
  border: none;
  color: #161616;
  border-bottom: 1px solid #8d8d8d;
  -webkit-transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all;
  transition: 70ms cubic-bezier(0.2, 0, 0.38, 0.9) all; }
  .bx--date-picker__input:focus, .bx--date-picker__input.bx--focused {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--date-picker__input[data-invalid], .bx--date-picker__input[data-invalid]:focus {
    outline: 2px solid #da1e28;
    outline-offset: -2px; }
  .bx--date-picker__input:disabled {
    color: #c6c6c6;
    background-color: #f4f4f4;
    border-bottom: 1px solid transparent;
    cursor: not-allowed; }
  .bx--date-picker__input:disabled::-webkit-input-placeholder {
    color: #c6c6c6; }
  .bx--date-picker__input:disabled::-moz-placeholder {
    color: #c6c6c6; }
  .bx--date-picker__input:disabled:-ms-input-placeholder {
    color: #c6c6c6; }
  .bx--date-picker__input:disabled::-ms-input-placeholder {
    color: #c6c6c6; }
  .bx--date-picker__input:disabled::placeholder {
    color: #c6c6c6; }
  .bx--date-picker__input:disabled:hover {
    border-bottom: 1px solid transparent; }
  .bx--date-picker__input::-webkit-input-placeholder {
    color: #a8a8a8;
    opacity: 1; }
  .bx--date-picker__input::-moz-placeholder {
    color: #a8a8a8;
    opacity: 1; }
  .bx--date-picker__input:-ms-input-placeholder {
    color: #a8a8a8;
    opacity: 1; }
  .bx--date-picker__input::-ms-input-placeholder {
    color: #a8a8a8;
    opacity: 1; }
  .bx--date-picker__input::placeholder {
    color: #a8a8a8;
    opacity: 1; }

.bx--date-picker__icon {
  position: absolute;
  right: 1rem;
  fill: #161616;
  cursor: pointer;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.bx--date-picker__icon ~ .bx--date-picker__input {
  padding-right: 3rem; }

.bx--date-picker__input:disabled ~ .bx--date-picker__icon {
  fill: #c6c6c6;
  cursor: not-allowed; }

.bx--date-picker--range
> .bx--date-picker-container:first-child {
  margin-right: 0.0625rem; }

.bx--date-picker--range .bx--date-picker-container,
.bx--date-picker--range .bx--date-picker__input {
  width: 8.96875rem; }

.bx--date-picker__calendar,
.flatpickr-calendar.open {
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.1);
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.25rem 0.5rem 0.25rem;
  width: 18rem !important;
  height: 21rem;
  border-radius: 0;
  border: none;
  overflow: hidden;
  margin-top: -2px; }
  .bx--date-picker__calendar:before, .bx--date-picker__calendar:after,
  .flatpickr-calendar.open:before,
  .flatpickr-calendar.open:after {
    display: none; }
  .bx--date-picker__calendar:focus,
  .flatpickr-calendar.open:focus {
    outline: 1px solid #0f62fe; }

.bx--date-picker__month,
.flatpickr-month {
  display: flex;
  align-items: center;
  width: 100%;
  height: 2.5rem;
  margin-bottom: 0.25rem; }

.bx--date-picker__month .flatpickr-prev-month,
.bx--date-picker__month .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  fill: #161616;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--date-picker__month .flatpickr-prev-month:hover,
  .bx--date-picker__month .flatpickr-next-month:hover,
  .flatpickr-months .flatpickr-prev-month:hover,
  .flatpickr-months .flatpickr-next-month:hover {
    background-color: #e5e5e5; }

.flatpickr-current-month .numInputWrapper {
  width: 7ch; }

.bx--date-picker__month .flatpickr-current-month,
.flatpickr-month .flatpickr-current-month {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  padding: 0; }

.bx--date-picker__month .flatpickr-current-month svg,
.flatpickr-month .flatpickr-current-month svg {
  fill: #161616; }

.bx--date-picker__month .flatpickr-current-month .cur-month,
.flatpickr-month .flatpickr-current-month .cur-month {
  margin-right: 0.25rem;
  color: #161616; }

.bx--date-picker__month .numInputWrapper .numInput,
.flatpickr-month .numInputWrapper .numInput {
  font-weight: 600;
  color: #161616;
  background-color: #f4f4f4;
  border: none;
  border-radius: 0;
  padding: 0.25rem;
  -moz-appearance: textfield;
  /* Firefox */ }
  .bx--date-picker__month .numInputWrapper .numInput:focus,
  .flatpickr-month .numInputWrapper .numInput:focus {
    outline: 1px solid #0f62fe; }
  .bx--date-picker__month .numInputWrapper .numInput::-webkit-outer-spin-button, .bx--date-picker__month .numInputWrapper .numInput::-webkit-inner-spin-button,
  .flatpickr-month .numInputWrapper .numInput::-webkit-outer-spin-button,
  .flatpickr-month .numInputWrapper .numInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */ }

.bx--date-picker__month .numInputWrapper span.arrowUp,
.bx--date-picker__month .numInputWrapper span.arrowDown,
.flatpickr-month .numInputWrapper span.arrowUp,
.flatpickr-month .numInputWrapper span.arrowDown {
  left: 2.6rem;
  border: none;
  width: 0.75rem; }
  .bx--date-picker__month .numInputWrapper span.arrowUp:hover,
  .bx--date-picker__month .numInputWrapper span.arrowDown:hover,
  .flatpickr-month .numInputWrapper span.arrowUp:hover,
  .flatpickr-month .numInputWrapper span.arrowDown:hover {
    background: none; }
    .bx--date-picker__month .numInputWrapper span.arrowUp:hover:after,
    .bx--date-picker__month .numInputWrapper span.arrowDown:hover:after,
    .flatpickr-month .numInputWrapper span.arrowUp:hover:after,
    .flatpickr-month .numInputWrapper span.arrowDown:hover:after {
      border-bottom-color: #0f62fe;
      border-top-color: #0f62fe; }
  .bx--date-picker__month .numInputWrapper span.arrowUp:after,
  .bx--date-picker__month .numInputWrapper span.arrowDown:after,
  .flatpickr-month .numInputWrapper span.arrowUp:after,
  .flatpickr-month .numInputWrapper span.arrowDown:after {
    border-bottom-color: #161616;
    border-top-color: #161616; }

.bx--date-picker__month .numInputWrapper span.arrowUp,
.flatpickr-month .numInputWrapper span.arrowUp {
  top: 4px; }

.bx--date-picker__month .numInputWrapper span.arrowDown,
.flatpickr-month .numInputWrapper span.arrowDown {
  top: 11px; }

span.bx--date-picker__weekday,
span.flatpickr-weekday {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #161616;
  font-weight: 400; }

.bx--date-picker__day,
.flatpickr-day {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #161616;
  border-radius: 0;
  border: none;
  -webkit-transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--date-picker__day:hover,
  .flatpickr-day:hover {
    background: #e5e5e5; }
  .bx--date-picker__day:focus,
  .flatpickr-day:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px;
    outline-color: #0f62fe; }

.bx--date-picker__days .nextMonthDay,
.bx--date-picker__days .prevMonthDay {
  color: #393939; }

.bx--date-picker__day.today,
.flatpickr-day.today {
  position: relative;
  color: #0f62fe;
  font-weight: 600; }
  .bx--date-picker__day.today::after,
  .flatpickr-day.today::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0.4375rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 0.25rem;
    width: 0.25rem;
    background: #0f62fe; }

.bx--date-picker__day.today.no-border,
.flatpickr-day.today.no-border {
  border: none; }

.bx--date-picker__day.today.selected {
  border: 2px solid #0f62fe; }
  .bx--date-picker__day.today.selected::after {
    display: none; }

.bx--date-picker__day.disabled,
.flatpickr-day.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: #161616; }
  .bx--date-picker__day.disabled:hover,
  .flatpickr-day.disabled:hover {
    background: transparent; }

.bx--date-picker__day.inRange,
.flatpickr-day.inRange {
  background: #d0e2ff;
  color: #161616; }

.bx--date-picker__day.selected,
.flatpickr-day.selected {
  color: #ffffff;
  background: #0f62fe; }

.bx--date-picker__day.startRange.selected,
.flatpickr-day.startRange.selected {
  box-shadow: none;
  z-index: 2; }

.bx--date-picker__day.endRange:hover,
.flatpickr-day.endRange:hover {
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
  background: #f4f4f4;
  color: #161616; }

.bx--date-picker__day.endRange.inRange,
.flatpickr-day.endRange.inRange {
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
  background: #f4f4f4;
  z-index: 3; }

.bx--date-picker__day.endRange.inRange.selected,
.flatpickr-day.endRange.inRange.selected {
  color: #ffffff;
  background: #0f62fe; }

.bx--date-picker__day.startRange.inRange:not(.selected),
.flatpickr-day.startRange.inRange:not(.selected) {
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
  background: #f4f4f4;
  z-index: 3; }

.bx--date-picker__days,
.dayContainer {
  width: 100%;
  min-width: 100%;
  height: 15.375rem; }

.flatpickr-innerContainer,
.flatpickr-rContainer {
  width: 100%;
  height: auto; }

.bx--date-picker__weekdays,
.flatpickr-weekdays,
.flatpickr-weekdaycontainer {
  width: 100%; }

.flatpickr-weekdays {
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.flatpickr-weekdaycontainer {
  display: flex; }

.flatpickr-months {
  display: flex;
  width: 100%;
  position: relative; }

.flatpickr-prev-month,
.flatpickr-next-month {
  padding-top: 12px; }

.flatpickr-prev-month:hover svg,
.flatpickr-next-month:hover svg {
  fill: #161616; }

.flatpickr-next-month.disabled svg,
.flatpickr-prev-month.disabled svg {
  fill: #161616;
  opacity: 0.5;
  cursor: not-allowed; }

.flatpickr-next-month.disabled:hover svg,
.flatpickr-prev-month.disabled:hover svg {
  fill: #161616; }

.bx--date-picker.bx--skeleton input,
.bx--date-picker__input.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%; }
  .bx--date-picker.bx--skeleton input:hover, .bx--date-picker.bx--skeleton input:focus, .bx--date-picker.bx--skeleton input:active,
  .bx--date-picker__input.bx--skeleton:hover,
  .bx--date-picker__input.bx--skeleton:focus,
  .bx--date-picker__input.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--date-picker.bx--skeleton input:before,
  .bx--date-picker__input.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--date-picker.bx--skeleton input:before,
      .bx--date-picker__input.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }
  .bx--date-picker.bx--skeleton input::-webkit-input-placeholder,
  .bx--date-picker__input.bx--skeleton::-webkit-input-placeholder {
    color: transparent; }

.bx--date-picker.bx--skeleton .bx--label {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 4.6875rem;
  height: 0.875rem; }
  .bx--date-picker.bx--skeleton .bx--label:hover, .bx--date-picker.bx--skeleton .bx--label:focus, .bx--date-picker.bx--skeleton .bx--label:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--date-picker.bx--skeleton .bx--label:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--date-picker.bx--skeleton .bx--label:before {
        -webkit-animation: none;
                animation: none; } }

.bx--dropdown__wrapper--inline {
  display: inline-grid;
  align-items: center;
  grid-template: auto auto / auto -webkit-min-content;
  grid-template: auto auto / auto min-content;
  grid-gap: 0.25rem; }
  .bx--dropdown__wrapper--inline .bx--label {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px; }
  .bx--dropdown__wrapper--inline .bx--label,
  .bx--dropdown__wrapper--inline .bx--form__helper-text,
  .bx--dropdown__wrapper--inline .bx--form-requirement {
    margin: 0; }
  .bx--dropdown__wrapper--inline .bx--form-requirement {
    grid-column: 2; }

.bx--dropdown {
  outline: 2px solid transparent;
  outline-offset: -2px;
  position: relative;
  list-style: none;
  display: block;
  background-color: #f4f4f4;
  border: none;
  border-bottom: 1px solid #8d8d8d;
  width: 100%;
  height: 2.5rem;
  cursor: pointer;
  color: #161616;
  outline: 2px solid transparent;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--dropdown:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--dropdown:hover {
    background-color: #e5e5e5; }

.bx--dropdown--open {
  border-bottom-color: #e0e0e0; }

.bx--dropdown--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }
  .bx--dropdown--invalid .bx--dropdown-text {
    padding-right: 3.5rem; }
  .bx--dropdown--invalid + .bx--form-requirement {
    display: inline-block;
    max-height: 12.5rem;
    color: #da1e28; }

.bx--dropdown__invalid-icon {
  position: absolute;
  top: 50%;
  right: 2.5rem;
  fill: #da1e28;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.bx--dropdown--open:hover {
  background-color: #f4f4f4; }

.bx--dropdown--open:focus {
  outline: 1px solid transparent; }

.bx--dropdown--open .bx--dropdown-list {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.bx--dropdown--light {
  background-color: #ffffff; }

.bx--dropdown--up .bx--dropdown-list {
  bottom: 2rem; }

.bx--dropdown__arrow {
  fill: #161616;
  position: absolute;
  right: 1rem;
  top: 0.8125rem;
  pointer-events: none;
  -webkit-transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-transform-origin: 50% 45%;
          transform-origin: 50% 45%; }

button.bx--dropdown-text {
  background: none;
  border: none;
  width: 100%;
  text-align: left; }
  button.bx--dropdown-text:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }

.bx--dropdown-text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: block;
  height: 2.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 2.625rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.bx--dropdown-list {
  outline: 2px solid transparent;
  outline-offset: -2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style: none;
  position: absolute;
  z-index: 9100;
  max-height: 0;
  -webkit-transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  overflow-x: hidden;
  overflow-y: auto; }

.bx--dropdown:not(.bx--dropdown--open)
.bx--dropdown-item {
  visibility: hidden; }

.bx--dropdown-item {
  -webkit-transition: visibility 70ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: visibility 70ms cubic-bezier(0.2, 0, 0.38, 0.9), opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  opacity: 0;
  visibility: inherit; }
  .bx--dropdown-item:hover {
    background-color: #e5e5e5; }
    .bx--dropdown-item:hover + .bx--dropdown-item .bx--dropdown-link {
      border-color: transparent; }
  .bx--dropdown-item:active {
    background-color: #e0e0e0; }
  .bx--dropdown-item:first-of-type .bx--dropdown-link {
    border-top-color: transparent; }

.bx--dropdown-item:last-of-type .bx--dropdown-link {
  border-bottom: none; }

.bx--dropdown-link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: block;
  height: 2.5rem;
  color: #393939;
  text-decoration: none;
  font-weight: normal;
  line-height: 1rem;
  padding: 0.6875rem 0;
  margin: 0 1rem;
  border: 1px solid transparent;
  border-top-color: #e0e0e0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }
  .bx--dropdown-link:hover {
    color: #161616;
    border-color: transparent; }

.bx--dropdown--focused,
.bx--dropdown-link:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
  margin: 0;
  padding: 0.6875rem 1rem; }

.bx--dropdown-item:hover .bx--dropdown-link {
  border-bottom-color: #e5e5e5; }

.bx--dropdown--selected {
  display: none; }

.bx--dropdown--open .bx--dropdown__arrow {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

.bx--dropdown--open .bx--dropdown-list {
  max-height: 15rem;
  -webkit-transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0, 0, 0.38, 0.9); }

.bx--dropdown--open .bx--dropdown-item {
  opacity: 1; }

.bx--dropdown--disabled {
  border-bottom-color: transparent;
  cursor: not-allowed; }
  .bx--dropdown--disabled:hover {
    background-color: #f4f4f4; }
  .bx--dropdown--disabled:focus {
    outline: none; }
  .bx--dropdown--disabled .bx--dropdown-text {
    color: #c6c6c6; }
  .bx--dropdown--disabled .bx--dropdown__arrow {
    fill: #c6c6c6; }
  .bx--dropdown--disabled.bx--dropdown--light:hover {
    background-color: #ffffff; }

.bx--dropdown--auto-width {
  width: auto;
  max-width: 25rem; }

.bx--dropdown--inline {
  justify-self: start;
  display: inline-block;
  border-bottom-color: transparent;
  width: auto;
  height: 2rem;
  background-color: #ffffff;
  -webkit-transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9);
  transition: background 70ms cubic-bezier(0, 0, 0.38, 0.9); }
  .bx--dropdown--inline:hover {
    background-color: #e5e5e5; }
  .bx--dropdown--inline.bx--dropdown--disabled {
    background-color: #ffffff; }
  .bx--dropdown--inline .bx--dropdown__arrow {
    top: 0.5rem;
    right: 0.5rem; }

.bx--dropdown--inline.bx--dropdown--open {
  background-color: transparent; }

.bx--dropdown--inline .bx--dropdown-text {
  display: inline-block;
  padding: 0.4375rem 2rem 0.4375rem 0.75rem;
  height: 2rem;
  overflow: visible;
  color: #161616; }

.bx--dropdown--inline.bx--dropdown--disabled
.bx--dropdown-text {
  color: #c6c6c6; }

.bx--dropdown--inline.bx--dropdown--disabled:focus
.bx--dropdown-text {
  outline: 0; }

.bx--dropdown--inline.bx--dropdown--invalid
.bx--dropdown__invalid-icon {
  right: 2rem; }

.bx--dropdown--inline.bx--dropdown--invalid
.bx--dropdown-text {
  padding-right: 3.5rem; }

.bx--dropdown--inline.bx--dropdown--open:focus
.bx--dropdown-list {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1); }

.bx--dropdown--inline .bx--dropdown-link {
  font-weight: normal;
  margin-right: 0.75rem;
  margin-left: 0.625rem; }
  .bx--dropdown--inline .bx--dropdown-link:focus {
    margin: 0;
    padding-left: 0.625rem; }

.bx--dropdown-v2.bx--skeleton,
.bx--dropdown.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5; }
  .bx--dropdown-v2.bx--skeleton:hover, .bx--dropdown-v2.bx--skeleton:focus, .bx--dropdown-v2.bx--skeleton:active,
  .bx--dropdown.bx--skeleton:hover,
  .bx--dropdown.bx--skeleton:focus,
  .bx--dropdown.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--dropdown-v2.bx--skeleton:before,
  .bx--dropdown.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--dropdown-v2.bx--skeleton:before,
      .bx--dropdown.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

.bx--modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  content: '';
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: background-color 720ms cubic-bezier(0.4, 0.14, 1, 1), opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), z-index 720ms cubic-bezier(0.4, 0.14, 1, 1), visibility 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 1, 1), opacity 240ms cubic-bezier(0.4, 0.14, 1, 1), z-index 720ms cubic-bezier(0.4, 0.14, 1, 1), visibility 240ms cubic-bezier(0.4, 0.14, 1, 1); }
  .bx--modal.is-visible {
    z-index: 9000;
    visibility: visible;
    opacity: 1;
    background-color: rgba(22, 22, 22, 0.5);
    -webkit-transition: background-color 720ms cubic-bezier(0, 0, 0.3, 1), opacity 240ms cubic-bezier(0, 0, 0.3, 1), z-index 720ms cubic-bezier(0, 0, 0.3, 1), visibility 240ms cubic-bezier(0, 0, 0.3, 1);
    transition: background-color 720ms cubic-bezier(0, 0, 0.3, 1), opacity 240ms cubic-bezier(0, 0, 0.3, 1), z-index 720ms cubic-bezier(0, 0, 0.3, 1), visibility 240ms cubic-bezier(0, 0, 0.3, 1); }
  .bx--modal .bx--text-input,
  .bx--modal .bx--text-area,
  .bx--modal .bx--search-input,
  .bx--modal .bx--select-input,
  .bx--modal .bx--dropdown,
  .bx--modal .bx--dropdown-list,
  .bx--modal .bx--number input[type='number'] {
    background-color: #ffffff; }

.bx--modal.is-visible .bx--modal-container {
  -webkit-transition: -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1);
  transition: transform 240ms cubic-bezier(0, 0, 0.3, 1), -webkit-transform 240ms cubic-bezier(0, 0, 0.3, 1);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.bx--modal-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transform: translate3d(0, -24px, 0);
          transform: translate3d(0, -24px, 0);
  -webkit-transition: -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1);
  transition: transform 240ms cubic-bezier(0.4, 0.14, 1, 1), -webkit-transform 240ms cubic-bezier(0.4, 0.14, 1, 1); }
  @media (min-width: 42rem) {
    .bx--modal-container {
      width: 50%;
      max-width: 768px;
      max-height: 90%;
      height: auto; } }
  @media (min-width: 66rem) {
    .bx--modal-container {
      max-height: 80%; } }

.bx--modal-header,
.bx--modal-content {
  padding-right: 25%;
  padding-left: 1rem; }

.bx--modal-header,
.bx--modal-footer {
  flex-shrink: 0; }

.bx--modal-header {
  padding-top: 1rem;
  margin-bottom: 1rem; }

.bx--modal-header__label {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #393939;
  margin-bottom: 0.25rem; }

.bx--modal-header__heading {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.625rem;
  letter-spacing: 0;
  color: #161616; }

.bx--modal-content {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  overflow-y: auto;
  margin-bottom: 3rem;
  color: #161616;
  font-weight: 400; }
  .bx--modal-content:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }

.bx--modal-content > * {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px; }

.bx--modal-footer {
  display: flex;
  margin-top: auto;
  height: 4rem;
  background-color: #e0e0e0; }
  .bx--modal-footer button.bx--btn {
    max-width: none;
    flex: 1 1;
    height: 4rem;
    margin: 0;
    padding-top: 1rem;
    padding-bottom: 2rem; }

.bx--modal-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
  padding: 0.75rem;
  border: 2px solid transparent;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--modal-close:hover {
    background-color: #e5e5e5; }
  .bx--modal-close:focus {
    outline: none;
    border-color: #0f62fe; }

.bx--modal-close::-moz-focus-inner {
  border: 0; }

.bx--modal-close__icon {
  fill: #161616;
  height: 1.25rem;
  width: 1.25rem; }

.bx--body--with-modal-open {
  overflow: hidden; }

.bx--multi-select .bx--list-box__menu {
  min-width: auto; }

.bx--multi-select
.bx--list-box__menu-item__option
.bx--checkbox-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; }

.bx--multi-select
.bx--list-box__menu-item__option
> .bx--form-item {
  margin: 0;
  flex-direction: row; }

.bx--multi-select
.bx--list-box__menu-item
.bx--checkbox:checked
~ .bx--checkbox-label-text {
  color: #161616; }

.bx--multi-select--filterable .bx--list-box__selection--multi {
  position: absolute;
  left: 0.5rem;
  right: auto; }

.bx--multi-select--selected .bx--text-input {
  padding-left: 3.5rem; }

.bx--inline-notification {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: auto;
  min-height: 3rem;
  min-width: 18rem;
  max-width: 18rem;
  color: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  @media (min-width: 42rem) {
    .bx--inline-notification {
      max-width: 38rem; } }
  @media (min-width: 66rem) {
    .bx--inline-notification {
      max-width: 46rem; } }
  @media (min-width: 99rem) {
    .bx--inline-notification {
      max-width: 52rem; } }

.bx--inline-notification--low-contrast {
  color: #161616; }
  .bx--inline-notification--low-contrast::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -webkit-filter: opacity(0.4);
            filter: opacity(0.4);
    border-style: solid;
    border-width: 1px 1px 1px 0; }

.bx--inline-notification--error {
  border-left: 3px solid #fa4d56;
  background: #393939; }
  .bx--inline-notification--error .bx--inline-notification__icon,
  .bx--inline-notification--error .bx--toast-notification__icon {
    fill: #fa4d56; }

.bx--inline-notification--low-contrast.bx--inline-notification--error {
  border-left: 3px solid #da1e28;
  background: #fff1f1; }
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--error .bx--toast-notification__icon {
    fill: #da1e28; }
  .bx--inline-notification--low-contrast.bx--inline-notification--error:before {
    border-color: #da1e28; }

.bx--inline-notification--success {
  border-left: 3px solid #42be65;
  background: #393939; }
  .bx--inline-notification--success .bx--inline-notification__icon,
  .bx--inline-notification--success .bx--toast-notification__icon {
    fill: #42be65; }

.bx--inline-notification--low-contrast.bx--inline-notification--success {
  border-left: 3px solid #24a148;
  background: #defbe6; }
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--success .bx--toast-notification__icon {
    fill: #24a148; }
  .bx--inline-notification--low-contrast.bx--inline-notification--success:before {
    border-color: #24a148; }

.bx--inline-notification--info {
  border-left: 3px solid #4589ff;
  background: #393939; }
  .bx--inline-notification--info .bx--inline-notification__icon,
  .bx--inline-notification--info .bx--toast-notification__icon {
    fill: #4589ff; }

.bx--inline-notification--low-contrast.bx--inline-notification--info {
  border-left: 3px solid #0043ce;
  background: #edf5ff; }
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--info .bx--toast-notification__icon {
    fill: #0043ce; }
  .bx--inline-notification--low-contrast.bx--inline-notification--info:before {
    border-color: #0043ce; }

.bx--inline-notification--info .bx--inline-notification__icon {
  display: none; }

.bx--inline-notification--warning {
  border-left: 3px solid #f1c21b;
  background: #393939; }
  .bx--inline-notification--warning .bx--inline-notification__icon,
  .bx--inline-notification--warning .bx--toast-notification__icon {
    fill: #f1c21b; }

.bx--inline-notification--low-contrast.bx--inline-notification--warning {
  border-left: 3px solid #f1c21b;
  background: #fdf6dd; }
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--inline-notification__icon,
  .bx--inline-notification--low-contrast.bx--inline-notification--warning .bx--toast-notification__icon {
    fill: #f1c21b; }
  .bx--inline-notification--low-contrast.bx--inline-notification--warning:before {
    border-color: #f1c21b; }

.bx--inline-notification--warning
.bx--inline-notification__icon
path[opacity='0'] {
  fill: #000000;
  opacity: 1; }

.bx--inline-notification__details {
  display: flex;
  margin: 0 1rem; }

.bx--inline-notification__icon {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 0.875rem; }

.bx--inline-notification__text-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.75rem 0; }

.bx--inline-notification__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  margin: 0 0.25rem 0 0;
  line-height: 1.5rem; }

.bx--inline-notification__subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  word-break: break-word; }

.bx--inline-notification__action-button {
  height: 2rem;
  margin: 0.5rem 0; }
  .bx--inline-notification__action-button, .bx--inline-notification__action-button:hover, .bx--inline-notification__action-button:focus, .bx--inline-notification__action-button:active {
    color: #78a9ff; }
  .bx--inline-notification__action-button:hover {
    background-color: #4c4c4c; }

.bx--inline-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--inline-notification__close-button .bx--inline-notification__close-icon {
    height: 1rem;
    width: 1rem;
    fill: #ffffff; }

.bx--inline-notification--low-contrast .bx--inline-notification__close-button
.bx--inline-notification__close-icon {
  fill: #161616; }

.bx--inline-notification--low-contrast .bx--inline-notification__action-button {
  color: #0f62fe; }
  .bx--inline-notification--low-contrast .bx--inline-notification__action-button:active {
    color: #002d9c; }
  .bx--inline-notification--low-contrast .bx--inline-notification__action-button:active, .bx--inline-notification--low-contrast .bx--inline-notification__action-button:hover {
    background-color: #ffffff; }

.bx--toast-notification {
  display: flex;
  width: 18rem;
  height: auto;
  padding-left: 1rem;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2); }
  .bx--toast-notification:first-child {
    margin-top: 1rem; }
  @media (min-width: 99rem) {
    .bx--toast-notification {
      width: 22rem; } }

.bx--toast-notification--low-contrast {
  color: #161616; }

.bx--toast-notification--error {
  border-left: 3px solid #fa4d56;
  background: #393939; }
  .bx--toast-notification--error .bx--inline-notification__icon,
  .bx--toast-notification--error .bx--toast-notification__icon {
    fill: #fa4d56; }

.bx--toast-notification--low-contrast.bx--toast-notification--error {
  border-left: 3px solid #da1e28;
  background: #fff1f1; }
  .bx--toast-notification--low-contrast.bx--toast-notification--error .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--error .bx--toast-notification__icon {
    fill: #da1e28; }

.bx--toast-notification--success {
  border-left: 3px solid #42be65;
  background: #393939; }
  .bx--toast-notification--success .bx--inline-notification__icon,
  .bx--toast-notification--success .bx--toast-notification__icon {
    fill: #42be65; }

.bx--toast-notification--low-contrast.bx--toast-notification--success {
  border-left: 3px solid #24a148;
  background: #defbe6; }
  .bx--toast-notification--low-contrast.bx--toast-notification--success .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--success .bx--toast-notification__icon {
    fill: #24a148; }

.bx--toast-notification--info {
  border-left: 3px solid #4589ff;
  background: #393939; }
  .bx--toast-notification--info .bx--inline-notification__icon,
  .bx--toast-notification--info .bx--toast-notification__icon {
    fill: #4589ff; }

.bx--toast-notification--low-contrast.bx--toast-notification--info {
  border-left: 3px solid #0043ce;
  background: #edf5ff; }
  .bx--toast-notification--low-contrast.bx--toast-notification--info .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--info .bx--toast-notification__icon {
    fill: #0043ce; }

.bx--toast-notification--warning {
  border-left: 3px solid #f1c21b;
  background: #393939; }
  .bx--toast-notification--warning .bx--inline-notification__icon,
  .bx--toast-notification--warning .bx--toast-notification__icon {
    fill: #f1c21b; }

.bx--toast-notification--low-contrast.bx--toast-notification--warning {
  border-left: 3px solid #f1c21b;
  background: #fdf6dd; }
  .bx--toast-notification--low-contrast.bx--toast-notification--warning .bx--inline-notification__icon,
  .bx--toast-notification--low-contrast.bx--toast-notification--warning .bx--toast-notification__icon {
    fill: #f1c21b; }

.bx--toast-notification--warning
.bx--toast-notification__icon
path[opacity='0'] {
  fill: #000000;
  opacity: 1; }

.bx--toast-notification__icon {
  flex-shrink: 0;
  margin-right: 1rem;
  margin-top: 1rem; }

.bx--toast-notification__details {
  margin-right: 1rem; }

.bx--toast-notification__close-button {
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
  height: 3rem;
  width: 3rem;
  min-height: 3rem;
  min-width: 3rem;
  -webkit-transition: outline 110ms, background-color 110ms;
  transition: outline 110ms, background-color 110ms; }
  .bx--toast-notification__close-button:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--toast-notification__close-button .bx--toast-notification__close-icon {
    height: 1rem;
    width: 1rem;
    fill: #ffffff; }

.bx--toast-notification--low-contrast
.bx--toast-notification__close-button
.bx--toast-notification__close-icon {
  fill: #161616; }

.bx--toast-notification__title {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  font-weight: 600;
  margin-top: 1rem;
  word-break: break-word; }

.bx--toast-notification__subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 1.5rem;
  word-break: break-word; }

.bx--toast-notification--low-contrast
.bx--toast-notification__subtitle {
  color: #161616; }

.bx--toast-notification__caption {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #ffffff;
  margin-bottom: 1rem; }

.bx--toast-notification--low-contrast
.bx--toast-notification__caption {
  color: #161616; }

.bx--tooltip__label {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  display: inline-flex;
  align-items: center;
  color: #393939; }
  .bx--tooltip__label:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__label .bx--tooltip__trigger {
    margin-left: 0.5rem; }

.bx--tooltip__trigger svg {
  fill: #525252; }

.bx--tooltip__trigger:not(.bx--btn--icon-only) {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem; }
  .bx--tooltip__trigger:not(.bx--btn--icon-only)::-moz-focus-inner {
    border: 0; }
  .bx--tooltip__trigger:not(.bx--btn--icon-only):focus {
    outline: 1px solid #0f62fe;
    fill: #0353e9; }

.bx--tooltip__label--bold {
  font-weight: 600; }

.bx--tooltip {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  display: none;
  min-width: 13rem;
  max-width: 18rem;
  background: #393939;
  margin-top: 0.25rem;
  padding: 1rem;
  border-radius: 0.125rem;
  z-index: 10000;
  word-wrap: break-word;
  color: #ffffff; }
  .bx--tooltip:focus {
    box-shadow: inset 0 0 0 1px #393939, inset 0 0 0 2px #ffffff;
    outline: 0; }
  .bx--tooltip p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px; }
  .bx--tooltip button {
    padding-right: 2rem; }
  .bx--tooltip .bx--btn:focus {
    border-color: #ffffff;
    outline-color: #393939; }
  .bx--tooltip .bx--link {
    color: #78a9ff;
    font-size: 0.875rem; }
    .bx--tooltip .bx--link:focus {
      outline: 1px solid #ffffff;
      outline-offset: 2px; }
    .bx--tooltip .bx--link:active {
      color: #ffffff; }
    .bx--tooltip .bx--link:visited {
      color: #78a9ff; }
  .bx--tooltip .bx--tooltip__caret {
    border-left: 0.42969rem solid transparent;
    border-right: 0.42969rem solid transparent;
    border-bottom: 0.42969rem solid #393939;
    position: absolute;
    left: 0;
    top: calc(-0.42969rem + 1px);
    right: 0;
    width: 0;
    height: 0;
    margin: 0 auto;
    content: ''; }
  .bx--tooltip .bx--tooltip__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem; }
  .bx--tooltip[data-floating-menu-direction='left'] .bx--tooltip__caret {
    left: auto;
    top: 50%;
    right: calc(-0.42969rem + 1px);
    -webkit-transform: rotate(90deg) translate(50%, -50%);
            transform: rotate(90deg) translate(50%, -50%); }
  .bx--tooltip[data-floating-menu-direction='top'] .bx--tooltip__caret {
    top: auto;
    bottom: calc(-0.42969rem + 1px);
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .bx--tooltip[data-floating-menu-direction='right'] .bx--tooltip__caret {
    left: calc(-0.42969rem + 1px);
    top: 50%;
    right: auto;
    -webkit-transform: rotate(270deg) translate(50%, -50%);
            transform: rotate(270deg) translate(50%, -50%); }

.bx--tooltip__heading {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  margin-bottom: 0.5rem; }

.bx--tooltip--shown {
  display: block; }

/* begin legacy definition tooltip TODO: deprecate */
.bx--tooltip--definition {
  position: relative; }
  .bx--tooltip--definition .bx--tooltip__trigger {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.32px;
    display: inline-flex;
    position: relative;
    border-bottom: 1px dotted #0f62fe;
    color: #161616; }
    .bx--tooltip--definition .bx--tooltip__trigger:hover {
      cursor: pointer; }
      .bx--tooltip--definition .bx--tooltip__trigger:hover + .bx--tooltip--definition__top,
      .bx--tooltip--definition .bx--tooltip__trigger:hover + .bx--tooltip--definition__bottom {
        display: block; }
    .bx--tooltip--definition .bx--tooltip__trigger:focus {
      outline: 1px solid #0f62fe; }
      .bx--tooltip--definition .bx--tooltip__trigger:focus + .bx--tooltip--definition__top,
      .bx--tooltip--definition .bx--tooltip__trigger:focus + .bx--tooltip--definition__bottom {
        display: block; }

.bx--tooltip--definition__bottom,
.bx--tooltip--definition__top {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 1;
  display: none;
  background: #393939;
  width: 13rem;
  margin-top: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 0.125rem;
  pointer-events: none;
  cursor: pointer; }
  .bx--tooltip--definition__bottom p,
  .bx--tooltip--definition__top p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    color: #ffffff; }
  .bx--tooltip--definition__bottom .bx--tooltip__caret,
  .bx--tooltip--definition__top .bx--tooltip__caret {
    position: absolute;
    right: 0;
    left: 0;
    width: 0.6rem;
    height: 0.6rem;
    background: #393939;
    margin-left: 1rem; }

.bx--tooltip--definition__bottom .bx--tooltip__caret {
  top: -0.2rem;
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg); }

.bx--tooltip--definition__top {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  margin-top: -2rem; }
  .bx--tooltip--definition__top .bx--tooltip__caret {
    bottom: -0.2rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }

.bx--tooltip--definition__align-end {
  right: 0; }

.bx--tooltip--definition__align-center {
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.bx--tooltip--definition__top.bx--tooltip--definition__align-center {
  margin-left: 50%;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%); }

.bx--tooltip--definition__align-center .bx--tooltip__caret {
  left: auto;
  margin-left: auto;
  margin-right: calc(50% - 6px); }

.bx--tooltip--definition__align-end .bx--tooltip__caret {
  left: auto;
  margin-left: auto;
  margin-right: 1rem; }

/* end legacy definition tooltip */
.bx--tooltip--definition.bx--tooltip--a11y {
  display: inline-flex; }

.bx--tooltip--definition button.bx--tooltip--a11y {
  margin: 0; }

.bx--tooltip__trigger.bx--tooltip__trigger--definition {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  border-bottom: 0.0625rem dotted #0f62fe; }

.bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover::after, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top:focus + .bx--assistive-text {
    top: -0.5625rem;
    left: 0;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-start:focus + .bx--assistive-text {
    top: -0.5625rem;
    left: 0;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-center:focus + .bx--assistive-text {
    top: -0.5625rem;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::before {
    top: -0.25rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--top.bx--tooltip--align-end:focus + .bx--assistive-text {
    top: -0.5625rem;
    right: 0;
    left: auto;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }

.bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover::after, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom:focus + .bx--assistive-text {
    bottom: -0.5625rem;
    left: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start:focus + .bx--assistive-text {
    bottom: -0.5625rem;
    left: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-start.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center:focus + .bx--assistive-text {
    bottom: -0.5625rem;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-center.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::before {
    bottom: -0.25rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end:focus + .bx--assistive-text {
    bottom: -0.5625rem;
    right: 0;
    left: auto;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip__trigger--definition.bx--tooltip--bottom.bx--tooltip--align-end.bx--tooltip--a11y + .bx--assistive-text {
    bottom: -0.5rem;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }

/* begin tooltip icon (TODO: deprecate) */
.bx--tooltip--icon {
  display: inline-flex;
  align-items: center; }

.bx--tooltip--icon__top,
.bx--tooltip--icon__bottom {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip--icon__top::before, .bx--tooltip--icon__top::after,
  .bx--tooltip--icon__bottom::before,
  .bx--tooltip--icon__bottom::after {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip--icon__top::before,
  .bx--tooltip--icon__bottom::before {
    right: 0;
    left: 0;
    width: 0;
    height: 0;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-style: solid;
    border-color: transparent transparent #393939 transparent;
    margin: 0 auto;
    content: '';
    margin-top: 1px;
    margin-left: 50%; }
  .bx--tooltip--icon__top::after,
  .bx--tooltip--icon__bottom::after {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    min-width: 1.5rem;
    max-width: 13rem;
    height: 1.5rem;
    margin-left: 50%;
    padding: 0 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    content: attr(aria-label);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    white-space: nowrap;
    pointer-events: none;
    background-color: #393939; }
  .bx--tooltip--icon__top:hover::before, .bx--tooltip--icon__top:hover::after, .bx--tooltip--icon__top:focus::before, .bx--tooltip--icon__top:focus::after,
  .bx--tooltip--icon__bottom:hover::before,
  .bx--tooltip--icon__bottom:hover::after,
  .bx--tooltip--icon__bottom:focus::before,
  .bx--tooltip--icon__bottom:focus::after {
    opacity: 1; }
  .bx--tooltip--icon__top:hover svg, .bx--tooltip--icon__top:focus svg,
  .bx--tooltip--icon__bottom:hover svg,
  .bx--tooltip--icon__bottom:focus svg {
    fill: #525252; }
  .bx--tooltip--icon__top:focus,
  .bx--tooltip--icon__bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip--icon__top:focus svg,
    .bx--tooltip--icon__bottom:focus svg {
      outline: 1px solid #0f62fe; }

.bx--tooltip--icon__top::before {
  top: 1px;
  -webkit-transform: translate(-50%, calc(-100% - 9px)) rotate(180deg);
          transform: translate(-50%, calc(-100% - 9px)) rotate(180deg); }

.bx--tooltip--icon__top::after {
  top: 0;
  -webkit-transform: translate(-50%, calc(-100% - 12px));
          transform: translate(-50%, calc(-100% - 12px)); }

.bx--tooltip--icon__bottom::before {
  bottom: 0;
  -webkit-transform: translate(-50%, 10px) rotate(0);
          transform: translate(-50%, 10px) rotate(0); }

.bx--tooltip--icon__bottom::after {
  bottom: 0;
  -webkit-transform: translate(-50%, calc(100% + 10px));
          transform: translate(-50%, calc(100% + 10px)); }

.bx--tooltip--icon__top.bx--tooltip--icon__align-start::before {
  top: 1px;
  margin-left: 4px;
  -webkit-transform: translate(0, calc(-100% - 9px)) rotate(180deg);
          transform: translate(0, calc(-100% - 9px)) rotate(180deg); }

.bx--tooltip--icon__top.bx--tooltip--icon__align-start::after {
  top: 0;
  margin-left: 0;
  -webkit-transform: translate(0, calc(-100% - 12px));
          transform: translate(0, calc(-100% - 12px)); }

.bx--tooltip--icon__top.bx--tooltip--icon__align-end::before {
  top: 1px;
  margin-right: 4px;
  left: auto;
  right: 0;
  -webkit-transform: translate(0, calc(-100% - 9px)) rotate(180deg);
          transform: translate(0, calc(-100% - 9px)) rotate(180deg); }

.bx--tooltip--icon__top.bx--tooltip--icon__align-end::after {
  top: 0;
  margin-left: 0;
  right: 0;
  -webkit-transform: translate(0, calc(-100% - 12px));
          transform: translate(0, calc(-100% - 12px)); }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-start::before {
  bottom: 0;
  margin-left: 4px;
  -webkit-transform: translate(0, 10px) rotate(0);
          transform: translate(0, 10px) rotate(0); }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-start::after {
  bottom: 0;
  margin-left: 0;
  -webkit-transform: translate(0, calc(100% + 10px));
          transform: translate(0, calc(100% + 10px)); }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-end::before {
  bottom: 0;
  margin-right: 4px;
  left: auto;
  right: 0;
  -webkit-transform: translate(0, 10px) rotate(0);
          transform: translate(0, 10px) rotate(0); }

.bx--tooltip--icon__bottom.bx--tooltip--icon__align-end::after {
  bottom: 0;
  margin-left: 0;
  right: 0;
  -webkit-transform: translate(0, calc(100% + 10px));
          transform: translate(0, calc(100% + 10px)); }

.bx--tooltip--icon .bx--tooltip__trigger svg {
  margin-left: 0; }

/* end legacy tooltip icon */
.bx--tooltip__trigger:hover svg, .bx--tooltip__trigger:focus svg {
  fill: #525252; }

.bx--tooltip__trigger.bx--tooltip--top {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--top:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--top:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--top:focus svg {
      outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--top::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--top .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--top::after,
      .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--tooltip__trigger.bx--tooltip--top::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--top:hover::before, .bx--tooltip__trigger.bx--tooltip--top:hover::after, .bx--tooltip__trigger.bx--tooltip--top:focus::before, .bx--tooltip__trigger.bx--tooltip--top:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--top:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--top::before, .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top::after,
  .bx--tooltip__trigger.bx--tooltip--top:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top:focus + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-start:focus + .bx--assistive-text {
    top: -0.8125rem;
    left: 0;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-center:focus + .bx--assistive-text {
    top: -0.8125rem;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:focus + .bx--assistive-text {
    top: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::before {
    top: -0.5rem;
    border-width: 0.3125rem 0.25rem 0 0.25rem;
    border-color: #393939 transparent transparent transparent;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%); }
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--top.bx--tooltip--align-end:focus + .bx--assistive-text {
    top: -0.8125rem;
    right: 0;
    left: auto;
    -webkit-transform: translate(0, -100%);
            transform: translate(0, -100%); }

.bx--tooltip__trigger.bx--tooltip--right {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--right:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--right:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--right:focus svg {
      outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--right::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--right .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--right::after,
      .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--tooltip__trigger.bx--tooltip--right::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--right:hover::before, .bx--tooltip__trigger.bx--tooltip--right:hover::after, .bx--tooltip__trigger.bx--tooltip--right:focus::before, .bx--tooltip__trigger.bx--tooltip--right:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--right:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--right::before, .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right::after,
  .bx--tooltip__trigger.bx--tooltip--right:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right:focus + .bx--assistive-text {
    right: -0.8125rem;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:focus + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-start:focus + .bx--assistive-text {
    right: -0.8125rem;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:focus + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-center:focus + .bx--assistive-text {
    right: -0.8125rem;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:focus + .bx--assistive-text {
    top: 50%;
    right: 0; }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::before {
    right: -0.5rem;
    border-width: 0.25rem 0.3125rem 0.25rem 0;
    border-color: transparent #393939 transparent transparent;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--right.bx--tooltip--align-end:focus + .bx--assistive-text {
    right: -0.8125rem;
    -webkit-transform: translate(100%, -50%);
            transform: translate(100%, -50%); }

.bx--tooltip__trigger.bx--tooltip--bottom {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--bottom:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--bottom:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--bottom:focus svg {
      outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--bottom::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--bottom .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--bottom::after,
      .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--tooltip__trigger.bx--tooltip--bottom::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--bottom:hover::before, .bx--tooltip__trigger.bx--tooltip--bottom:hover::after, .bx--tooltip__trigger.bx--tooltip--bottom:focus::before, .bx--tooltip__trigger.bx--tooltip--bottom:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--bottom:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--bottom::before, .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom::after,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom:focus + .bx--assistive-text {
    bottom: -0.8125rem;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-start:focus + .bx--assistive-text {
    bottom: -0.8125rem;
    left: 0;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-center:focus + .bx--assistive-text {
    bottom: -0.8125rem;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:focus + .bx--assistive-text {
    bottom: 0;
    left: 50%; }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::before {
    bottom: -0.5rem;
    border-width: 0 0.25rem 0.3125rem 0.25rem;
    border-color: transparent transparent #393939 transparent;
    -webkit-transform: translate(-50%, 100%);
            transform: translate(-50%, 100%); }
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--bottom.bx--tooltip--align-end:focus + .bx--assistive-text {
    bottom: -0.8125rem;
    right: 0;
    left: auto;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%); }

.bx--tooltip__trigger.bx--tooltip--left {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--left:focus {
    outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--left:focus {
    outline: 1px solid transparent; }
    .bx--tooltip__trigger.bx--tooltip--left:focus svg {
      outline: 1px solid #0f62fe; }
  .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    letter-spacing: 0.16px;
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
        display: inline-block; } }
  .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after {
    -webkit-transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--a11y::after {
    -webkit-transition: none;
    transition: none; }
  .bx--tooltip__trigger.bx--tooltip--left::before {
    width: 0;
    height: 0;
    border-style: solid;
    content: ''; }
  .bx--tooltip__trigger.bx--tooltip--left .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left + .bx--assistive-text {
    box-sizing: content-box;
    color: inherit;
    opacity: 1;
    white-space: normal; }
  .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 13rem;
    height: auto;
    padding: 0.1875rem 1rem;
    border-radius: 0.125rem;
    color: #ffffff;
    font-weight: 400;
    text-align: left;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    pointer-events: none;
    background-color: #393939; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-accelerator: true) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
        width: 13rem; } }
    @supports (-ms-ime-align: auto) {
      .bx--tooltip__trigger.bx--tooltip--left::after,
      .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text,
      .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
        width: 13rem; } }
  .bx--tooltip__trigger.bx--tooltip--left::after {
    content: attr(aria-label); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--a11y::after {
    content: none; }
  .bx--tooltip__trigger.bx--tooltip--left:hover::before, .bx--tooltip__trigger.bx--tooltip--left:hover::after, .bx--tooltip__trigger.bx--tooltip--left:focus::before, .bx--tooltip__trigger.bx--tooltip--left:focus::after {
    opacity: 1; }

@keyframes tooltip-fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
  .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
    clip: auto;
    margin: auto;
    overflow: visible; }
  .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:hover.bx--tooltip--a11y::before, .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text, .bx--tooltip__trigger.bx--tooltip--left:focus.bx--tooltip--a11y::before {
    -webkit-animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
            animation: tooltip-fade 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tooltip__trigger.bx--tooltip--left::before, .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left::after,
  .bx--tooltip__trigger.bx--tooltip--left:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left:focus + .bx--assistive-text {
    left: -0.8125rem;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:focus + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-start:focus + .bx--assistive-text {
    left: -0.8125rem;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:focus + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-center:focus + .bx--assistive-text {
    left: -0.8125rem;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::before, .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:focus + .bx--assistive-text {
    top: 50%;
    left: 0; }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::before {
    left: -0.5rem;
    border-width: 0.25rem 0 0.25rem 0.3125rem;
    border-color: transparent transparent transparent #393939;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end::after,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:hover .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:focus .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:hover + .bx--assistive-text,
  .bx--tooltip__trigger.bx--tooltip--left.bx--tooltip--align-end:focus + .bx--assistive-text {
    left: -0.8125rem;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%); }

.bx--tabs {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #161616;
  height: auto;
  width: 100%;
  position: relative; }
  @media (min-width: 42rem) {
    .bx--tabs {
      background: none;
      min-height: 2.5rem; } }

.bx--tabs-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 2.5rem;
  cursor: pointer;
  color: #161616;
  outline: 2px solid transparent;
  border-bottom: 1px solid #8d8d8d;
  background-color: #f4f4f4; }
  @media (min-width: 42rem) {
    .bx--tabs-trigger {
      display: none; } }

.bx--tabs-trigger:focus,
.bx--tabs-trigger:active {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--tabs-trigger svg {
  width: 0.75rem;
  height: 0.4375rem;
  fill: #161616;
  -webkit-transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tabs-trigger--open:focus,
.bx--tabs-trigger--open:active {
  outline: 2px solid transparent;
  outline-offset: -2px;
  -webkit-transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tabs-trigger--open {
  background: #e0e0e0; }

.bx--tabs-trigger--open svg {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
  -webkit-transition: -webkit-transform 70ms;
  transition: -webkit-transform 70ms;
  transition: transform 70ms;
  transition: transform 70ms, -webkit-transform 70ms;
  -webkit-transform-origin: 50% 45%;
          transform-origin: 50% 45%; }

.bx--tabs--light.bx--tabs-trigger {
  background-color: #ffffff; }

.bx--tabs-trigger-text {
  text-decoration: none;
  padding-top: 2px;
  color: #161616;
  font-weight: 400; }

.bx--tabs-trigger-text:hover {
  color: #161616; }

.bx--tabs-trigger-text:focus {
  outline: none; }

.bx--tabs__nav {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
  padding: 0;
  position: absolute;
  list-style: none;
  display: flex;
  flex-direction: column;
  z-index: 9100;
  background: #f4f4f4;
  -webkit-transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  max-height: 600px;
  width: 100%; }
  @media (min-width: 42rem) {
    .bx--tabs__nav {
      flex-direction: row;
      background: none;
      box-shadow: none;
      z-index: auto;
      -webkit-transition: inherit;
      transition: inherit;
      width: auto; } }

.bx--tabs__nav--hidden {
  -webkit-transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  overflow: hidden;
  max-height: 0; }
  @media (min-width: 42rem) {
    .bx--tabs__nav--hidden {
      display: flex;
      -webkit-transition: inherit;
      transition: inherit;
      overflow: visible;
      max-height: none; } }

.bx--tabs__nav-item {
  background-color: #f4f4f4;
  display: flex;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 2.5rem;
  -webkit-transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item {
      background: transparent;
      height: auto; }
      .bx--tabs__nav-item + .bx--tabs__nav-item {
        margin-left: 0.125rem; } }

.bx--tabs__nav-item .bx--tabs__nav-link {
  -webkit-transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), border-bottom-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

@media (min-width: 42rem) {
  .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected) {
    background: transparent; } }

.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) {
  background-color: #e5e5e5;
  box-shadow: 0 -1px 0 #e5e5e5; }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--disabled) {
      background-color: transparent;
      box-shadow: none; } }

.bx--tabs__nav-item--disabled,
.bx--tabs__nav-item--disabled:hover {
  cursor: not-allowed;
  outline: none; }

.bx--tabs__nav-item--disabled .bx--tabs__nav-link {
  pointer-events: none; }

.bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) {
  border: none;
  display: none;
  -webkit-transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) {
      display: flex; }
      .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link {
        color: #161616;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.125rem;
        letter-spacing: 0.16px;
        border-bottom: 3px solid #0f62fe; }
      .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:focus,
      .bx--tabs__nav-item--selected:not(.bx--tabs__nav-item--disabled) .bx--tabs__nav-link:active {
        color: #161616;
        border-bottom: 2px solid #0f62fe; } }

a.bx--tabs__nav-link {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: inline-block;
  color: #393939;
  text-decoration: none;
  font-weight: 400;
  padding: 0.75rem 0;
  width: calc(100% - 32px);
  height: 2.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 1rem;
  line-height: 1rem;
  border-bottom: 1px solid #e0e0e0;
  overflow: hidden;
  -webkit-transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
    width: 100%;
    margin: 0;
    padding-left: 16px;
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  @media (min-width: 42rem) {
    a.bx--tabs__nav-link {
      border-bottom: 3px solid #e0e0e0;
      padding: 0.5rem 1rem;
      width: 10rem;
      margin: 0;
      line-height: inherit; }
      a.bx--tabs__nav-link:focus, a.bx--tabs__nav-link:active {
        width: 10rem;
        padding: 0.5rem 1rem;
        border-bottom: 2px; } }

.bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled)
.bx--tabs__nav-link {
  color: #161616; }
  @media (min-width: 42rem) {
    .bx--tabs__nav-item:hover:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled)
.bx--tabs__nav-link {
      color: #161616;
      border-bottom: 3px solid #8d8d8d; } }

.bx--tabs__nav-item--disabled .bx--tabs__nav-link {
  color: #c6c6c6;
  border-bottom: 3px solid #f4f4f4; }

.bx--tabs__nav-item--disabled:hover .bx--tabs__nav-link {
  cursor: no-drop;
  border-bottom: 3px solid #f4f4f4; }

.bx--tabs__nav-item--disabled .bx--tabs__nav-link:focus,
.bx--tabs__nav-item--disabled a.bx--tabs__nav-link:active {
  outline: none;
  border-bottom: 3px solid #f4f4f4; }

.bx--tabs__nav-item:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled):not(.bx--tabs__nav-item--selected)
.bx--tabs__nav-link:focus,
.bx--tabs__nav-item:not(.bx--tabs__nav-item--selected):not(.bx--tabs__nav-item--disabled):not(.bx--tabs__nav-item--selected)
a.bx--tabs__nav-link:active {
  color: #393939; }

.bx--tabs.bx--skeleton {
  pointer-events: none;
  cursor: default; }

.bx--tabs.bx--skeleton .bx--tabs__nav-link {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 4.6875rem;
  height: 0.75rem; }
  .bx--tabs.bx--skeleton .bx--tabs__nav-link:hover, .bx--tabs.bx--skeleton .bx--tabs__nav-link:focus, .bx--tabs.bx--skeleton .bx--tabs__nav-link:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--tabs.bx--skeleton .bx--tabs__nav-link:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--tabs.bx--skeleton .bx--tabs__nav-link:before {
        -webkit-animation: none;
                animation: none; } }

.bx--tabs.bx--skeleton .bx--tabs-trigger {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 6.25rem; }
  .bx--tabs.bx--skeleton .bx--tabs-trigger:hover, .bx--tabs.bx--skeleton .bx--tabs-trigger:focus, .bx--tabs.bx--skeleton .bx--tabs-trigger:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--tabs.bx--skeleton .bx--tabs-trigger:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--tabs.bx--skeleton .bx--tabs-trigger:before {
        -webkit-animation: none;
                animation: none; } }

.bx--tabs.bx--skeleton .bx--tabs-trigger svg {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--tag {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 1.5rem;
  margin: 0.25rem;
  border-radius: 0.9375rem; }
  .bx--tag:not(:first-child) {
    margin-left: 0; }
  .bx--tag.bx--skeleton {
    background-color: #e0e0e0;
    color: #161616; }

.bx--tag--red {
  background-color: #ffd7d9;
  color: #a2191f; }

.bx--tag--magenta {
  background-color: #ffd6e8;
  color: #9f1853; }

.bx--tag--purple {
  background-color: #e8daff;
  color: #6929c4; }

.bx--tag--blue {
  background-color: #d0e2ff;
  color: #0043ce; }

.bx--tag--cyan {
  background-color: #bae6ff;
  color: #00539a; }

.bx--tag--teal {
  background-color: #9ef0f0;
  color: #005d5d; }

.bx--tag--green {
  background-color: #a7f0ba;
  color: #0e6027; }

.bx--tag--gray {
  background-color: #e0e0e0;
  color: #161616; }

.bx--tag--cool-gray {
  background-color: #dde1e6;
  color: #121619; }

.bx--tag--warm-gray {
  background-color: #e5e0df;
  color: #171414; }

.bx--tag--disabled {
  background-color: #f4f4f4;
  color: #c6c6c6; }
  .bx--tag--disabled:hover {
    cursor: not-allowed; }

.bx--tag--filter {
  background-color: #393939;
  color: #ffffff;
  cursor: pointer;
  padding-right: 0.125rem; }

.bx--tag--filter > svg {
  fill: #ffffff;
  margin-left: 0.25rem;
  padding: 0.125rem;
  width: 1.25rem;
  height: 1.25rem; }

.bx--tag--filter > svg:hover {
  border-radius: 50%;
  background-color: #4c4c4c; }

.bx--tag--filter:focus,
.bx--tag--filter:hover {
  outline: none; }

.bx--tag--filter:focus > svg {
  box-shadow: inset 0 0 0 2px #ffffff;
  border-radius: 50%; }

.bx--tag.bx--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 3.75rem;
  overflow: hidden; }
  .bx--tag.bx--skeleton:hover, .bx--tag.bx--skeleton:focus, .bx--tag.bx--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--tag.bx--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--tag.bx--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

.bx--data-table-container + .bx--pagination {
  border-top: 0; }

.bx--pagination {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  width: 100%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  height: 3rem; }

.bx--pagination .bx--select {
  height: 100%;
  align-items: center;
  grid-template-columns: auto 0; }

.bx--select-input__wrapper,
.bx--select-input--inline__wrapper {
  height: 100%; }

.bx--pagination .bx--select-input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  width: auto;
  min-width: auto;
  height: 100%;
  padding: 0 2.5rem 0 1rem;
  margin-right: -0.65rem; }
  @media (min-width: 42rem) {
    .bx--pagination .bx--select-input {
      padding-right: 2.25rem;
      margin-right: 0; } }

.bx--pagination .bx--select-input:hover {
  background: #e5e5e5; }

.bx--pagination .bx--select__arrow {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
  @media (min-width: 42rem) {
    .bx--pagination .bx--select__arrow {
      right: 1rem; } }

.bx--pagination
.bx--select__item-count
.bx--select-input {
  border-right: 0.0625rem solid #e0e0e0; }

.bx--pagination
.bx--select__page-number
.bx--select-input {
  border-left: 1px solid #e0e0e0; }

.bx--pagination__left,
.bx--pagination__right {
  display: flex;
  height: 100%;
  align-items: center; }

.bx--pagination__left > .bx--form-item,
.bx--pagination__right > .bx--form-item {
  height: 100%; }

.bx--pagination__left .bx--pagination__text {
  margin-right: 0.0625rem; }

.bx--pagination__right .bx--pagination__text {
  margin-right: 1rem;
  margin-left: 0.0625rem; }

@media (min-width: 42rem) {
  .bx--pagination__left {
    padding: 0 1rem; } }

.bx--pagination__text {
  display: none; }
  @media (min-width: 42rem) {
    .bx--pagination__text {
      display: inline-block; } }

span.bx--pagination__text {
  margin-left: 1rem;
  color: #393939; }

.bx--pagination__button {
  border: none;
  background: none;
  cursor: pointer;
  height: 100%;
  margin: 0;
  padding: 0 0.875rem;
  border-left: 1px solid #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: #161616;
  -webkit-transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background-color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--pagination__button:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--pagination__button:hover {
  background: #e5e5e5; }

.bx--pagination__button--no-index {
  fill: #c6c6c6;
  cursor: not-allowed; }

.bx--pagination__button:disabled:hover,
.bx--pagination__button--no-index:hover {
  cursor: not-allowed;
  fill: #c6c6c6;
  background: #f4f4f4; }

.bx--pagination--inline {
  height: 2.625rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  margin-right: -1rem; }

.bx--pagination--inline .bx--pagination__button {
  height: 2.5rem;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  margin: 0; }

.bx--pagination--inline .bx--pagination__button--forward {
  border-right: 0;
  padding: 0 1rem;
  margin-left: 1rem; }

.bx--pagination--inline .bx--pagination__button--backward {
  margin: 0 1rem;
  padding: 0 1rem; }

.bx--pagination.bx--skeleton .bx--skeleton__text {
  margin-right: 1rem;
  margin-bottom: 0; }

@-webkit-keyframes collapse-accordion {
  0% {
    height: 100%;
    visibility: visible;
    opacity: 1; }
  100% {
    height: 0;
    visibility: hidden;
    opacity: 0; } }

@keyframes collapse-accordion {
  0% {
    height: 100%;
    visibility: visible;
    opacity: 1; }
  100% {
    height: 0;
    visibility: hidden;
    opacity: 0; } }

@-webkit-keyframes expand-accordion {
  0% {
    height: 0;
    visibility: hidden;
    opacity: 0; }
  100% {
    height: 100%;
    visibility: visible;
    opacity: 1; } }

@keyframes expand-accordion {
  0% {
    height: 0;
    visibility: hidden;
    opacity: 0; }
  100% {
    height: 100%;
    visibility: visible;
    opacity: 1; } }

.bx--accordion {
  list-style: none;
  width: 100%; }

.bx--accordion__item {
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  border-top: 1px solid #e0e0e0;
  overflow: visible; }
  .bx--accordion__item:last-child {
    border-bottom: 1px solid #e0e0e0; }

.bx--accordion__heading {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  color: #161616;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0.375rem 0;
  flex-direction: row-reverse;
  position: relative;
  width: 100%;
  margin: 0;
  -webkit-transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  transition: background-color cubic-bezier(0.2, 0, 0.38, 0.9) 110ms; }
  .bx--accordion__heading::-moz-focus-inner {
    border: 0; }
  .bx--accordion__heading:hover::before, .bx--accordion__heading:focus::before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: calc(100% + 2px); }
  .bx--accordion__heading:hover::before {
    background-color: #e5e5e5; }
  .bx--accordion__heading:focus {
    outline: none; }
  .bx--accordion__heading:focus:before {
    border: 2px solid #0f62fe;
    box-sizing: border-box; }

.bx--accordion__arrow {
  outline: 2px solid transparent;
  outline-offset: -2px;
  flex: 0 0 1rem;
  width: 1rem;
  height: 1rem;
  margin: 2px 1rem 0 0;
  fill: #161616;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--accordion__title {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  margin: 0 0 0 1rem;
  width: 100%;
  text-align: left;
  z-index: 0; }

.bx--accordion__content {
  display: none;
  -webkit-transition: padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  transition: padding cubic-bezier(0.2, 0, 0.38, 0.9) 110ms;
  padding-left: 1rem;
  padding-right: 25%; }
  @media (max-width: 42rem) {
    .bx--accordion__content {
      padding-right: 3rem; } }
  .bx--accordion__content p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.16px; }

.bx--accordion__item--collapsing .bx--accordion__content,
.bx--accordion__item--expanding .bx--accordion__content {
  display: block; }

.bx--accordion__item--collapsing .bx--accordion__content {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) collapse-accordion;
          animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) collapse-accordion; }

.bx--accordion__item--expanding .bx--accordion__content {
  -webkit-animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) expand-accordion;
          animation: 110ms cubic-bezier(0.2, 0, 0.38, 0.9) expand-accordion; }

.bx--accordion__item--active {
  overflow: visible; }
  .bx--accordion__item--active .bx--accordion__content {
    display: block;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
    -webkit-transition: padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms;
    transition: padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms; }
  .bx--accordion__item--active .bx--accordion__arrow {
    /*rtl:ignore*/
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    fill: #161616; }

.bx--accordion.bx--skeleton .bx--accordion__heading,
.bx--accordion.bx--skeleton .bx--accordion__button {
  cursor: default; }

.bx--accordion.bx--skeleton .bx--accordion__arrow {
  pointer-events: none;
  fill: #161616;
  cursor: default; }
  .bx--accordion.bx--skeleton .bx--accordion__arrow:hover, .bx--accordion.bx--skeleton .bx--accordion__arrow:focus, .bx--accordion.bx--skeleton .bx--accordion__arrow:active {
    border: none;
    outline: none;
    cursor: default; }

.bx--skeleton
.bx--accordion__heading:focus
.bx--accordion__arrow {
  border: none;
  outline: none;
  cursor: default; }

.bx--accordion__title.bx--skeleton__text {
  margin-bottom: 0; }

.bx--progress {
  display: flex;
  list-style: none; }

.bx--progress-step {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  min-width: 7rem;
  width: 8rem;
  overflow: visible; }
  .bx--progress-step .bx--tooltip__label {
    display: block; }

.bx--progress-line {
  position: absolute;
  left: 0;
  height: 1px;
  width: 8rem;
  border: 1px inset transparent; }

.bx--progress-step svg {
  position: relative;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 9px 0.5rem 0 0;
  fill: #0f62fe; }

.bx--progress-label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  line-height: 1.45;
  max-width: 5.5rem;
  margin: 0.5rem 0 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-transition: box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), color 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--progress-label::before {
    content: '';
    display: block; }

.bx--progress-label:hover {
  color: #0f62fe;
  cursor: pointer;
  box-shadow: 0 0.0625rem #0f62fe; }

.bx--progress-label:focus {
  outline: none;
  color: #0f62fe;
  box-shadow: 0 0.1875rem 0 0 #0f62fe; }

.bx--progress-label:active {
  color: #0f62fe;
  box-shadow: 0 0.1875rem 0 0 #0f62fe; }

.bx--progress-label-overflow:hover ~ .bx--tooltip,
.bx--progress-label-overflow:focus ~ .bx--tooltip {
  visibility: visible; }

.bx--progress-step .bx--tooltip .bx--tooltip__caret {
  margin-left: 0.625rem; }

.bx--tooltip__text {
  padding: 0;
  margin: 0;
  font-weight: normal; }

.bx--progress-step .bx--tooltip {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  min-width: 7.1875rem;
  width: 7.8125rem;
  min-height: 1.5rem;
  margin-left: 1.375rem;
  margin-top: 2.5rem;
  padding: 0.5rem 1rem;
  display: block;
  visibility: hidden;
  color: #ffffff; }

.bx--progress-step .bx--tooltip_multi {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.16px;
  width: 9.375rem;
  height: auto;
  color: #ffffff; }

.bx--progress-optional {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  position: absolute;
  left: 0;
  margin-left: 1.5rem;
  margin-top: 1.75rem;
  color: #161616; }

.bx--progress-step--current .bx--progress-line {
  background-color: #0f62fe; }

.bx--progress-step--current svg {
  width: 14px;
  height: 14px;
  fill: #0f62fe;
  margin-top: 0.59375rem; }

.bx--progress-step--incomplete svg {
  fill: #161616; }

.bx--progress-step--incomplete .bx--progress-line {
  background-color: #e0e0e0; }

.bx--progress-step--complete .bx--progress-line {
  background-color: #0f62fe; }

.bx--progress-step-button {
  display: flex; }

.bx--progress-step-button--unclickable {
  outline: none; }

.bx--progress-step--disabled {
  cursor: not-allowed; }
  .bx--progress-step--disabled svg {
    fill: #c6c6c6;
    cursor: not-allowed; }
  .bx--progress-step--disabled .bx--progress-label,
  .bx--progress-step--disabled .bx--progress-label:hover {
    color: #c6c6c6;
    cursor: not-allowed;
    box-shadow: none; }
  .bx--progress-step--disabled .bx--progress-label:focus,
  .bx--progress-step--disabled .bx--progress-label:active {
    outline: none;
    box-shadow: none; }
  .bx--progress-step--disabled .bx--progress-line {
    cursor: not-allowed; }
  .bx--progress-step--disabled .bx--progress-label-overflow:hover
~ .bx--tooltip--definition
.bx--tooltip--definition__bottom {
    display: none; }

.bx--progress__warning > path {
  fill: #da1e28; }

.bx--progress.bx--skeleton .bx--progress-label {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  height: 0.75rem;
  width: 2.5rem; }
  .bx--progress.bx--skeleton .bx--progress-label:hover, .bx--progress.bx--skeleton .bx--progress-label:focus, .bx--progress.bx--skeleton .bx--progress-label:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--progress.bx--skeleton .bx--progress-label:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--progress.bx--skeleton .bx--progress-label:before {
        -webkit-animation: none;
                animation: none; } }

.bx--progress--vertical {
  display: block; }

.bx--progress--vertical .bx--progress-step {
  display: list-item;
  min-height: 6rem;
  width: auto;
  width: initial;
  min-width: 0;
  min-width: initial; }
  .bx--progress--vertical .bx--progress-step svg {
    display: inline-block;
    margin: 0.1rem 0.5rem; }

.bx--progress--vertical .bx--progress-step--current svg {
  margin-left: 0.563rem; }

.bx--progress--vertical .bx--progress-label {
  display: inline-block;
  width: auto;
  width: initial;
  max-width: none;
  vertical-align: top;
  margin: 0; }

.bx--progress--vertical .bx--progress-step .bx--tooltip {
  margin-top: 0.5rem; }

.bx--progress--vertical .bx--progress-optional {
  margin-top: auto;
  position: static;
  position: initial;
  margin-left: 2.25rem; }

.bx--progress--vertical .bx--progress-line {
  top: 0;
  left: 0;
  height: 100%;
  width: 1px; }

.bx--breadcrumb {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: inline; }
  @media (min-width: 42rem) {
    .bx--breadcrumb {
      display: flex;
      flex-wrap: wrap; } }

.bx--breadcrumb-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 0.5rem; }

.bx--breadcrumb-item::after {
  content: '/';
  color: #161616;
  margin-left: 0.5rem; }

.bx--breadcrumb--no-trailing-slash
.bx--breadcrumb-item:last-child::after {
  content: ''; }

.bx--breadcrumb-item:last-child,
.bx--breadcrumb-item:last-child::after {
  margin-right: 0; }

.bx--breadcrumb .bx--link {
  white-space: nowrap; }

.bx--breadcrumb-item [aria-current='page'],
.bx--breadcrumb-item.bx--breadcrumb-item--current
.bx--link {
  color: #161616;
  cursor: auto; }
  .bx--breadcrumb-item [aria-current='page']:hover,
  .bx--breadcrumb-item.bx--breadcrumb-item--current
.bx--link:hover {
    text-decoration: none; }

.bx--breadcrumb.bx--skeleton .bx--link {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 6.25rem;
  height: 1rem; }
  .bx--breadcrumb.bx--skeleton .bx--link:hover, .bx--breadcrumb.bx--skeleton .bx--link:focus, .bx--breadcrumb.bx--skeleton .bx--link:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--breadcrumb.bx--skeleton .bx--link:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--breadcrumb.bx--skeleton .bx--link:before {
        -webkit-animation: none;
                animation: none; } }

.bx--toolbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 1rem 0; }
  .bx--toolbar > div {
    margin: 0 0.25rem; }
  .bx--toolbar .bx--search-input {
    height: 2rem;
    background-color: transparent;
    outline: none; }
  .bx--toolbar .bx--search-close {
    display: none; }
  .bx--toolbar .bx--overflow-menu__icon {
    fill: #393939;
    -webkit-transition: fill 50ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: fill 50ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--toolbar .bx--search-magnifier {
    fill: #393939;
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
    -webkit-transition: all 175ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: all 175ms cubic-bezier(0.2, 0, 0.38, 0.9);
    top: 0.5rem;
    left: 0.375rem;
    cursor: pointer; }
  .bx--toolbar fieldset {
    border: 0;
    padding: 0; }
  .bx--toolbar .bx--toolbar-search--active {
    width: 15.625rem; }
    .bx--toolbar .bx--toolbar-search--active .bx--search-magnifier {
      -webkit-transform: scale(1);
              transform: scale(1);
      top: 0.5625rem; }
    .bx--toolbar .bx--toolbar-search--active .bx--search-input {
      background-color: #ffffff; }
    .bx--toolbar .bx--toolbar-search--active .bx--search-close {
      display: block; }
  .bx--toolbar .bx--checkbox-label {
    margin-bottom: 0; }
  .bx--toolbar .bx--overflow-menu--open > .bx--overflow-menu__icon {
    fill: #0f62fe; }

.bx--toolbar-search {
  width: 1.8rem;
  -webkit-transition: all 175ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 175ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--toolbar-search__btn {
  position: absolute;
  left: 0;
  top: 0;
  background: transparent;
  border: 0;
  height: 2rem;
  width: 2rem; }
  .bx--toolbar-search__btn:focus {
    outline: 1px solid #0f62fe; }

.bx--toolbar-filter-icon {
  padding-left: 0;
  padding-right: 0; }

.bx--toolbar-menu__title {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  font-weight: 600;
  padding: 0.5rem 1.25rem; }

.bx--toolbar-menu__option {
  padding: 0.5rem 1.25rem; }

.bx--toolbar-menu__divider {
  width: 100%;
  border: 0;
  border-top: 1px solid #e0e0e0; }

.bx--radio-button-group {
  border: none; }

.bx--toolbar-search:not(.bx--toolbar-search--active)
.bx--search-input {
  border-bottom: none; }

.bx--time-picker {
  display: flex;
  align-items: flex-end; }

.bx--time-picker[data-invalid] .bx--time-picker__input-field {
  outline: 2px solid #da1e28;
  outline-offset: -2px; }

.bx--time-picker__select {
  justify-content: center; }
  .bx--time-picker__select:not(:last-of-type) {
    margin: 0 0.125rem; }

.bx--time-picker__input {
  display: flex;
  flex-direction: column; }

.bx--time-picker .bx--select-input {
  min-width: auto;
  width: auto;
  padding-right: 3rem;
  line-height: 1; }

.bx--time-picker__input-field {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
  width: 4.875rem;
  height: 2.5rem;
  -webkit-transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--slider-container {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.bx--slider {
  position: relative;
  width: 100%;
  margin: 0 1rem;
  max-width: 40rem;
  min-width: 12.5rem; }

.bx--slider__range-label {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.32px;
  font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
  color: #161616; }
  .bx--slider__range-label:last-of-type {
    margin-right: 1rem; }

.bx--slider__track {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: #e0e0e0;
  cursor: pointer;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%); }

.bx--slider__track:before {
  content: '';
  position: absolute;
  display: inline-block;
  height: 0.25rem;
  width: 0.125rem;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  top: -0.3125rem;
  background: #e0e0e0; }

.bx--slider__filled-track {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: #161616;
  -webkit-transform-origin: left;
          transform-origin: left;
  pointer-events: none;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  -webkit-transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--slider__thumb {
  position: absolute;
  height: 0.875rem;
  width: 0.875rem;
  background: #161616;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px transparent, inset 0 0 0 2px transparent;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9), background 110ms cubic-bezier(0.2, 0, 0.38, 0.9), box-shadow 110ms cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  cursor: pointer;
  outline: none;
  z-index: 3; }
  .bx--slider__thumb:hover {
    -webkit-transform: translate(-50%, -50%) scale(1.42857);
            transform: translate(-50%, -50%) scale(1.42857); }
  .bx--slider__thumb:focus {
    -webkit-transform: translate(-50%, -50%) scale(1.42857);
            transform: translate(-50%, -50%) scale(1.42857);
    box-shadow: inset 0 0 0 2px #0f62fe, inset 0 0 0 3px #f4f4f4;
    background-color: #0f62fe; }
  .bx--slider__thumb:active {
    -webkit-transform: translate(-50%, -50%) scale(1.42857);
            transform: translate(-50%, -50%) scale(1.42857);
    box-shadow: inset 0 0 0 2px #0f62fe; }

.bx--slider__input {
  display: none; }

.bx--slider-text-input,
.bx-slider-text-input {
  width: 4rem;
  height: 2.5rem;
  padding: 0;
  text-align: center;
  -moz-appearance: textfield; }
  .bx--slider-text-input::-webkit-outer-spin-button, .bx--slider-text-input::-webkit-inner-spin-button,
  .bx-slider-text-input::-webkit-outer-spin-button,
  .bx-slider-text-input::-webkit-inner-spin-button {
    display: none; }

.bx--slider__thumb:focus ~ .bx--slider__filled-track {
  background-color: #0f62fe; }

.bx--label--disabled
~ .bx--slider-container
> .bx--slider__range-label {
  color: #c6c6c6; }

.bx--slider--disabled .bx--slider__thumb {
  background-color: #e0e0e0; }
  .bx--slider--disabled .bx--slider__thumb:hover {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: not-allowed; }
  .bx--slider--disabled .bx--slider__thumb:focus {
    box-shadow: none;
    outline: none;
    background-color: #e0e0e0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .bx--slider--disabled .bx--slider__thumb:active {
    background: #e0e0e0;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.bx--slider--disabled .bx--slider__track,
.bx--slider--disabled .bx--slider__filled-track,
.bx--slider--disabled
.bx--slider__thumb:focus
~ .bx--slider__filled-track {
  background-color: #e0e0e0;
  cursor: not-allowed; }

.bx--slider--disabled
~ .bx--form-item
.bx--slider-text-input,
.bx--slider--disabled ~ .bx--slider-text-input {
  background-color: #f4f4f4;
  color: #c6c6c6;
  -webkit-transition: none;
  transition: none;
  cursor: not-allowed;
  border: none; }
  .bx--slider--disabled
~ .bx--form-item
.bx--slider-text-input:active, .bx--slider--disabled
~ .bx--form-item
.bx--slider-text-input:focus, .bx--slider--disabled
~ .bx--form-item
.bx--slider-text-input:hover,
  .bx--slider--disabled ~ .bx--slider-text-input:active,
  .bx--slider--disabled ~ .bx--slider-text-input:focus,
  .bx--slider--disabled ~ .bx--slider-text-input:hover {
    outline: none;
    color: #c6c6c6; }

.bx--slider-container.bx--skeleton
.bx--slider__range-label {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 1.25rem;
  height: 0.75rem; }
  .bx--slider-container.bx--skeleton
.bx--slider__range-label:hover, .bx--slider-container.bx--skeleton
.bx--slider__range-label:focus, .bx--slider-container.bx--skeleton
.bx--slider__range-label:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--slider-container.bx--skeleton
.bx--slider__range-label:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--slider-container.bx--skeleton
.bx--slider__range-label:before {
        -webkit-animation: none;
                animation: none; } }

.bx--slider-container.bx--skeleton
.bx--slider__track {
  cursor: default;
  pointer-events: none; }

.bx--slider-container.bx--skeleton
.bx--slider__thumb {
  left: 50%;
  cursor: default;
  pointer-events: none; }

.bx--tile {
  display: block;
  min-width: 8rem;
  min-height: 4rem;
  background-color: #f4f4f4;
  position: relative;
  padding: 1rem;
  outline: 2px solid transparent;
  outline-offset: -2px; }
  .bx--tile:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }

.bx--tile--clickable,
.bx--tile--selectable,
.bx--tile--expandable {
  -webkit-transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  cursor: pointer; }
  .bx--tile--clickable:hover,
  .bx--tile--selectable:hover,
  .bx--tile--expandable:hover {
    background: #e5e5e5; }
  .bx--tile--clickable:hover .bx--tile__checkmark, .bx--tile--clickable:focus .bx--tile__checkmark,
  .bx--tile--selectable:hover .bx--tile__checkmark,
  .bx--tile--selectable:focus .bx--tile__checkmark,
  .bx--tile--expandable:hover .bx--tile__checkmark,
  .bx--tile--expandable:focus .bx--tile__checkmark {
    opacity: 1; }

.bx--tile--clickable:focus,
.bx--tile--expandable:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--tile--selectable {
  padding-right: 3rem; }

.bx--tile__checkmark,
.bx--tile__chevron {
  position: absolute;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  border: none;
  background: transparent; }

.bx--tile__checkmark {
  height: 1rem;
  top: 1rem;
  right: 1rem;
  opacity: 0; }
  .bx--tile__checkmark svg {
    border-radius: 50%;
    fill: #525252; }
  .bx--tile__checkmark:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }

.bx--tile__chevron {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  height: 1rem; }
  .bx--tile__chevron svg {
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    fill: #161616; }
  .bx--tile__chevron:hover {
    cursor: pointer; }
  .bx--tile__chevron:focus {
    outline: none; }

.bx--tile--expandable {
  overflow: hidden;
  -webkit-transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 150ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tile-content__above-the-fold {
  display: block; }

.bx--tile-content__below-the-fold {
  display: block;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tile--is-expanded {
  overflow: visible;
  -webkit-transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: max-height 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }
  .bx--tile--is-expanded .bx--tile__chevron svg {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .bx--tile--is-expanded .bx--tile-content__below-the-fold {
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 110ms cubic-bezier(0.2, 0, 0.38, 0.9), visibility 110ms cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--tile--is-selected {
  outline: 1px solid #393939;
  outline-offset: -1px; }

.bx--tile--is-selected .bx--tile__checkmark {
  opacity: 1; }

.bx--tile-input:checked
+ .bx--tile
> .bx--tile__checkmark
svg {
  fill: #161616; }

.bx--tile-content {
  width: 100%;
  height: 100%; }

.bx--tile-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--skeleton__text {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem; }
  .bx--skeleton__text:hover, .bx--skeleton__text:focus, .bx--skeleton__text:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--skeleton__text:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--skeleton__text:before {
        -webkit-animation: none;
                animation: none; } }

.bx--skeleton__heading {
  height: 1.5rem; }

.bx--icon--skeleton {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  display: inline-block;
  width: 1rem;
  height: 1rem; }
  .bx--icon--skeleton:hover, .bx--icon--skeleton:focus, .bx--icon--skeleton:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--icon--skeleton:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--icon--skeleton:before {
        -webkit-animation: none;
                animation: none; } }

.bx--skeleton__placeholder {
  position: relative;
  border: none;
  padding: 0;
  box-shadow: none;
  pointer-events: none;
  background: #e5e5e5;
  height: 6.25rem;
  width: 6.25rem; }
  .bx--skeleton__placeholder:hover, .bx--skeleton__placeholder:focus, .bx--skeleton__placeholder:active {
    border: none;
    outline: none;
    cursor: default; }
  .bx--skeleton__placeholder:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #c6c6c6;
    -webkit-animation: 3000ms ease-in-out skeleton infinite;
            animation: 3000ms ease-in-out skeleton infinite; }
    @media (prefers-reduced-motion: reduce) {
      .bx--skeleton__placeholder:before {
        -webkit-animation: none;
                animation: none; } }

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg); } }

/* Stroke animations */
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240; }
  100% {
    stroke-dashoffset: 40; } }

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40; }
  100% {
    stroke-dashoffset: 240; } }

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

.bx--inline-loading {
  display: flex;
  width: 100%;
  min-height: 2rem;
  align-items: center; }
  .bx--inline-loading .bx--loading__svg circle {
    stroke-width: 12; }
  .bx--inline-loading .bx--loading__stroke {
    stroke-dashoffset: 99; }

.bx--inline-loading__text {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  color: #393939; }

.bx--inline-loading__animation {
  position: relative;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center; }

.bx--inline-loading__checkmark-container {
  fill: #0f62fe; }
  .bx--inline-loading__checkmark-container.bx--inline-loading__svg {
    width: 0.75rem;
    position: absolute;
    top: 0.75rem; }
  .bx--inline-loading__checkmark-container[hidden] {
    display: none; }

.bx--inline-loading__checkmark {
  fill: none;
  stroke: #0f62fe;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-width: 1.8;
  stroke-dasharray: 12;
  stroke-dashoffset: 12;
  -webkit-animation-name: stroke;
          animation-name: stroke;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

.bx--inline-loading--error {
  fill: #da1e28;
  width: 1rem;
  height: 1rem; }
  .bx--inline-loading--error[hidden] {
    display: none; }

.bx--loading--small .bx--inline-loading__svg {
  stroke: #0f62fe; }

/* If IE11 Don't show check animation */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .bx--inline-loading__checkmark-container {
    top: 1px;
    right: 0.5rem; }
  .bx--inline-loading__checkmark {
    -webkit-animation: none;
            animation: none;
    stroke-dashoffset: 0;
    stroke-dasharray: 0; } }

.bx--pagination-nav {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  line-height: 0; }

.bx--pagination-nav__list {
  align-items: center;
  display: flex;
  list-style: none; }

.bx--pagination-nav__list-item {
  padding: 0; }
  .bx--pagination-nav__list-item:first-child {
    padding-left: 0; }
  .bx--pagination-nav__list-item:last-child {
    padding-right: 0; }

.bx--pagination-nav__page {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 0;
  color: #393939;
  display: block;
  font-weight: 400;
  line-height: 1;
  min-width: 3rem;
  outline: 0;
  padding: 1.0625rem 0.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color, color;
  transition: background-color, color;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .bx--pagination-nav__page::-moz-focus-inner {
    border: 0; }
  .bx--pagination-nav__page:hover {
    background-color: #e5e5e5;
    color: #393939; }
  .bx--pagination-nav__page:focus {
    outline: 2px solid #0f62fe;
    outline-offset: -2px; }
  .bx--pagination-nav__page:disabled, .bx--pagination-nav__page.bx--pagination-nav__page--disabled {
    background: none;
    color: rgba(57, 57, 57, 0.5);
    outline: none;
    pointer-events: none; }
  .bx--pagination-nav__page:not(.bx--pagination-nav__page--direction)::after {
    background-color: #0f62fe;
    bottom: 0;
    content: '';
    display: block;
    height: 0.25rem;
    left: 50%;
    position: absolute;
    opacity: 0;
    -webkit-transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    width: 0; }
  .bx--pagination-nav__page--active + .bx--pagination-nav__page::after, .bx--pagination-nav__page.bx--pagination-nav__page--active::after {
    left: calc(50% - 0.5rem);
    opacity: 1;
    width: 1rem; }
  .bx--pagination-nav__page.bx--pagination-nav__page--active {
    background-color: transparent;
    background-color: initial;
    color: #393939;
    font-weight: 600;
    outline: none; }
  .bx--pagination-nav__page .bx--pagination-nav__icon {
    fill: currentColor;
    pointer-events: none; }

.bx--pagination-nav__page--direction {
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: center;
  line-height: 0;
  width: 3rem; }

.bx--pagination-nav__select {
  position: relative; }

.bx--pagination-nav__page--select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  max-height: 3rem;
  text-indent: calc(50% - 4.5px); }

@-moz-document url-prefix() {
  .bx--pagination-nav__page--select {
    text-indent: 0; } }

.bx--pagination-nav__select-icon-wrapper {
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%; }
  .bx--pagination-nav__select-icon-wrapper:not(.bx--pagination-nav__page--direction)::after {
    background-color: #0f62fe;
    bottom: 0;
    content: '';
    display: block;
    height: 0.25rem;
    left: 50%;
    position: absolute;
    opacity: 0;
    -webkit-transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: width 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
    width: 0; }
  .bx--pagination-nav__page--active + .bx--pagination-nav__select-icon-wrapper::after, .bx--pagination-nav__select-icon-wrapper.bx--pagination-nav__page--active::after {
    left: calc(50% - 0.5rem);
    opacity: 1;
    width: 1rem; }
  .bx--pagination-nav__page--active + .bx--pagination-nav__select-icon-wrapper .bx--pagination-nav__select-icon {
    display: none; }

.bx--pagination-nav__select-icon {
  left: calc(50% - 0.5rem);
  pointer-events: none;
  position: absolute;
  top: calc(50% - 0.5rem); }

.bx--pagination-nav__accessibility-label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background-color: #161616;
  border-bottom: 1px solid #393939;
  z-index: 6000; }

.bx--header__action {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid transparent;
  -webkit-transition: background-color 110ms, border-color 110ms;
  transition: background-color 110ms, border-color 110ms; }
  .bx--header__action::-moz-focus-inner {
    border: 0; }

.bx--header__action
> svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active
> svg.bx--navigation-menu-panel-expand-icon {
  display: none; }

.bx--header__action--active
> svg.bx--navigation-menu-panel-collapse-icon {
  display: inline; }

.bx--header__action
> svg.bx--navigation-menu-panel-collapse-icon,
.bx--header__action--active
> svg.bx--navigation-menu-panel-expand-icon {
  display: none; }

.bx--header__action--active
> svg.bx--navigation-menu-panel-collapse-icon {
  display: inline; }

.bx--header__action:hover {
  background-color: #353535; }

.bx--header__action--active {
  border-left: 1px solid #393939;
  border-right: 1px solid #393939;
  border-bottom: 1px solid #161616; }

.bx--header__action:focus {
  border-color: #ffffff;
  outline: none; }

.bx--header__action:active {
  background-color: #393939; }

.bx--header__action > svg {
  fill: #ffffff; }

.bx--header__menu-trigger {
  margin-right: -0.5rem; }

.bx--header__menu-trigger > svg {
  fill: #f4f4f4; }

.bx--header__menu-trigger:hover {
  fill: #2c2c2c; }

.bx--header__menu-toggle {
  display: block; }

@media (min-width: 66rem) {
  .bx--header__menu-toggle__hidden {
    display: none; } }

a.bx--header__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2rem 0 1rem;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0.125rem solid transparent;
  -webkit-transition: border-color 110ms;
  transition: border-color 110ms;
  outline: none; }

a.bx--header__name:focus {
  border-color: #ffffff; }

.bx--header__name--prefix {
  font-weight: 400; }

a.bx--header__name,
a.bx--header__name:hover {
  color: #f4f4f4; }

.bx--header__nav {
  height: 100%;
  padding-left: 1rem;
  position: relative;
  display: block; }
  @media (max-width: 66rem) {
    .bx--header__nav {
      display: none; } }
  .bx--header__nav::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 1.5rem;
    width: 0.0625rem;
    background-color: #393939; }

.bx--header__menu-bar[role='menubar'] {
  display: flex;
  height: 100%; }

a.bx--header__menu-item[role='menuitem'] {
  display: flex;
  align-items: center;
  color: #c6c6c6;
  padding: 0 1rem;
  height: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.125rem;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px solid transparent;
  -webkit-transition: background-color 110ms, border-color 110ms, color 110ms;
  transition: background-color 110ms, border-color 110ms, color 110ms; }

a.bx--header__menu-item[role='menuitem']:hover {
  background-color: #2c2c2c;
  color: #f4f4f4; }

.bx--header__action:active,
a.bx--header__menu-item[role='menuitem']:active {
  background-color: #393939;
  color: #f4f4f4; }

a.bx--header__menu-item[role='menuitem']:focus {
  border-color: #ffffff;
  color: #f4f4f4;
  outline: none; }

a.bx--header__menu-item[role='menuitem']:hover > svg,
a.bx--header__menu-item[role='menuitem']:active > svg,
a.bx--header__menu-item[role='menuitem']:focus > svg {
  fill: #f4f4f4; }

.bx--header__submenu {
  position: relative; }

.bx--header__menu-title[role='menuitem'][aria-haspopup='true'] {
  position: relative; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true'] {
  background-color: #262626;
  color: #ffffff;
  z-index: 6001; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
> .bx--header__menu-arrow {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--header__menu[role='menu'] {
  display: none; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 12.5rem;
  flex-direction: column;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  background-color: #262626;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
  z-index: 6000; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu
.bx--header__menu-item:hover {
  background-color: #353535; }

.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu
.bx--header__menu-item:active {
  background-color: #393939; }

.bx--header__menu .bx--header__menu-item[role='menuitem'] {
  height: 3rem; }

.bx--header__menu
.bx--header__menu-item[role='menuitem']:hover {
  background-color: #262626;
  color: #f4f4f4; }

.bx--header__menu-arrow {
  fill: #c6c6c6;
  margin-left: 0.5rem;
  -webkit-transition: fill 110ms, -webkit-transform 110ms;
  transition: fill 110ms, -webkit-transform 110ms;
  transition: transform 110ms, fill 110ms;
  transition: transform 110ms, fill 110ms, -webkit-transform 110ms; }

.bx--header__global {
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  height: 100%; }

.bx--skip-to-content {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap; }

.bx--skip-to-content:focus {
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: auto;
  height: 3rem;
  clip: auto;
  border: 4px solid #0f62fe;
  z-index: 9999;
  background-color: #161616;
  color: #f4f4f4;
  outline: none;
  padding: 0 1rem; }

.bx--header-panel {
  -webkit-transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
          transition-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
  position: fixed;
  top: 3rem;
  bottom: 0;
  right: 0;
  width: 0;
  background-color: #161616;
  border: none;
  will-change: width;
  -webkit-transition: width 0.11s;
  transition: width 0.11s;
  overflow: hidden;
  z-index: 6000;
  color: #c6c6c6; }

.bx--header-panel--expanded {
  width: 16rem;
  border-left: 1px solid #393939;
  border-right: 1px solid #393939; }

.bx--panel--overlay {
  position: fixed;
  top: 3rem;
  right: 0;
  bottom: 0;
  width: 16rem;
  will-change: transform;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  padding: 1rem 0;
  overflow-y: auto;
  z-index: 1000;
  background-color: #161616;
  height: 100%;
  overflow-x: hidden;
  -webkit-transition: -webkit-transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: -webkit-transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9), -webkit-transform 0.11s cubic-bezier(0.2, 0, 0.38, 0.9); }

.bx--panel--expanded {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.bx--product-switcher__search {
  padding: 0 1rem;
  margin-bottom: 1.5rem; }

.bx--search--shell input {
  background-color: #e0e0e0; }

.bx--product-switcher__subheader,
.bx--product-switcher__all-btn {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  padding: 0.5rem;
  color: #c6c6c6; }

.bx--product-switcher__subheader {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn {
  padding-left: 3.5rem; }

.bx--product-switcher__all-btn,
.bx--product-switcher__back-btn {
  display: inline-block;
  background: transparent;
  width: 100%;
  border: none;
  color: #0f62fe;
  cursor: pointer;
  text-align: left; }

.bx--product-switcher__all-btn:hover,
.bx--product-switcher__back-btn:hover {
  text-decoration: underline; }

.bx--product-switcher__all-btn:focus,
.bx--product-switcher__back-btn:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px #0f62fe; }

.bx--product-switcher__back-btn {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  padding: 0.5rem 1rem; }

.bx--product-switcher__back-arrow {
  fill: #0f62fe;
  margin-right: 1rem; }

.bx--product-list__item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.bx--product-list__item:hover {
  background: #e0e0e0; }

.bx--product-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1rem;
  text-decoration: none; }

.bx--product-link:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px #0f62fe; }

.bx--product-switcher__icon {
  margin-right: 1rem; }

.bx--product-link__name {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  margin-left: 0.25rem;
  font-weight: 400;
  color: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu {
  display: none;
  justify-content: center;
  align-items: center;
  width: 2.5rem; }
  .bx--product-switcher__product-list .bx--overflow-menu.bx--overflow-menu--open {
    display: flex; }

.bx--product-switcher__product-list .bx--overflow-menu > svg {
  fill: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:hover {
  background: #c6c6c6; }

.bx--product-switcher__product-list
.bx--overflow-menu:hover
> svg {
  fill: #c6c6c6; }

.bx--product-switcher__product-list .bx--overflow-menu:focus {
  display: flex;
  outline: none;
  box-shadow: inset 0 0 0 3px #0f62fe; }

.bx--product-switcher__product-list
.bx--overflow-menu-options__option:hover {
  background: #ffffff; }

.bx--product-list__item:hover .bx--overflow-menu {
  display: flex; }

.bx--switcher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c6c6c6; }

.bx--switcher__item {
  width: 100%;
  height: 2rem; }

.bx--switcher__item:nth-child(1) {
  margin-top: 1rem; }

.bx--switcher__item--divider {
  display: block;
  width: 14rem;
  height: 1px;
  margin: 0.5rem 1rem;
  background: #393939;
  border: none; }

.bx--switcher__item-link {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  display: block;
  height: 2rem;
  text-decoration: none;
  padding: 0.375rem 1rem;
  color: #c6c6c6; }
  .bx--switcher__item-link:hover:not(.bx--switcher__item-link--selected) {
    background: #2c2c2c;
    color: #f4f4f4;
    cursor: pointer; }
  .bx--switcher__item-link:focus {
    outline: 2px solid #ffffff;
    outline-offset: -2px; }
  .bx--switcher__item-link:active {
    background: #393939;
    color: #f4f4f4; }

.bx--switcher__item-link--selected {
  background: #262626;
  color: #f4f4f4; }

.bx--side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 3rem;
  max-width: 16rem;
  color: #525252;
  background-color: #ffffff;
  will-change: width;
  -webkit-transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  transition: width 0.11s cubic-bezier(0.2, 0, 1, 0.9);
  overflow: hidden;
  z-index: 6000; }

.bx--side-nav--ux {
  top: 3rem;
  width: 16rem; }
  @media (max-width: 66rem) {
    .bx--side-nav--ux {
      width: 0; } }

.bx--side-nav--rail {
  width: 3rem; }

.bx--side-nav--hidden {
  width: 0; }

.bx--side-nav:not(.bx--side-nav--fixed):hover,
.bx--side-nav--expanded {
  width: 16rem; }

.bx--side-nav__overlay {
  position: absolute;
  top: 3rem;
  height: 0;
  width: 0;
  background-color: transparent;
  opacity: 0;
  -webkit-transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9); }

@media (max-width: 66rem) {
  .bx--side-nav__overlay-active {
    height: 100vh;
    width: 100%;
    background-color: rgba(22, 22, 22, 0.5);
    opacity: 1;
    -webkit-transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9);
    transition: opacity 240ms cubic-bezier(0.2, 0, 0.38, 0.9), background-color 240ms cubic-bezier(0.2, 0, 0.38, 0.9); } }

.bx--header ~ .bx--side-nav {
  top: 3rem;
  height: calc(100% - 48px); }

.bx--side-nav--fixed {
  width: 16rem; }

.bx--side-nav--collapsed {
  width: 16rem;
  -webkit-transform: translateX(-16rem);
          transform: translateX(-16rem); }

.bx--side-nav__navigation {
  display: flex;
  flex-direction: column;
  height: 100%; }

.bx--side-nav__header {
  display: flex;
  border-bottom: 1px solid #393939;
  width: 100%;
  height: 3rem;
  max-width: 100%; }
  .bx--side-nav:hover .bx--side-nav__header,
  .bx--side-nav--fixed .bx--side-nav__header,
  .bx--side-nav--expanded .bx--side-nav__header {
    height: auto; }

.bx--side-nav--ux .bx--side-nav__header {
  height: auto; }

.bx--side-nav__details {
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  flex: 1 1;
  min-width: 0;
  opacity: 0;
  visibility: hidden; }
  .bx--side-nav:hover .bx--side-nav__details,
  .bx--side-nav--fixed .bx--side-nav__details,
  .bx--side-nav--expanded .bx--side-nav__details {
    visibility: visible;
    opacity: 1; }

.bx--side-nav--ux .bx--side-nav__details {
  opacity: 1;
  visibility: inherit; }

.bx--side-nav__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  margin-top: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.bx--side-nav__title,
.bx--side-nav__select {
  padding-left: 0.5rem; }

.bx--side-nav__switcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.bx--side-nav__switcher-chevron {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  fill: #525252; }

.bx--side-nav__select {
  outline: 2px solid transparent;
  outline-offset: -2px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  flex: 1 1;
  background-color: #161616;
  color: #f4f4f4;
  height: 100%;
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 0.75rem;
  height: 2rem;
  min-width: 0;
  padding-right: 2rem;
  -webkit-transition: outline 110ms;
  transition: outline 110ms; }

.bx--side-nav__select:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--side-nav__footer {
  flex: 0 0 3rem;
  width: 100%;
  background-color: #ffffff; }

.bx--side-nav__toggle {
  outline: 2px solid transparent;
  outline-offset: -2px;
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: left;
  -webkit-transition: outline 110ms;
  transition: outline 110ms; }
  .bx--side-nav__toggle::-moz-focus-inner {
    border: 0; }

.bx--side-nav__toggle:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--side-nav__toggle {
  padding-left: 1rem; }

.bx--side-nav__items {
  flex: 1 1;
  overflow: hidden;
  padding: 1rem 0 0; }
  .bx--side-nav:hover .bx--side-nav__items,
  .bx--side-nav--fixed .bx--side-nav__items,
  .bx--side-nav--expanded .bx--side-nav__items {
    overflow-y: auto; }

.bx--side-nav--ux .bx--side-nav__items {
  overflow-y: auto; }

.bx--side-nav__item {
  width: 3rem;
  height: 3rem;
  overflow: hidden; }
  .bx--side-nav:hover .bx--side-nav__item,
  .bx--side-nav--fixed .bx--side-nav__item,
  .bx--side-nav--expanded .bx--side-nav__item {
    width: auto;
    height: auto; }

.bx--side-nav--ux .bx--side-nav__item {
  width: auto;
  height: auto; }

.bx--side-nav__item:not(.bx--side-nav__item--active):hover
.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__submenu:hover,
.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__link:hover,
.bx--side-nav__menu[role='menu']
a.bx--side-nav__link[role='menuitem']:not(.bx--side-nav__link--current):not([aria-current='page']):hover,
.bx--side-nav a.bx--header__menu-item[role='menuitem']:hover,
.bx--side-nav
.bx--header__menu-title[role='menuitem'][aria-expanded='true']:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__item:not(.bx--side-nav__item--active)
> .bx--side-nav__link:hover
> span,
.bx--side-nav__item:not(.bx--side-nav__item--active)
.bx--side-nav__menu-item
> .bx--side-nav__link:hover
> span {
  color: #161616; }

.bx--side-nav__item--large {
  height: 3rem; }

.bx--side-nav__submenu[aria-haspopup='true'] {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  outline: 2px solid transparent;
  outline-offset: -2px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  color: #525252;
  height: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms; }
  .bx--side-nav__submenu[aria-haspopup='true']::-moz-focus-inner {
    border: 0; }

.bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__submenu:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

.bx--side-nav__submenu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left; }

.bx--side-nav__icon.bx--side-nav__submenu-chevron {
  flex: 1 1;
  display: flex;
  justify-content: flex-end; }

.bx--side-nav__submenu-chevron > svg {
  -webkit-transition: -webkit-transform 110ms;
  transition: -webkit-transform 110ms;
  transition: transform 110ms;
  transition: transform 110ms, -webkit-transform 110ms;
  height: 1rem;
  width: 1rem; }

.bx--side-nav__submenu[aria-expanded='true']
.bx--side-nav__submenu-chevron
> svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--side-nav__item--large .bx--side-nav__submenu {
  height: 3rem; }

.bx--side-nav__item--active .bx--side-nav__submenu:hover {
  background-color: #e5e5e5;
  color: #161616; }

.bx--side-nav__item--active
.bx--side-nav__submenu[aria-expanded='false'] {
  background-color: #e5e5e5;
  color: #161616;
  position: relative; }
  .bx--side-nav__item--active
.bx--side-nav__submenu[aria-expanded='false']::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 4px;
    background-color: #0f62fe; }

.bx--side-nav__item--active .bx--side-nav__submenu-title {
  font-weight: 600;
  color: #161616; }

.bx--side-nav__menu[role='menu'] {
  display: block;
  visibility: hidden;
  max-height: 0; }

.bx--side-nav__submenu[aria-expanded='true']
+ .bx--side-nav__menu[role='menu'] {
  max-height: 93.75rem;
  visibility: inherit; }

.bx--side-nav__menu[role='menu']
a.bx--side-nav__link[role='menuitem'] {
  height: 2rem;
  min-height: 2rem;
  padding-left: 2rem;
  font-weight: 400; }

.bx--side-nav__item.bx--side-nav__item--icon
a.bx--side-nav__link[role='menuitem'] {
  padding-left: 4.5rem; }

.bx--side-nav__menu[role='menu']
a.bx--side-nav__link--current,
.bx--side-nav__menu[role='menu']
a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  background-color: #e0e0e0; }
  .bx--side-nav__menu[role='menu']
a.bx--side-nav__link--current > span,
  .bx--side-nav__menu[role='menu']
a.bx--side-nav__link[aria-current='page'] > span,
  a.bx--side-nav__link--current > span {
    color: #161616;
    font-weight: 600; }

a.bx--side-nav__link--current > span.bx--side-nav__link-text {
  color: #161616;
  font-weight: 600; }

a.bx--side-nav__link,
.bx--side-nav a.bx--header__menu-item[role='menuitem'],
.bx--side-nav
.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu {
  outline: 2px solid transparent;
  outline-offset: -2px;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  position: relative;
  display: flex;
  align-items: center;
  text-decoration: none;
  min-height: 2rem;
  padding: 0 1rem;
  -webkit-transition: color 110ms, background-color 110ms, outline 110ms;
  transition: color 110ms, background-color 110ms, outline 110ms; }

.bx--side-nav__item--large a.bx--side-nav__link {
  height: 3rem; }

a.bx--side-nav__link > .bx--side-nav__link-text,
.bx--side-nav
a.bx--header__menu-item[role='menuitem']
.bx--text-truncate-end {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #525252;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.25rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

a.bx--side-nav__link:focus,
.bx--side-nav a.bx--header__menu-item[role='menuitem']:focus {
  outline: 2px solid #0f62fe;
  outline-offset: -2px; }

a.bx--side-nav__link[aria-current='page'],
a.bx--side-nav__link--current {
  font-weight: 600; }

a.bx--side-nav__link[aria-current='page']::before,
a.bx--side-nav__link--current::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0f62fe; }

.bx--side-nav__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 1rem; }

.bx--side-nav__icon:not(.bx--side-nav__submenu-chevron) {
  margin-right: 1.5rem; }

.bx--side-nav__icon > svg {
  fill: #525252;
  width: 1rem;
  height: 1rem; }

.bx--side-nav__icon > svg.bx--side-nav-collapse-icon {
  display: none; }

.bx--side-nav--expanded
.bx--side-nav__icon
> svg.bx--side-nav-expand-icon {
  display: none; }

.bx--side-nav--expanded
.bx--side-nav__icon
> svg.bx--side-nav-collapse-icon {
  display: block; }

.bx--side-nav--fixed a.bx--side-nav__link,
.bx--side-nav--fixed
.bx--side-nav__submenu[aria-haspopup='true'] {
  padding-left: 1rem; }

.bx--side-nav--fixed
.bx--side-nav__menu[role='menu']
a.bx--side-nav__link {
  padding-left: 2rem; }

@media (max-width: 66rem) {
  .bx--side-nav .bx--header__nav {
    display: block; } }

.bx--side-nav__header-navigation {
  display: none; }
  @media (max-width: 66rem) {
    .bx--side-nav__header-navigation {
      display: block;
      position: relative;
      margin-bottom: 2rem; } }

.bx--side-nav__header-divider::after {
  content: '';
  position: absolute;
  height: 0.0625rem;
  bottom: -1rem;
  left: 1rem;
  width: calc(100% - 32px);
  background: #e0e0e0; }

.bx--side-nav a.bx--header__menu-item[role='menuitem'] {
  color: #525252;
  white-space: nowrap;
  justify-content: space-between; }
  .bx--side-nav a.bx--header__menu-item[role='menuitem'][aria-expanded='true'] {
    background-color: transparent; }

.bx--side-nav
.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu {
  bottom: inherit;
  width: 100%;
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  background-color: transparent;
  padding: 0; }
  .bx--side-nav
.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu li {
    width: 100%; }
  .bx--side-nav
.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu a.bx--header__menu-item[role='menuitem'] {
    padding-left: 4.25rem;
    font-weight: 400; }
  .bx--side-nav
.bx--header__menu-title[role='menuitem'][aria-expanded='true']
+ .bx--header__menu a.bx--header__menu-item[role='menuitem']:hover {
    background-color: #e5e5e5;
    color: #161616; }

.bx--side-nav
.bx--header__menu
a.bx--header__menu-item[role='menuitem'] {
  height: inherit; }

.bx--side-nav
a.bx--header__menu-item[role='menuitem']:hover
.bx--header__menu-arrow,
.bx--side-nav
a.bx--header__menu-item[role='menuitem']:focus
.bx--header__menu-arrow,
.bx--side-nav .bx--header__menu-arrow {
  fill: #525252; }

.bx--navigation {
  position: fixed;
  top: 3rem;
  bottom: 0;
  left: 0;
  background-color: #262626;
  width: 16rem;
  z-index: 9100;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.25);
  color: #f4f4f4; }

.bx--navigation--right {
  left: auto;
  right: 0; }

.bx--navigation svg {
  fill: #f4f4f4; }

.bx--navigation-section:not(:last-child)::after {
  display: block;
  content: '';
  height: 1px;
  background-color: #393939;
  margin: 0 1rem; }

.bx--navigation-item {
  position: relative;
  display: flex;
  align-items: center; }

.bx--navigation-item--active > a.bx--navigation-link {
  color: #ffffff;
  font-weight: 600; }

.bx--navigation-item--active::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0f62fe; }

a.bx--navigation-link {
  display: flex;
  align-items: center;
  color: #f4f4f4;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 400;
  width: 100%;
  min-height: 2.5rem;
  padding-left: 1rem; }

a.bx--navigation-link:hover {
  background-color: #333333;
  color: #ffffff; }

a.bx--navigation-link:focus {
  outline: 0.1875rem solid #0f62fe;
  outline-offset: -0.1875rem; }

.bx--navigation-item--icon > a.bx--navigation-link {
  padding-left: 0; }

.bx--navigation__category {
  width: 100%; }

.bx--navigation__category-toggle {
  display: inline-block;
  background: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center; }
  .bx--navigation__category-toggle::-moz-focus-inner {
    border: 0; }

.bx--navigation__category-toggle:hover {
  background-color: #333333; }

.bx--navigation__category-toggle:focus {
  outline: 0.1875rem solid #0f62fe;
  outline-offset: -0.1875rem; }

.bx--navigation__category-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
  width: 100%;
  color: #f4f4f4;
  font-size: 0.875rem;
  font-weight: 400;
  min-height: 2.5rem;
  padding-left: 1rem; }

.bx--navigation-item--icon .bx--navigation__category-title {
  padding-left: 0; }

.bx--navigation__category-items {
  display: none;
  visibility: hidden; }

.bx--navigation__category-item > a.bx--navigation-link {
  display: flex;
  align-items: center;
  min-height: 2rem;
  padding-left: 2rem; }

.bx--navigation__category-item {
  position: relative; }

.bx--navigation-item--icon
.bx--navigation__category-item
> a.bx--navigation-link {
  padding-left: 3.5rem; }

.bx--navigation__category-item--active::after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  background-color: #0f62fe; }

.bx--navigation__category-item--active
> a.bx--navigation-link {
  font-weight: 600;
  color: #ffffff; }

.bx--navigation__category--expanded
.bx--navigation__category-title {
  font-weight: 600; }

.bx--navigation__category--expanded
.bx--navigation__category-title
> svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.bx--navigation__category--expanded
.bx--navigation__category-items {
  display: block;
  visibility: inherit; }

.bx--navigation-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  min-width: 3rem;
  height: 2.5rem;
  margin-right: 0.5rem; }

.bx--content {
  background: #ffffff;
  padding: 2rem;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  will-change: margin-left; }

.bx--header ~ .bx--content {
  margin-top: 3rem; }

.bx--side-nav ~ .bx--content {
  margin-left: 3rem; }

.bx--side-nav.bx--side-nav--expanded ~ .bx--content {
  margin-left: 16rem; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.bx--header ~ .bx--content {
  margin-top: 60px; }

.bx--side-nav__overlay {
  top: 60px; }

@media (max-width: 66rem) {
  .bx--side-nav__overlay-active {
    height: auto; } }

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#header {
  background-color: #e55300;
  height: 60px; }

#bni-header-logo {
  margin-left: 20px; }

#nuhk-header-logo {
  margin-right: 20px; }

.logo {
  height: 40px; }

.bx--header ~ .bx--side-nav {
  height: calc(100% - 60px);
  top: 60px;
  background-color: #006575; }

a.bx--side-nav__link > .bx--side-nav__link-text {
  color: white; }

.side-nav-icon {
  fill: white; }

.bx--side-nav__item--active .bx--side-nav__submenu-title {
  color: white; }

.bx--side-nav__item--active .bx--side-nav__submenu[aria-expanded='false'] {
  background-color: #00414b; }

.bx--side-nav__icon > svg {
  fill: white; }

.bx--side-nav__menu-item:active {
  background-color: #00414b; }

label, .searchKey {
  display: inline-flex;
  width: 180px; }

#inquiry-container > h4 {
  margin: 8px 0; }

.photo-btn-container {
  align-items: center;
  display: flex;
  flex-direction: column; }

.searchValue {
  display: inline-flex; }

.form-element {
  margin: 6px 0; }

#inquiry-form-msg {
  font-size: 0.8em;
  display: inline-flex; }

#inquiry-container > button {
  display: inline-flex; }

#search-by {
  margin-bottom: 8px;
  display: inline-flex; }

input {
  width: 300px; }

button {
  display: block; }

h2 {
  margin-bottom: 16px; }

#period-selector > button, #period-selector > select, input[type='date'] {
  margin-left: 8px; }

#period-selector > button {
  display: inline-flex; }

#table-nasabah {
  margin-top: 8px; }

#excel-btn {
  margin: 0 auto;
  margin-top: 8px; }

.photo-disp > div > img {
  width: 200px;
  margin: 8px 0;
  object-fit: contain; }

.photo-disp {
  display: flex; }

.no-disp {
  display: none; }

.hidden {
  display: none; }

.edit-nasabah-label {
  width: 300px; }

#edit-nasabah-container input, #edit-nasabah-container textarea {
  display: inline-block;
  width: 400px;
  margin-left: 0px; }

#edit-nasabah-container .textarea-div {
  display: inline-flex; }

#edit-nasabah-container img {
  width: 200px;
  object-fit: contain;
  display: inline-block; }

.empty-div-label {
  display: inline-block; }

.file-container {
  display: inline-flex;
  flex-direction: column; }

#edit-nasabah-container .pagination {
  border: 1px solid gray;
  border-radius: 4px;
  display: inline-flex;
  align-self: flex-end;
  cursor: pointer; }

#edit-nasabah-container .pagination li {
  padding: 8px;
  display: flex;
  box-sizing: border-box;
  border: 1px solid gray; }

#edit-nasabah-container .pagination .nasabah-selected {
  background-color: #006575;
  font-weight: 600; }

#edit-nasabah-container .pagination li.nasabah-selected a {
  color: white; }

#edit-nasabah-container .pagination li a {
  color: #006575; }

#edit-nasabah-container .pagination .previous {
  border-right: 1px solid gray; }

#edit-nasabah-container .pagination .next {
  border-left: 1px solid gray; }

#login-form {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 600px;
  border-radius: 8px;
  padding: 24px;
  border: 2px solid #e55300; }

#login-container {
  display: flex;
  flex: 1 1;
  height: 100%;
  align-items: center;
  justify-content: center; }

#sign-in-title {
  font-size: 2em;
  padding-bottom: 24px;
  text-align: center; }

#login-form label {
  font-weight: bold;
  font-size: 1.2em; }

#login-form label[for=password] {
  margin-top: 16px; }

#login-form button[type=submit] {
  width: 100%;
  background-color: #e55300;
  color: white;
  padding: 14px 20px;
  border: none;
  margin: 8px 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.2em;
  cursor: pointer; }

#login-form input[type=text], #login-form input[type=password] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

.editable-row input[type='text'] {
  display: inline-block;
  width: 450px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px; }

.editable-row .bx--btn .bx--btn__icon {
  right: auto;
  right: initial;
  width: 16px;
  height: 16px;
  outline: none; }

.editable-row {
  display: flex;
  align-items: center; }

.editable-row label {
  width: 20px;
  margin-right: 8px; }

.add-field-btn {
  margin-bottom: 16px;
  margin-top: 4px; }

.remove-field-btn {
  width: 28px;
  height: 28px;
  min-height: 0;
  min-height: initial;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  background-color: #e53935;
  border-radius: 14px;
  -webkit-transition: background-color 0.1s;
  transition: background-color 0.1s; }

.remove-field-btn:hover {
  background-color: #b71c1c; }

.remove-field-btn:focus {
  background-color: #b71c1c;
  border-color: #b71c1c;
  outline: none; }

.centered-loader {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}
