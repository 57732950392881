@import '../../utils/constants.scss';

#header {
  background-color: $bni-orange;
  height: 60px;
}

#bni-header-logo {
  margin-left: 20px;
}

#nuhk-header-logo {
  margin-right: 20px;
}

.logo {
  height: 40px;
  // height: 20px;
}