@mixin transition($x...){
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}

.editable-row input[type='text'] {
  display: inline-block;
  width: 450px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
}

.editable-row .bx--btn .bx--btn__icon {
  right: initial;
  width: 16px;
  height: 16px;
  outline: none;
}

.editable-row {
  display: flex;
  align-items: center;
}

.editable-row label {
  width: 20px;
  margin-right: 8px;
}

.add-field-btn {
  margin-bottom: 16px;
  margin-top: 4px;
}

.remove-field-btn {
  width: 28px;
  height: 28px;
  min-height: initial;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0;
  background-color: #e53935;
  border-radius: 14px;
  @include transition(background-color 0.1s);
}

.remove-field-btn:hover {
  background-color: #b71c1c;
}

.remove-field-btn:focus {
  background-color: #b71c1c;
  border-color: #b71c1c;
  outline: none;
}

