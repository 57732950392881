@import '../../utils/constants.scss';

.bx--header ~ .bx--side-nav {
  height: calc(100% - 60px);
  top: 60px;
  background-color: $bni-green;
}

a.bx--side-nav__link > .bx--side-nav__link-text {
  color: white;
}

.side-nav-icon {
  fill: white;
}

.bx--side-nav__item--active .bx--side-nav__submenu-title {
  color: white;
}

.bx--side-nav__item--active .bx--side-nav__submenu[aria-expanded='false'] {
  background-color: #00414b;
}

.bx--side-nav__icon > svg {
  fill: white;
}

.bx--side-nav__menu-item:active {
  background-color: #00414b;
}
